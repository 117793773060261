import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { CommonMethodsService } from '../util/common-methods.service';
import { LoginService } from '../auth/login.service';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class PocsService {
  apiUrl = environment.apiUrl;
  updatedId = 0;

  constructor(private http: HttpClient, private commonService: CommonMethodsService, private loginService: LoginService) { }

  getPocs(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/pocs', { params, headers });
  }

  sendExpiredEmail(data) {
    return this.http.post(this.apiUrl + `/v1/pocs/send-delayed-emails`, data)
  }

  // @Cacheable()
  getStatuses(params?) {
    return this.http.get(this.apiUrl + '/v1/poc-statuses', { params });
  }

  getFeatured() {
    return {
      'yes': 1,
      'No': 0
    };
  }

  getPocInfo(id: number, headers?, params?) {
    return this.http.get(this.apiUrl + `/v1/pocs/${id}`, { headers, params });
  }

  getRecommendation(id) {
    return this.http.get(this.apiUrl + `/v1/pocs/${id}/recommended`);
  }

  updateFeaturedStatus(id, featured) {
    const data = {
      email_id: this.loginService.currentUser.user.email,
      featured: featured
    };
    return this.http.post(this.apiUrl + `/v1/pocs/${id}/featured`, data);
  }

  updateProgress(id, progress) {
    const data = {
      email_id: this.loginService.currentUser.user.email,
      progress: progress
    };
    return this.http.post(this.apiUrl + `/v1/pocs/${id}/progress`, data);
  }

  startProgress(id) {
    const data = {
      email_id: this.loginService.currentUser.user.email
    };
    return this.http.post(this.apiUrl + `/v1/pocs/${id}/start-progress`, data);
  }

  approveReject(id, comments, type) {
    const data = {
      email_id: this.loginService.currentUser.user.email,
      comments,
      type
    };
    return this.http.post(this.apiUrl + `/v1/pocs/${id}/status`, data);
  }

  submitPoc(data) {
    return this.http.post(this.apiUrl + '/v1/pocs', data);
  }

  updatePoc(id, data) {
    return this.http.put(this.apiUrl + `/v1/pocs/${id}`, data);
  }

  @Cacheable()
  getCloudServiceProviders() {
    return this.http.get(this.apiUrl + '/v1/cloud-service-providers');
  }

  @Cacheable()
  getPurposes() {
    return this.http.get(this.apiUrl + '/v1/purposes');
  }

  @Cacheable()
  getPocTypes() {
    return this.http.get(this.apiUrl + '/v1/poc-types');
  }

  updateProposalDocument(id, data) {
    return this.http.put(this.apiUrl + `/v1/pocs/${id}/document-proposal`, data);
  }

  markComplete(id, data) {
    return this.http.put(this.apiUrl + `/v1/pocs/${id}/mark-complete`, data);
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + `/v1/pocs/${id}`);
  }
}

export interface Pocs {
  pocs: [];
  total_count: number;
}
