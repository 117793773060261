import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.css']
})
export class ChipsComponent implements OnInit {
  // @Input() filters;
  @Input() data;
  @Input() displayLength = 250;
  filtersList = [];
  displayNumber = 0;
  moreChips = 0;
  allowClearAll = false;

  constructor(public filterService: FilterService, private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.data) {
      this.filtersList = this.data;
      this.displayLength = 100000;
      this.getDisplayNumber();
      this.allowClearAll = true;
    }
    this.filterService.filtersUpdate.subscribe(filter => {
      this.filtersList = filter.filter;
      this.getDisplayNumber();
    });
  }

  getDisplayNumber() {
    this.displayNumber = 0;
    this.moreChips = 0;
    if (!this.filtersList.length) {
      this.modalService.close()
    }
    // get no of filters display
    let filtersName = '';
    for (const filter of this.filtersList) {
      filtersName += filter['name'] + '       ';
      if (filtersName.length > (this.displayLength - 14)) {
        break;
      }
      filtersName += ' ';
      this.displayNumber++;
    }
    // check is only one segment left and it can be display
    if (this.filtersList.length === (this.displayNumber + 1)) {
      if (filtersName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }
    this.moreChips = this.filtersList.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Filters`, message: '', component: 'ChipsComponent', data: this.filtersList, size: 'lg' };
    this.modalService.open(data);
  }

  clearAll() {
    this.filterService.clearFilters();
  }
}
