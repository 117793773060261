import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SpellCheckService } from 'app/services/spell-check/spell-check.service';
import { environment } from 'environments/environment';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css']
})
export class TextEditorComponent implements OnInit {
  apiUrl = environment.apiUrl;
  @Input() form: UntypedFormGroup;
  @Input() controlName = '';
  @Input() label = '';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() optional = false;
  @Input() showLabel = true;
  @Input() readonly = false;
  @Input() autoFocus = false;
  @Input() height = '';
  @Input() ckeConfig = {
    // removePlugins: 'elementspath',
    // toolbar: [
    //   // { name: 'document', items: ['Source', '-'] },
    //   // { name: 'styles', items: ['FontSize', '-'] },
    //   { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Subscript', 'Superscript', '-'] },
    //   // { name: 'colors', items: ['TextColor', 'BGColor'] },
    //   { name: 'paragraph', items: ['NumberedList', 'BulletedList',] },
    //   { name: 'links', items: ['Link', 'Unlink'] },
    //   { name: 'insert', items: ['HorizontalRule', 'SpecialChar'] },
    //   // { name: 'tools', items: ['Maximize'] }
    // ]
    toolbar: {
      items: ['bold', 'italic', 'underline', 'subscript', 'superscript',
        '|', 'numberedList', 'bulletedList',
        '|', 'link',
        '|', 'horizontalLine', 'specialCharacters']
    }


  };
  @Output() blur = new EventEmitter();
  @Output() focus = new EventEmitter();

  Editor = Editor;
  editorText = '';

  constructor(public spellCheckService: SpellCheckService) { }

  ngOnInit() {
    this.editorText = this.form.get(this.controlName).value;
    this.form.get(this.controlName).valueChanges.subscribe(
      value => {
        this.editorText = value;
      }
    );
  }

  valueChange() {
    this.form.get(this.controlName).setValue(this.editorText);
  }

  onBlur() {
    this.blur.emit(true);
  }

  onFocus() {
    this.focus.emit(true);
  }

  onReady(event: any) {
    if (this.autoFocus) {
      event.sourceElement.focus();
    }
  }
}
