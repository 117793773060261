<label for="file-upload" *ngIf="label">{{label}}</label>

<div class="banner-preview" [ngClass]="{'da-carousel-preview':entityCategoryId}">
  <div class="carousels-container">
    <div class="picsum-img-wrapper">
      <img *ngIf="value.url" [src]="value.url">
      <div class="banner-preview-card"></div>
    </div>
    <div class="carousel-caption">
      <div class="container carousel-caption-container">
        <ng-container *ngIf="entityCategoryId;else fullCarousel">
          <!-- <div class="carousel-tag-line" *ngIf="form.value.tag_line">{{form.value.tag_line}}</div> -->
          <h3 class="carousel-title">{{form.value.title}}</h3>
          <div class="carousel-description" *ngIf="form.value.description">{{form.value.description}}</div>
        </ng-container>

        <ng-template #fullCarousel>
          <div class="carousel-tag-line" *ngIf="form.value.tag_line">{{form.value.tag_line}}</div>
          <h3 class="carousel-title">{{form.value.title}}</h3>
          <div class="carousel-description" *ngIf="form.value.description">{{form.value.description}}</div>
          <div class="carousel-date" *ngIf="form.value.event_date">{{form.value.event_date.year}} -
            {{form.value.event_date.month}} - {{form.value.event_date.day}}
            <ng-container *ngIf="form.value.event_time"> {{form.value.event_time}}</ng-container>
          </div>
          <div class="carousel-action mt-10" *ngIf="form.value.action_name && form.value.action_url">
            <a [href]="form.value.action_url" [target]="form.value.target"
              class="btn btn-primary btn-sm">{{form.value.action_name}}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="hero-banner-gallery mt-5">
  <app-cloud-picker [data]="previewData"></app-cloud-picker>
</div>
