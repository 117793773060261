import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { LocationsService } from 'app/services/locations/locations.service';
import { UntypedFormGroup } from '@angular/forms';
import * as lodash from 'lodash';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-location-options',
  templateUrl: './location-options.component.html',
  styleUrls: ['./location-options.component.css']
})
export class LocationOptionsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = 'locations';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() label = $localize`Location`;
  @Input() placeholder = $localize`Select location`;
  @Input() optional = false;
  @Input() dropdownClass = '';
  locations = [{ id: '', text: '' }];
  options: Options;
  selectedLocations = [];
  touched = false;
  values: string[];

  constructor(private locationService: LocationsService) { }

  ngOnInit() {
    if (this.multiple) {
      this.placeholder = $localize`Select locations`;
      this.label = $localize`Locations`;
    }
    this.locationService.getLocations()
      .subscribe(response => {
        this.locations = this.locations.concat(
          response['rows'].map(location => ({ id: location.id, text: location.full_name, city: location.city }))
        );

        if (this.value && this.value.length) {
          // this.onSelect({ data: this.value.map(location => ({ id: location.id, text: location.full_name, city: location.city })) });
          // if (this.multiple) {
          setTimeout(() => {
            this.values = this.value.map(v => v.id.toString());
          }, 0);
          // } else {
          //   this.values = this.value ? this.value[0]?.id : null;
          // }
        }
      });

    this.options = {
      placeholder: this.placeholder,
      multiple: this.multiple,
      dropdownCssClass: this.dropdownClass,
    };
    if (!this.value) {
      this.value = [];
    }
  }

  ngOnChanges(change) {
    // if (!this.multiple && change.value) {
    //   this.values = this.value ? this.value[0]?.id : null;
    // }
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.locations.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    }
  }

  onSelect(event) {
    let locations = event.data.map(location => +location.id)
      .filter(id => id);
    if (locations.length) {
      this.touched = true;
      if (this.multiple) {
        locations = lodash.uniq(locations.concat(this.form.get(this.controlName).value.map(location => +location.id) || []));
        // $('#location-options select').val(null).trigger('change');
      }
      this.selectedLocations = this.locations.filter(loc => locations.includes(loc.id));
      if (!isEqual(locations, this.value.map(l => l.id))) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(this.selectedLocations);
      if (this.multiple && this.selectedLocations.length) {
        const input = document.querySelector(`.location-options .select2-search__field`) as HTMLInputElement
        input.placeholder = this.placeholder;
        input.style.width = 'auto';
      }
    }
  }

  remove(id) {
    this.touched = true;
    const locations = this.form.get(this.controlName).value.map(location => +location.id).filter(loc => loc !== id);
    this.selectedLocations = this.locations.filter(loc => locations.includes(loc.id));
    if (!isEqual(locations, this.value.map(l => l.id))) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(this.selectedLocations);
    this.values = this.selectedLocations.map(l => l.id.toString())
  }
}
