import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class TechTalksService {
  apiUrl = environment.apiUrl;
  updatedId = 0;
  submitEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  getTechTalks(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/tech-talks', { params, headers });
  }

  getTechTalkInfo(id: number, params?) {
    return this.http.get(this.apiUrl + `/v1/tech-talks/${id}`, { params });
  }

  getRecommendation(id) {
    return this.http.get(this.apiUrl + `/v1/tech-talks/${id}/recommended`);
  }

  // searchTechTalks(params) {
  //   return this.http.get(this.apiUrl + '/v1/tech-talks/search', { params });
  // }

  // get languages
  @Cacheable()
  getLanguages() {
    return this.http.get(this.apiUrl + '/v1/languages');
  }

  submitTechTalk(data) {
    return this.http.post(this.apiUrl + '/v1/tech-talks', data);
  }

  updateTechTalk(id, data) {
    return this.http.put(this.apiUrl + `/v1/tech-talks/${id}`, data);
  }

  approveReject(id, type, comments) {
    const data = {
      type,
      comments
    };
    return this.http.post(this.apiUrl + `/v1/tech-talks/${id}/status`, data);
  }

  delete(id: number) {
    return this.http.delete(this.apiUrl + `/v1/tech-talks/${id}`);
  }
}

export interface TechTalks {
  tech_talks: [];
  total_count: number;
}
