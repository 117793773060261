import { Component, OnDestroy, OnInit } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { LoginService } from 'app/services/auth/login.service';
import { FieldConfig } from 'app/interfaces/field';

@Component({
  selector: 'app-filter-action',
  templateUrl: './filter-action.component.html',
  styleUrls: ['./filter-action.component.css']
})
export class FilterActionComponent implements OnInit {
  showExport = false;
  showAdvanceFilter = false;

  constructor(public filterService: FilterService, private loginService: LoginService) { }

  ngOnInit() {
    if ((this.loginService.isPrivilegedUser() || this.loginService.isCenterCoordinator() || this.loginService.isBandThreeAndAbove())
      && this.filterService.exportUri) {
      this.showExport = true;
    }
  }

  toggleAdvanceFilter() {
    this.showAdvanceFilter = !this.showAdvanceFilter
  }

  onClickOutside(event) {
    if (!event.target.closest("a.clear-filter") && !event.target.closest("ngb-datepicker")) {
      this.closeAdvanceFilter();
    }
  }

  closeAdvanceFilter() {
    this.showAdvanceFilter = false;
  }

}
