import { Pipe, PipeTransform } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  constructor(private commonService: CommonMethodsService) { }

  transform(value: string, limit = 50, completeWords = false, ellipsis = '...', filterHtml = true) {
    return this.commonService.truncate(value, limit, completeWords, ellipsis, filterHtml);
  }

}
