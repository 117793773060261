import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlogsService } from 'app/services/blogs/blogs.service';
import { LoginService } from 'app/services/auth/login.service';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';

@Component({
  selector: 'app-glo-login',
  templateUrl: './glo-login.component.html',
  styleUrls: ['./glo-login.component.css']
})
export class GloLoginComponent implements OnInit {
  form: UntypedFormGroup;
  submitted = false;
  invalidForm = false;
  username = '';
  loginError = '';
  community;
  communityUrl = '';

  constructor(private fb: UntypedFormBuilder, private blogService: BlogsService,
    private loginService: LoginService, private formValidator: FormValidatorService, private activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.blogService.gloCommunity) {
      this.community = this.blogService.gloCommunity;
      this.communityUrl = environment.gloUrl + 'communities/' + this.community['glo_community_id'];
    }
    this.username = this.loginService.currentUser.user.email.split('@')[0];
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      username: this.fb.control({ value: this.username, disabled: true }),
      password: this.fb.control('', [this.formValidator.whiteSpaceValidation]),
      accept: this.fb.control(false, [Validators.requiredTrue])
    });
    this.onChanges('password');
  }

  closePopup() {
    this.activeModal.dismiss();
  }

  onSubmit() {
    this.invalidForm = true;
    // check form submitted
    if (!this.submitted) {
      if (this.form.invalid) {
        return false;
      }
      this.gloLogin();
    }
  }

  gloLogin() {
    this.invalidForm = false;
    this.submitted = true;
    const data = { token: btoa(`${this.username}:${this.form.value['password']}`) };

    this.blogService.gloLogin(data)
      .subscribe(response => {
        if (response['error']) {
          this.loginError = response['error'];
          this.invalidForm = true;
          this.submitted = false;
        } else {
          this.blogService.gloLoginEvent.emit(response['app_token']);
        }
      }, error => {
        this.loginError = error.error;
        this.invalidForm = true;
        this.submitted = false;
      });
  }

  onChanges(control) {
    this.form.get(control).valueChanges.subscribe(val => {
      this.loginError = '';
    });
  }

}
