<h3>{{title}} ({{membersCount}})</h3>
<a class="contactus-icon circle-actions" [ngClass]="membersType" (click)="openContacUs()" *ngIf="email" i18n-ngbTooltip
    ngbTooltip="Contact {{mailLabel}}" container="body">
    <i class="fas fa-envelope"></i></a>

<!-- <p class="truncate-2" *ngIf="membersType != 'core' else coreDescription" [ngbTooltip]="description">{{description}}</p> -->
<p class="truncate-2" [ngbTooltip]="description" container="body" tooltipClass='pmc-tooltip'>{{description}}</p>

<!-- <ng-template #coreDescription>
    <p>
        Group of recognised SMEs allocated to Global
        <a href="https://glo.globallogic.com/organizations/corporate_directory?id=779" target="_blank"> Practices </a>
        organization.
    </p>
</ng-template> -->

<div *ngIf="layout == 4">
    <p class="no-member" *ngIf="!members.length">No {{queryParam}} Found</p>
    <ul class="n-member-list">
        <li *ngFor="let member of members">
            <ng-template #toolTipContent>
                <app-tooltip-person-card [data]="member" (mouseleave)="t.close()">
                </app-tooltip-person-card>
            </ng-template>

            <span class="avatar avatar-75" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip' container="body"
                triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
                (mouseleave)="onMouseleave($event,t,'.p-card-tooltip')">
                <img [src]="member.photo_url" title={{getPersonName(member)}}>
            </span>

            <div>
                <div class="user-name mt-10 truncate-2" [routerLink]="['/team', member['login']]"
                    [title]="getPersonName(member)">{{getPersonName(member)}}</div>

                <div class="city-designation truncate-2" *ngIf="membersType !== 'center_coordinators'; else showCity"
                    [title]="getPersonTitle(member)">
                    {{getPersonTitle(member)}}
                </div>
                <ng-template #showCity>
                    <!-- <div class="city-designation truncate-1" [ngbToolTip]="locationToolTip">
                        {{getPersonCCLocations(member)}}</div> -->
                    <app-locations-chip [locations]="getPersonCCLocations(member)"></app-locations-chip>
                </ng-template>
            </div>
        </li>
    </ul>
</div>

<div *ngIf="layout == 6">
    <p class="no-member" *ngIf="!members.length">No {{queryParam}} Found</p>
    <ul class="n-member-list-verti pb-20">
        <li *ngFor="let member of members">
            <ng-template #toolTipContent>
                <app-tooltip-person-card [data]="member" (mouseleave)="t.close()">
                </app-tooltip-person-card>
            </ng-template>
            <span class="avatar avatar-sm" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip' container="body"
                triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
                (mouseleave)="onMouseleave($event,t,'.p-card-tooltip')">
                <img [src]="member.photo_url" title={{getPersonName(member)}}>
            </span>
            <div>
                <div class="user-name truncate-1" [routerLink]="['/team', member['login']]"
                    [title]="getPersonName(member)">{{getPersonName(member)}}</div>
                <div class="city-designation truncate-1" [title]="getPersonTitle(member)">{{getPersonTitle(member)}}
                </div>
            </div>
        </li>
    </ul>
</div>

<div class="n-member-list" *ngIf="layout == 36">
    <p class="no-member" *ngIf="!members.length" i18n>No {{queryParam}} Found</p>
    <a *ngFor="let member of members">
        <ng-template #toolTipContent>
            <app-tooltip-person-card [data]="member" (mouseleave)="t.close()">
            </app-tooltip-person-card>
        </ng-template>
        <span class="avatar avatar-sm" [ngClass]="classes" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip'
            container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
            (mouseleave)="onMouseleave($event,t,'.p-card-tooltip')">
            <img [src]="member.photo_url" title={{getPersonName(member)}}>
        </span>
    </a>
</div>

<button *ngIf="members.length" class="btn btn-primary" [routerLink]="'/team'"
    [queryParams]="{practice: currentPractice.name, member: queryParam}">{{buttonText}}</button>
