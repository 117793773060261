import {
  Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2, Input, OnChanges
} from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit, OnChanges {
  itemWidth = 300;
  mcInnerwidth = 10000;
  showButton = true;
  innerWidth = 0;

  @Input() dataItem = '1,2,3,4';
  @Input() items = [];
  @Input() component: string;
  @Input() type: string;
  @Input() classes: string;
  @Input() matchHeight = 'card-body';
  @Input() sortable = false;
  @Input() displayPractices = true;
  @ViewChild('mc', { static: true }) itemsMainDiv: ElementRef;
  @ViewChild('mcInner', { static: true }) itemsDiv: ElementRef;
  @ViewChild('left', { static: true }) leftBtn: ElementRef;
  @ViewChild('right', { static: true }) rightBtn: ElementRef;

  @HostListener('window:resize')
  onResize() {
    if (Math.abs(window.innerWidth - this.innerWidth) > 100) {
      this.resCarouselSize();
    }
  }

  constructor(private renderer: Renderer2, private commonService: CommonMethodsService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.itemsDiv.nativeElement.style.transform = 'translateX(' + (0) + 'px)';
    setTimeout(() => {
      this.resCarouselSize();
    });
  }

  // changeSlide(target) {
  //   if (target.classList.contains('leftLst')) {
  //     this.resCarousel(0);
  //   } else {
  //     this.resCarousel(1);
  //   }
  // }

  resCarouselSize() {
    this.innerWidth = window.innerWidth;
    let incno = 0;
    let itemsSplit = [];
    const sampwidth = this.itemsMainDiv.nativeElement.offsetWidth;
    const bodyWidth = document.body.offsetWidth;

    const itemNumbers = this.itemsDiv.nativeElement.querySelectorAll('.item').length;
    itemsSplit = this.dataItem.split(',');

    if (bodyWidth >= 992) {
      incno = itemsSplit[3];
      this.itemWidth = sampwidth / incno;
    } else if (bodyWidth >= 768) {
      incno = itemsSplit[2];
      this.itemWidth = sampwidth / incno;
    } else if (bodyWidth > 575) {
      incno = itemsSplit[1];
      this.itemWidth = sampwidth / incno;
    } else {
      incno = itemsSplit[0];
      this.itemWidth = 350;
    }
    this.mcInnerwidth = this.itemWidth * itemNumbers;

    this.showButton = itemNumbers > incno ? true : false;
    if (this.showButton) {
      this.renderer.addClass(this.leftBtn.nativeElement, 'over');
      this.renderer.removeClass(this.rightBtn.nativeElement, 'over');
    }
  }

  // this function used to move the items
  resCarousel(e: number) {
    let translateXval = 0;
    const values = this.itemsDiv.nativeElement.style.transform.match(/-?[\d\.]+/g);
    const xds = Math.abs(values);

    if (e === 0) { // slide to left
      translateXval = xds - this.itemWidth;
      this.renderer.removeClass(this.rightBtn.nativeElement, 'over');

      if (translateXval <= this.itemWidth / 2) {
        translateXval = 0;
        this.renderer.addClass(this.leftBtn.nativeElement, 'over');
      }
    } else if (e === 1) { // slide to right
      const itemsCondition = this.itemsDiv.nativeElement.offsetWidth - this.itemsMainDiv.nativeElement.offsetWidth;
      translateXval = parseInt(xds.toString(), 0) + parseInt(this.itemWidth.toString(), 0);
      this.renderer.removeClass(this.leftBtn.nativeElement, 'over');

      if (translateXval >= itemsCondition - this.itemWidth / 2) {
        translateXval = itemsCondition;
        this.renderer.addClass(this.rightBtn.nativeElement, 'over');
      }
    }
    this.itemsDiv.nativeElement.style.transform = 'translateX(' + (-translateXval) + 'px)';
  }

  listOrderChanged(newList) {
    for (const [index, val] of Object.entries(newList)) {
      if (val['id'] !== this.items[index].id) {
        this.items = newList;
        // callapi to save
        this.commonService.itemSortEvent.emit(newList);
      }
    }
  }
}
