import { Component, Input, OnInit } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-change-nugget-preview',
  templateUrl: './change-nugget-preview.component.html',
  styleUrls: ['./change-nugget-preview.component.css']
})
export class ChangeNuggetPreviewComponent implements OnInit {
  @Input() type;
  @Input() practice;
  @Input() entityId;
  @Input() entityCategory;
  @Input() previewUrl;
  @Input() showPractices = true;

  constructor(private modalService: ModalPopupService,) { }

  ngOnInit(): void {
  }

  open(event) {
    event.preventDefault();
    event.stopPropagation();
    const previewData = { type: this.type, practice: this.practice, entityId: this.entityId, entityCategory: this.entityCategory, previewUrl: this.previewUrl, showPractices: this.showPractices };
    const data = { title: $localize`Change Preview`, message: '', component: 'CloudPickerComponent', data: previewData, size: 'lg' };
    this.modalService.open(data);
  }
}
