import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { TechRadarService } from '../tech-radar.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-tech-radar-card',
  templateUrl: './tech-radar-card.component.html',
  styleUrls: ['./tech-radar-card.component.css']
})
export class TechRadarCardComponent implements OnInit, OnDestroy {
  @Input() radar;
  @Input() editable = true;
  showActionIcon = false;
  editAccess = false;
  @Input() showFull = false;
  createdBy = [];
  radarType = '';
  @Output() techRadarDeleteEvent: EventEmitter<any> = new EventEmitter();

  constructor(private techRadarService: TechRadarService, private modalService: ModalPopupService,
    private commonService: CommonMethodsService, private toastr: ToastrService, private loginService: LoginService) { }

  ngOnInit(): void {
    this.editAccess = ((this.radar.creator?.login === this.loginService.currentUser.person?.login) &&
      (this.loginService.hasFullAccess('technology_radar_manager') || this.loginService.isPrivilegedUser())) ||
      this.loginService.isAllAccess();

    if (this.radar.creator) {
      this.createdBy.push(this.radar.creator)
    }
    this.setRadarType();
  }

  setRadarType() {
    const radarTypeId = this.radar['type'].id;
    if (radarTypeId == 1) {
      this.radarType = "Company"
    } else if (radarTypeId == 2) {
      this.radarType = this.radar['practices'][0].name
    }
  }

  deleteRadar(radar) {
    const data = {
      title: $localize`Delete Technology Radar`, message: $localize`Are you sure you want to delete ${radar['title']} radar?`, size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.techRadarService.deleteTechRadar(radar.id)
            .subscribe((response) => {
              this.techRadarDeleteEvent.emit(radar.id);
              this.commonService.showLoader = false;
              this.toastr.success(response['message']);
            }, e => {
              this.commonService.showLoader = false;
              this.toastr.error(e.error.error);
            });
        }
      })
      .catch(() => { });
  }

  ngOnDestroy() {
    this.techRadarDeleteEvent.unsubscribe();
  }

}
