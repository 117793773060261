import { Component, OnInit, Input } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.css']
})
export class PageListComponent implements OnInit {

  @Input() data: any;
  @Input() type: string;
  @Input() classes: string;

  constructor(private commonService: CommonMethodsService) { }

  ngOnInit() {
  }
}
