<div class="user-box" [ngClass]="{'border-bottom': !(data['login'] || data['person_id'])}">
  <div class="user_avatar">
    <span class="avatar avatar-xl">
      <img [src]="data.photo_url" [title]="personName">
    </span>
  </div>
  <div class="user_details">
    <ng-container *ngIf="data['login'] || data['person_id'];else noLogin">
      <a [routerLink]="['/team',data['login'] || data['person_id']]" class="p-name">{{personName | titlecase}}</a>
      <div class="date mt-8">{{ getPersonTitle(data) }} |
        <a [routerLink]="['/locations', data.contact.location?.city | removeSpace]" appStopPropagation
          *ngIf="data.contact.location?.city">
          {{data.contact.location.city}}
        </a>
      </div>
    </ng-container>
    <ng-template #noLogin>
      <span class="p-name">{{personName | titlecase}}</span>
    </ng-template>
    <div class="date mt-8" *ngIf="data.contact">
      <a href="http://mail.google.com/a/globallogic.com/mail?view=cm&to={{data.contact.email_id}}" target="_blank"
        appStopPropagation>{{data.contact.email_id| truncate:35}}</a>
    </div>
    <app-person-practices [person]="data" class="mt-10 d-block" container=''></app-person-practices>
  </div>
</div>
<div class="pt-footer" *ngIf="data['login'] || data['person_id']">
  <a [routerLink]=" ['/team',data['login'] || data['person_id']]" class="view-profile" i18n>View Profile</a>
</div>
