import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.css'],
})
export class CmsPageComponent {

  @Input() page_id = '';
  @Input() title = '';
}
