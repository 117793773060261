import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { LoginService } from 'app/services/auth/login.service';
import { environment } from 'environments/environment';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-join-practice',
  templateUrl: './join-practice.component.html',
  styleUrls: ['./join-practice.component.css']
})
export class JoinPracticeComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() data;
  skillsUrl;
  submitted = false;
  httpError;
  invalidForm = false;

  constructor(private fb: UntypedFormBuilder, private modalService: ModalPopupService, private practiceService: PracticesService,
    private formValidator: FormValidatorService, private loginService: LoginService, private commonService: CommonMethodsService) { }

  ngOnInit() {
    if (this.loginService.currentUser.person && this.loginService.currentUser.person.login) {
      this.skillsUrl = `${environment.gloUrl}users/profile/${this.loginService.currentUser.person.login}#profileCareer`;
    }
    this.form = this.fb.group({
      comments: this.fb.control(null, [this.formValidator.whiteSpaceValidation, Validators.minLength(10), Validators.maxLength(5000)])
    });
  }

  cancel() {
    this.modalService.close();
  }

  onSubmit() {
    this.invalidForm = true;
    if (!this.submitted) {
      if (!this.form.invalid) {
        this.invalidForm = false;
        this.submitted = true;
        const data = { comments: this.form.value['comments'] };

        this.practiceService.nominate(this.data['id'], data)
          .subscribe(response => {
            this.practiceService.joinPracticeEvent.emit(response);
            this.cancel();
          }, error => {
            this.submitted = false;
            this.httpError = this.commonService.parseHttpErrors(error);
          });
      }
    }
  }
}
