import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { PeopleService } from 'app/services/people/people.service';
import { RouterExtraService } from 'app/services/router-extra/router-extra.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-recommended-nugget-card',
  templateUrl: './recommended-nugget-card.component.html',
  styleUrls: ['./recommended-nugget-card.component.css']
})
export class RecommendedNuggetCardComponent implements OnInit {
  @Input() data: any;
  @Input() type: string;
  url: string;
  displayNumber = 4;
  members = [];
  showMember = true;
  queryParams = {};

  constructor(private peopleService: PeopleService, private commonService: CommonMethodsService, private filterService: FilterService,
    private routerService: RouterExtraService) { }

  ngOnInit() {
    if (this.type === 'poc') {
      this.url = `/pocs`;
    } else if (this.type === 'tech-talk') {
      this.url = `/tech-talks`;
    } else if (this.type === 'tech-doc') {
      this.url = `/tech-papers`;
    } else if (this.type === 'case-study') {
      this.url = `/case-studies`;
      this.showMember = false;
    } else if (this.type === 'deck') {
      this.url = `/practices/${this.data.practice.c_name}/sales-materials`;
      this.showMember = false;
    } else if (this.type === 'curated-content') {
      this.url = `/practices/${this.data.practice.c_name}/recommended-reads`;
      this.showMember = false;
    }

    this.members = this.peopleService.getNuggetMembers(this.data);
  }

  getDate() {
    return this.commonService.getNuggetDate(this.data, this.type);
  }

  click() {
    this.filterService.deleteFilter();
  }
}
