import { Component, Input, OnInit } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @Input() data: any;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit(): void {
  }

  close(e) {
    // e.preventDefault()
    this.modalService.close();
    // window.open(`http://mail.google.com/a/globallogic.com/mail?view=cm&to=${this.data.mail}`, '_blank');
  }
}
