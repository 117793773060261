import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';

import { environment } from 'environments/environment';
import { FieldConfig } from 'app/interfaces/field';
import { CustomerActivityService } from '../customer-activities/customer-activity.service';
import { CommonMethodsService } from '../util/common-methods.service';
import { TagService } from '../tags/tag.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  class = '';
  filters = [];
  apiFilters = [];
  apiUrl = environment.apiUrl;
  filtersUpdate = new EventEmitter();
  targetElement = '';
  exportUri = ''; // export api uri
  fileName = 'export'; // export filename without extension
  filteredUrl = '';
  showFilter = false;
  pageFilters = [];
  advancePageFilters = [];
  form: UntypedFormGroup;
  advanceForm: UntypedFormGroup;
  filterConfig: FieldConfig[] = [];
  advanceFilterConfig: FieldConfig[] = [];
  minDate = {};
  maxDate = {};
  ccLocations = [];
  nuggetOptions = [
    { id: 'blog', name: $localize`Blog` },
    { id: 'case_study', name: $localize`Case Study` },
    // { id: 'customer_activity', name: 'Customer Activity' },
    // { id: 'conference_activity', name: 'Event' },
    { id: 'poc', name: $localize`Innovation` },
    { id: 'tech_document', name: $localize`Tech Document` },
    { id: 'tech_talk', name: $localize`Tech Talk` },
  ];

  fieldMap = {
    search: { label: $localize`Search`, name: 'search', type: 'input', inputType: 'text', paramName: 'search', hide: false, order: 0 },
    search_people: {
      label: $localize`Search People and Add to Practice`, name: 'search_people', type: 'searchPeople',
      paramName: 'search_people', hide: false, order: 0
    },
    customers: { label: $localize`Customers`, name: 'customers', type: 'customerFilter', paramName: 'customers', hide: false, order: 1 },
    country: { label: $localize`Country`, name: 'country', type: 'filterLink', options: [], hide: false, order: 0 },
    center: { label: $localize`Center`, name: 'center', type: 'filterLink', options: [], hide: false, order: 1 },
    providers: { label: $localize`Providers`, name: 'providers', type: 'filterDropdown', paramName: 'providers', options: [], hide: false, order: 1 },
    entity_categories: { label: $localize`Categories`, name: 'entity_categories', type: 'filterDropdown', paramName: 'categories', options: [], hide: false, order: 2 },
    radar_categories: { label: $localize`Categories`, name: 'radar_categories', type: 'filterLink', paramName: 'categories', options: [], hide: false, order: 2 },
    practices: { label: $localize`Practices`, name: 'practices', type: 'nestedFilter', paramName: 'practice', options: [], hide: false, order: 3 },
    locations: {
      label: $localize`Locations`, name: 'locations', type: 'filterDropdown', paramName: 'locations', options: [], hide: false, order: 4
    },
    industries: { label: $localize`Industries`, name: 'industries', type: 'filterDropdown', paramName: 'industries', options: [], hide: false, order: 5 },
    industry_segments: { label: $localize`Industry Segments`, name: 'industry_segments', type: 'nestedFilter', paramName: 'industry_segments', options: [], hide: false, order: 5 },
    marketplaces: { label: $localize`Published On`, name: 'marketplaces', type: 'filterDropdown', paramName: 'marketplaces', options: [], hide: false, order: 6 },
    member_types: {
      label: $localize`Member Type`, name: 'member_types', type: 'filterDropdown', paramName: 'member', options: [], hide: false, order: 6
    },
    tech_document_types: {
      label: $localize`Type`, name: 'tech_document_types', type: 'filterDropdown', paramName: 'tech_document_types', options: [], hide: false,
      order: 6
    },
    initiatives: {
      label: $localize`Initiatives`, name: 'initiatives', type: 'filterDropdown', paramName: 'initiatives', options: [], hide: false,
      order: 7
    },
    nugget_type: {
      label: $localize`Nuggets`, name: 'nugget_type', type: 'filterDropdown', paramName: 'nugget_type', options: this.nuggetOptions,
      hide: false, order: 8
    },
    languages: {
      label: $localize`Languages`, name: 'languages', type: 'filterDropdown', paramName: 'languages', options: [],
      hide: false, order: 9
    },
    nugget_statuses: {
      label: $localize`Status`, name: 'nugget_statuses', type: 'filterDropdown', paramName: 'nugget_statuses', options: [], hide: false, order: 10
    },
    feedback_statuses: {
      label: $localize`Status`, key: 'feedback_statuses', name: 'statuses', type: 'filterDropdown', paramName: 'statuses', options: [], hide: false, order: 10
    },
    poc_statuses: {
      label: $localize`Status`, name: 'poc_statuses', type: 'filterDropdown', paramName: 'poc_statuses', options: [], hide: false, order: 10
    },
    poc_types: {
      label: $localize`Types`, name: 'poc_types', type: 'filterDropdown', paramName: 'poc_types', options: [], hide: false, order: 14
    },
    featured: {
      label: $localize`Featured`, name: 'featured', type: 'filterDropdown', paramName: 'featured', options: [], hide: false, order: 11
    },
    poc_purposes: {
      label: $localize`Purposes`, name: 'poc_purposes', type: 'filterDropdown', paramName: 'poc_purposes', options: [], hide: false, order: 12
    },
    visible: {
      label: $localize`Visiblity`, name: 'visible', type: 'filterDropdown', paramName: 'visible', options: [], hide: false, order: 13
    },
    award_types: {
      label: $localize`Type`, name: 'award_types', type: 'filterDropdown', paramName: 'award_types', options: [], hide: false, order: 7
    },
    quarters: {
      label: $localize`Quarter`, name: 'quarters', type: 'filterDropdown', paramName: 'quarters', options: [], hide: false, order: 8
    },
    tags: { label: $localize`Tags`, name: 'tags', type: 'tagFilter', paramName: 'tags', hide: false, order: 20 },
    from: { label: $localize`From`, name: 'from', type: 'date', paramName: 'fromDate', hide: false, order: 21 },
    to: { label: $localize`To`, name: 'to', type: 'date', paramName: 'toDate', hide: false, order: 22 },

    practices_facet: {
      label: $localize`Practices`, name: 'practices', type: 'facetOptions', paramName: 'practice', options: [], hide: false, order: 2
    },
    locations_facet: {
      label: $localize`Locations`, name: 'locations', type: 'facetOptions', paramName: 'locations', options: [], hide: false, order: 3
    },
    industries_facet: { label: $localize`Industries`, name: 'industries', type: 'facetOptions', paramName: 'industries', options: [], hide: false, order: 4 },
    member_types_facet: {
      label: $localize`Member Type`, name: 'member_types', type: 'facetOptions', paramName: 'member', options: [], hide: false, order: 5
    },
    tech_document_types_facet: {
      label: $localize`Type`, name: 'tech_document_types', type: 'facetOptions', paramName: 'tech_document_types', options: [], hide: false, order: 5
    },
    languages_facet: {
      label: $localize`Languages`, name: 'languages', type: 'facetOptions', paramName: 'languages', options: [],
      hide: false, order: 6
    },
    poc_statuses_facet: {
      label: $localize`Status`, name: 'poc_statuses', type: 'facetOptions', paramName: 'poc_statuses', options: [], hide: false, order: 6
    },
    poc_types_facet: {
      label: $localize`Types`, name: 'poc_types', type: 'facetOptions', paramName: 'poc_types', options: [], hide: false, order: 6
    },
    featured_facet: {
      label: $localize`Featured`, name: 'featured', type: 'facetOptions', paramName: 'featured', options: [], hide: false, order: 7
    },
    page_types_facet: {
      label: $localize`Type`, name: 'page_types', type: 'facetOptions', paramName: 'page_types', options: [], hide: false, order: 7
    },
    digital_accelerator_categories_facet: {
      label: $localize`Categories`, name: 'entity_categories', type: 'facetOptions', paramName: 'digital_accelerator_categories', options: [], hide: false, order: 8
    },

    action: { label: $localize`Action`, name: 'action', type: 'action', paramName: '', hide: false, order: 30 },
    people: { label: '', name: 'people', type: 'noUi', paramName: 'people', hide: false, order: 31 },
    primaryPractices: {
      label: $localize`Practices`, name: 'primaryPractices', type: 'noUi', paramName: 'primaryPractices', options: [], hide: false, order: 33
    },
  };
  filterPage = 0;

  constructor(private http: HttpClient, private clipboardService: ClipboardService, private toastr: ToastrService,
    private route: ActivatedRoute, private customerActivityService: CustomerActivityService, private commonService: CommonMethodsService,
    private router: Router, private tagService: TagService) { }

  getFilterKeyForRoute(route?) {
    let uri = '';
    if (route) {
      uri = route;
    } else {
      uri = this.router.url.split('?')[0];
    }
    if (uri.includes('/locations')) {
      uri = '/locations';
    }
    return uri.replace(/\/|-/g, '_');
  }

  setFilterLabel(filter, label) {
    this.fieldMap[filter]['placeholder'] = label;
  }

  resetFilterLabel() {
    Object.entries(this.fieldMap).forEach(([key]) => {
      this.fieldMap[key]['placeholder'] = '';
    })
  }

  saveFilter() {
    const key = this.getFilterKeyForRoute();
    const filterPage = ['_case_studies', '_pocs', '_tech_papers', '_tech_talks', '_blogs', '_advisories', '_customer_activities',
      '_team', '_locations', '_search_results', '_admin_contributions', '_initiatives_tech_thursday', '_initiatives_innovation_gl'];
    if (filterPage.includes(key)) {
      const uri = this.getFilteredRoute();
      if (uri) {
        sessionStorage.setItem('savedFilters', uri);
      } else {
        this.deleteFilter('filter');
      }
    } else if (!filterPage.filter(f => key.includes(f)).length) {
      this.deleteFilter();
    }
  }

  addPageStatusInfo(page = 1, tab?) {
    setTimeout(() => {
      this.saveFilter();
      let uri = this.getFilteredRoute();
      if (page > 1) {
        uri += (uri.indexOf('?') > -1 ? '&' : '?') + `page=${page}`;
      } else {
        sessionStorage.removeItem('page');
      }
      if (tab) {
        uri += (uri.indexOf('?') > -1 ? '&' : '?') + `tab=${tab}`;
      } else {
        sessionStorage.removeItem('tab');
      }
      sessionStorage.setItem('savedFilters', uri);

    }, 100);
  }

  deleteFilter(type = 'all') {
    sessionStorage.removeItem('savedFilters');
    if (type === 'all') {
      sessionStorage.removeItem('page');
      sessionStorage.removeItem('tab');
    }
  }

  // set filter values for api's and chip
  setFilters() {
    let filterValues, fields;
    if (this.advanceFilterConfig.length === this.advancePageFilters.length && this.advanceForm) {
      filterValues = { ...this.form?.value, ...this.advanceForm?.value };
      fields = [...this.filterConfig, ...this.advanceFilterConfig];
    } else {
      filterValues = this.form.value;
      fields = this.filterConfig;
    }

    this.apiFilters = [];
    this.filters = [];
    fields.forEach(field => {
      if (['filterDropdown', 'facetOptions'].includes(field.type) ||
        (field.type === 'noUi' && field.name === 'primaryPractices')) {
        const values = [];
        this.filters = this.filters.concat(filterValues[field.name]
          .map((checked, index) => {
            let selected = null;
            if (checked) {
              field.options[index]['index'] = index;
              field.options[index]['filterType'] = field.name;
              field.options[index]['paramName'] = field['paramName'];
              field.options[index]['hide'] = field['hide'];
              selected = field.options[index];
            }
            return selected;
          })
          .filter(value => {
            if (value !== null) {
              values.push(value.id);
              return value;
            }
          }));
        if (values.length) {
          this.apiFilters.push({ key: 'filters[' + field.name + '][]', values: values });
        }
      } else if (field.type === 'date') {
        if (filterValues[field.name]) {
          let date = filterValues[field.name];
          if (field.name === 'to') {
            this.maxDate = date;
          } else if (field.name === 'from') {
            this.minDate = date;
          }

          date = `${date.year}-${date.month}-${date.day}`;
          this.filters = this.filters.concat([{ value: date, filterType: field.name, paramName: field['paramName'] }]);
          this.apiFilters.push({ key: 'filters[' + field.name + ']', values: [date] });
        }
      } else if (field.type === 'customerFilter' || field.type === 'tagFilter') {
        if (filterValues[field.name]) {
          const values = [];
          this.filters = this.filters.concat(filterValues[field.name]
            .map(value => {
              values.push(value.id);
              value['filterType'] = field.name;
              value['paramName'] = field['paramName'];
              value['hide'] = field['hide'];
              return value;
            }));
          if (values.length) {
            this.apiFilters.push({ key: 'filters[' + field.name + '][]', values: values });
          }
        }
      } else if (field.type === 'searchPeople') {
        if (filterValues[field.name]) {
          this.filters = this.filters.concat([{
            name: filterValues[field.name],
            filterType: field.name,
            paramName: field['paramName']
          }]);
          this.apiFilters.push({ key: 'filters[' + field.name + ']', values: [filterValues[field.name]] });
        }
      } else if (field.type === 'nestedFilter') {
        if (filterValues[field.name]) {
          const filter = filterValues[field.name].map(v => ({
            ...v,
            name: v.name,
            filterType: field.name,
            paramName: field['paramName'],
            type: field.type
          }))
          this.filters = this.filters.concat(filter);
          this.apiFilters.push({ key: 'filters[' + field.name + '][]', values: filterValues[field.name].map(v => v.id) });
        }
      } else {
        if (filterValues[field.name]) {
          if (field.name === 'people') {
            this.filters = this.filters.concat([{ name: filterValues[field.name], filterType: field.name, paramName: field['paramName'] }]);
            this.apiFilters.push({ key: 'filters[' + field.name + '][]', values: [filterValues[field.name]] });
          } else {
            this.filters = this.filters.concat([{
              value: filterValues[field.name],
              filterType: field.name,
              paramName: field['paramName']
            }]);
            this.apiFilters.push({ key: 'filters[' + field.name + ']', values: [filterValues[field.name]] });
          }
        }
      }
    });
    // emit filters updated values
    this.filtersUpdate.emit({ filter: this.filters, apiFilter: this.apiFilters, target: this.targetElement });
    this.saveFilter();
  }

  export() {
    const headers = new HttpHeaders({
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const params = this.getApiParams(this.apiFilters);

    return this.http.get(this.apiUrl + this.exportUri, { headers: headers, responseType: 'blob', params: params })
      .subscribe(data => {
        this.downloadFile(data, this.fileName.substr(0, 150) + '.xlsx');
      });
  }

  exportPDF() {
    const params = this.getApiParams(this.apiFilters);
    return this.http.get(this.apiUrl + this.exportUri, { responseType: 'blob', params: params })
      .subscribe(data => {
        this.downloadFile(data, this.fileName.substr(0, 150) + '.pdf');
      });
  }

  downloadFile(data: Blob, fileName) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    this.commonService.showLoader = false;
  }

  getApiParams(filters) {
    let params = new HttpParams();
    if (filters.length > 0) {
      filters.forEach(filter => {
        filter['values'].forEach(value => {
          params = params.append(filter['key'], value);
        });
      });
    }
    return params;
  }

  removeFilter(filter) {
    if (filter.filterType === 'people' || filter.filterType === 'from' || filter.filterType === 'to') {
      if (this.form && this.form.get(filter.filterType)) {
        this.form.get(filter.filterType).setValue(null);
      } else if (this.advanceForm && this.advanceForm.get(filter.filterType)) {
        this.advanceForm.get(filter.filterType).setValue(null);
        this.setFilters();
      }
    } else if (filter.filterType === 'customers') {
      const newValue = this.form.get(filter.filterType).value.filter(value => value.id !== filter.id);
      $('#customers-filter select option[value="' + filter.id + '"]').remove();
      this.form.get(filter.filterType).setValue(newValue);
    } else if (filter.filterType === 'tags') {
      const newValue = this.form.get(filter.filterType).value.filter(value => value.id !== filter.id);
      $('#tags-filter select option[value="' + filter.id + '"]').remove();
      this.form.get(filter.filterType).setValue(newValue);
    } else if (filter.filterType === 'search_people') {
      this.form.get(filter.filterType).setValue(null);
    } else if (filter.type === 'nestedFilter') {
      const newValue = this.advanceForm?.get(filter.filterType)?.value.filter(value => value.id !== filter.id);
      this.advanceForm?.get(filter.filterType)?.setValue(newValue);
      const newFormValue = this.form?.get(filter.filterType)?.value.filter(value => value.id !== filter.id);
      this.form?.get(filter.filterType)?.setValue(newFormValue);
      this.setFilters();
    } else if (this.form && this.form.get(filter.filterType)) {
      this.targetElement = filter.filterType;
      (<UntypedFormArray>this.form.get(filter.filterType)).at(filter.index).setValue(false);
    } else if (this.advanceForm && this.advanceForm.get(filter.filterType)) {
      this.targetElement = filter.filterType;
      (<UntypedFormArray>this.advanceForm.get(filter.filterType)).at(filter.index).setValue(false);
      this.setFilters();
    }
  }

  clearFilters() {
    this.minDate = null;
    this.maxDate = null;
    $('#customers-filter select').val(null).trigger('change');
    $('#tags-filter select').val(null).trigger('change');
    // $('#search-people select').val(null).trigger('change');
    this.form.reset();
    this.advanceForm?.reset();
  }

  reset(form = false) {
    // if (this.form && form) {
    //   this.form.reset();
    // }
    this.filters = [];
    this.exportUri = '';
    this.filteredUrl = '';
    this.fileName = 'export';
    this.form = new UntypedFormGroup({});
    this.advanceForm = new UntypedFormGroup({});
    this.showFilter = false;
    this.pageFilters = [];
    this.advancePageFilters = [];
    this.ccLocations = [];
    this.filterConfig = [];
    this.advanceFilterConfig = [];
    this.resetFilterLabel()
  }

  getFilteredUrl() {
    return window.location.origin + this.getFilteredRoute();
  }

  getFilteredRoute(action = 'copy') {
    const queryParams = this.getQueryParams();
    let params = '';
    if (this.getFilterKeyForRoute() === '_search_results') {
      this.route.queryParamMap.subscribe(param => {
        if (param.has('psearchterm')) {
          params = '?psearchterm=' + param.get('psearchterm');
        }
      });
    }
    Object.keys(queryParams).forEach((param) => {
      params += (params ? '&' : '?') + param + '=' + encodeURIComponent(queryParams[param]);
    });
    if (action === 'copy') {
      if (+this.filterPage > 1) {
        params += (params ? '&' : '?') + `page=${this.filterPage}`;
      }
      return window.location.pathname + params;
    }
    return params ? window.location.pathname + params : '';
  }

  getQueryParams(filters?) {
    const queryParams = {};
    (filters || this.filters).forEach(filter => {
      const value = ['customers', 'tags'].includes(filter.filterType) ? filter.id : filter.name || filter.value;
      if (queryParams[filter.paramName]) {
        queryParams[filter.paramName] += ',' + value;
      } else {
        queryParams[filter.paramName] = value;
      }
    });
    return queryParams;
  }

  copy() {
    this.clipboardService.copyFromContent(this.getFilteredUrl());
    this.toastr.success($localize`URL copied to clipboard.`);
  }

  setDefaultFilter(filters) {
    const interval = setInterval(() => {
      filters.map(filter => {
        if (this.form && this.form.get(filter.key)) {
          if (this.fieldMap[filter.key].type === 'filterDropdown') {
            const formValues = this.fieldMap[filter.key].options.map(option => { // create options value array
              return filter.values.indexOf(option.id) > -1 ? true : false;
            });
            this.form.get(filter.key).setValue(formValues);
          } else if (this.fieldMap[filter.key].type === 'date') {
            this.form.get(filter.key).setValue(filter.values[0]);
          }
          clearInterval(interval);
          this.setFilters();
        }
        if (this.advanceForm && this.advanceForm.get(filter.key)) {
          if (this.fieldMap[filter.key].type === 'filterDropdown') {
            const formValues = this.fieldMap[filter.key].options.map(option => { // create options value array
              return filter.values.indexOf(option.id) > -1 ? true : false;
            });
            this.advanceForm.get(filter.key).setValue(formValues);
          } else if (this.fieldMap[filter.key].type === 'date') {
            this.advanceForm.get(filter.key).setValue(filter.values[0]);
          }
          clearInterval(interval);
          this.setFilters();
        }
      });
    }, 100);
  }

  setFilterByParams(key) {
    const routeSubscribe = this.route.queryParamMap
      .subscribe(params => {
        if (params.has(this.fieldMap[key].paramName)) {
          if (['filterDropdown', 'facetOptions'].includes(this.fieldMap[key].type) ||
            (this.fieldMap[key].type === 'noUi' && this.fieldMap[key].name === 'primaryPractices')) {
            const paramValues = params.get(this.fieldMap[key].paramName).split(',');
            const formValues = this.fieldMap[key].options.map(option => { // create options value array
              return paramValues.indexOf(option.name) > -1 ? true : false;
            });

            const interval = setInterval(() => {
              if (this.form?.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.form.get(this.fieldMap[key].name).setValue(formValues);
              }
              if (this.advanceForm?.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.advanceForm.get(this.fieldMap[key].name).setValue(formValues);
              }
            }, 50); // set form value
          } else if (['nestedFilter'].includes(this.fieldMap[key].type)) {
            const paramValues = params.get(this.fieldMap[key].paramName).split(',');
            const formValues = this.fieldMap[key].options.filter(option => paramValues.some(p => option.name === p));
            const interval = setInterval(() => {
              if (this.form?.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.form.get(this.fieldMap[key].name).setValue(formValues);
              }
              if (this.advanceForm?.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.advanceForm.get(this.fieldMap[key].name).setValue(formValues);
              }
            }, 50); // set form value
          } else if (this.fieldMap[key].type === 'customerFilter') {
            const paramValues = params.get(this.fieldMap[key].paramName).split(',');
            const filter = [{ key: 'id[]', values: paramValues }];
            const param = this.getApiParams(filter);
            this.customerActivityService.getCustomers(param)
              .subscribe(response => {
                const clients = response['clients'].map((client) => {
                  // set html values
                  const name = client.name + (client.erp_number ? ` (${client.erp_number})` : '');
                  const newOption = new Option(name, client.id, false, true);
                  $('#customers-filter select').append(newOption).trigger('change');
                  return {
                    id: client.id,
                    name: name
                  };
                });

                const interval = setInterval(() => {
                  if (this.form && this.form.get(this.fieldMap[key].name)) {
                    clearInterval(interval);
                    this.form.get(this.fieldMap[key].name).setValue(clients);
                    $('#customers-filter select').val(null).trigger('change');
                  }
                  if (this.advanceForm && this.advanceForm.get(this.fieldMap[key].name)) {
                    clearInterval(interval);
                    this.advanceForm.get(this.fieldMap[key].name).setValue(clients);
                    $('#customers-filter select').val(null).trigger('change');
                  }
                }, 50); // set form value
              });
          } else if (this.fieldMap[key].type === 'tagFilter') {
            const paramValues = params.get(this.fieldMap[key].paramName).split(',');
            const filter = [{ key: 'filters[tagId]', values: paramValues }, { key: 'filters[approved]', values: [1] }];
            const param = this.getApiParams(filter);
            this.tagService.getTags(param)
              .subscribe(response => {
                const tags = response['tags'].map((tag) => {
                  // set html values
                  const name = tag.name;
                  const newOption = new Option(name, name, false, true);
                  $('#tags-filter select').append(newOption).trigger('change');
                  return {
                    id: tag.id,
                    name: name
                  };
                });
                const interval = setInterval(() => {
                  if (this.form && this.form.get(this.fieldMap[key].name)) {
                    clearInterval(interval);
                    this.form.get(this.fieldMap[key].name).setValue(tags);
                    $('#tags-filter select').val(null).trigger('change');
                  }
                  if (this.advanceForm && this.advanceForm.get(this.fieldMap[key].name)) {
                    clearInterval(interval);
                    this.advanceForm.get(this.fieldMap[key].name).setValue(tags);
                    $('#tags-filter select').val(null).trigger('change');
                  }
                }, 50); // set form value
              });
          } else if (this.fieldMap[key].type === 'date') {
            const dateParam = params.get(this.fieldMap[key].paramName);
            let dateObj;
            if (dateParam) {
              const date = dateParam.split('-');
              dateObj = { year: +date[0], month: +date[1], day: +date[2] };
            } else {
              dateObj = null;
            }
            const interval = setInterval(() => {
              if (this.form && this.form.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.form.get(this.fieldMap[key].name).setValue(dateObj);
              }
              if (this.advanceForm && this.advanceForm.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.advanceForm.get(this.fieldMap[key].name).setValue(dateObj);
              }
            }, 50);

            // setTimeout(() => this.form.get(this.fieldMap[key].name).setValue(dateObj), 1000); // set form value
          } else {
            const interval = setInterval(() => {
              if (this.form && this.form.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.form.get(this.fieldMap[key].name).setValue(params.get(this.fieldMap[key].paramName));
              }
              if (this.advanceForm && this.advanceForm.get(this.fieldMap[key].name)) {
                clearInterval(interval);
                this.advanceForm.get(this.fieldMap[key].name).setValue(params.get(this.fieldMap[key].paramName));
              }
            }, 50);
            // setTimeout(() => this.form.get(this.fieldMap[key].name).setValue(params.get(this.fieldMap[key].paramName)),
            //   1000); // set form value
          }
        } else if (params.has('duration')) {
          let from = {};
          let fromDate = new Date();
          const toDate = new Date();
          const to = { year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate() };

          switch (params.get('duration')) {
            case 'weekly': fromDate = new Date(toDate.setDate(toDate.getDate() - 7));
              break;
            case 'monthly': fromDate = new Date(toDate.setMonth(toDate.getMonth() - 1));
              break;
            case 'yearly': fromDate = new Date(toDate.setFullYear(toDate.getFullYear() - 1));
              break;
          }

          from = {
            year: fromDate.getFullYear(),
            month: fromDate.getMonth() + 1,
            day: fromDate.getDate()
          };

          const interval = setInterval(() => {
            if (this.form && this.form.get('from')) {
              clearInterval(interval);
              this.form.get('from').setValue(from);
              this.form.get('to').setValue(to);
            }
            if (this.advanceForm && this.advanceForm.get('from')) {
              clearInterval(interval);
              this.advanceForm.get('from').setValue(from);
              this.advanceForm.get('to').setValue(to);
            }
          }, 50);
          // setTimeout(() => {
          //   this.form.get('from').setValue(from);
          //   this.form.get('to').setValue(to);
          // }, 1000); // set form value
        }
      });

    routeSubscribe.unsubscribe();
  }

  // getFilterBySearch(searchText) {
  //   const filter = [{ key: 'search', values: [searchText] }];
  //   const params = this.getApiParams(filter);
  //   return this.http.get(this.apiUrl + '/v1/tokens', { params: params });
  // }

  // saveSearchHistory(data) {
  //   return this.http.post(this.apiUrl + '/v1/search-history', data);
  // }

}
