import { Component, OnInit, Input } from '@angular/core';
import { PracticesService } from 'app/services/practices/practices.service';
import { PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-recommendation-card',
  templateUrl: './recommendation-card.component.html',
  styleUrls: ['./recommendation-card.component.css']
})
export class RecommendationCardComponent implements OnInit {
  @Input() data: any;
  practiceCName: any;

  constructor(private practicesService: PracticesService, public peopleService: PeopleService,
    public commonService: CommonMethodsService) { }

  ngOnInit() {
    this.practiceCName = this.practicesService.currentPractice.c_name;
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
