import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { People, PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assigned',
  templateUrl: './assigned.component.html',
  styleUrls: ['./assigned.component.css']
})
export class AssignedComponent implements OnChanges {
  @Input() data;
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();

  reviewerName;

  constructor(private nuggetService: NuggetsService, private modalService: ModalPopupService, private toastr: ToastrService,
    private peopleService: PeopleService, private commonService: CommonMethodsService) { }

  ngOnChanges() {
    this.reviewerName = this.peopleService.getPersonName(this.data['reviewer']);
  }

  onDelete() {
    const data = {
      title: `Delete reviewer`, message: `Are you sure you want to delete ${this.reviewerName} as reviewer?`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: {
              entity_category_id: this.data['entity_category_id'],
            },
          };
          this.commonService.showLoader = true;
          this.nuggetService.deleteAssignee(this.data['id'], options)
            .subscribe(response => {
              this.toastr.success(response['message']);
              this.data = undefined;
              this.commonService.showLoader = false;
              this.delete.emit(true);
            }, error => {
              this.toastr.error(this.commonService.parseHttpErrors(error));
              this.commonService.showLoader = false;
            });
        }
      })
      .catch(() => { });
  }
}
