import { Component, OnInit, OnDestroy, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Subscription, shareReplay, switchMap, tap } from 'rxjs';
// import { Options } from 'select2';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { DigitalAcceleratorsService } from 'app/modules/feature/digital-accelerator/digital-accelerators.service';
import { PracticesService } from 'app/services/practices/practices.service';

@Component({
  selector: 'app-industry-segment-option',
  templateUrl: './industry-segment-option.component.html',
  styleUrls: ['./industry-segment-option.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IndustrySegmentOptionComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => IndustrySegmentOptionComponent)
    }
  ]
})
export class IndustrySegmentOptionComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {

  form: UntypedFormGroup;
  @Input() helpText = '';
  @Input() invalidForm = false;
  @Input() optional = false;
  @Input() hideLabel = false;
  @Input() showAll = false;

  label = $localize`Industry Segments`;
  placeholder = $localize`Select industry segments`;
  industriesList = [];
  filteredIndustries = [];
  selected = [];
  verticalPractices = [];

  value = null;
  onChange = (value) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;
  onValidationChange = () => { };
  onChangeSub: Subscription;

  constructor(private digitalAcceleratorService: DigitalAcceleratorsService, private fb: UntypedFormBuilder, private formValidator: FormValidatorService, private practiceService: PracticesService,) { }

  ngOnInit() {
    // this.digitalAcceleratorService.getIndustries()
    //   .subscribe(response => {
    //     this.industriesList = response['industries'].flatMap(i => i.industry_segments.map(s => ({ id: s.id, name: s.name, industry_id: i.id, industry: i.name, practice_id: i.practice_id })));
    //     if(this.showAll) {
    //       this.filteredIndustries = this.industriesList;
    //     } else {
    //       this.filterIndustriesByPractice(this.verticalPractices);  
    //     }
    //   });
    // this.practiceService.selectVerticalPracticeEvent.subscribe(response => {
    //   this.verticalPractices = response;
    //   this.filterIndustriesByPractice(response);
    // });

    this.practiceService.selectVerticalPracticeEvent.pipe(
      tap(response => {
        this.verticalPractices = response;
      }),
      switchMap(() => this.digitalAcceleratorService.getIndustries().pipe(shareReplay(1)))
    ).subscribe(response => {
      this.industriesList = response['industries'].flatMap(i => i.industry_segments.map(s => ({ id: s.id, name: s.name, industry_id: i.id, industry: i.name, practice_id: i.practice_id })));
      if(this.showAll) {
        this.filteredIndustries = this.industriesList;
      } else {
        this.filterIndustriesByPractice(this.verticalPractices);  
      }
    });
  }

  filterIndustriesByPractice(practices) {
    const practiceIds = practices.map(practice => practice.id);
    this.filteredIndustries = this.industriesList.filter(({ practice_id }) => practiceIds.includes(practice_id));
  
    if(this.industriesList.length && this.value.length) {
      this.value = this.value.map(value => {
        let industry = this.industriesList.find(industry => industry.industry_id === value.industry_id);
        return industry ? {...value, practice_id: industry.practice_id} : value;
      }).filter(({ practice_id }) => practiceIds.includes(practice_id));
      this.valueChange();
    }
  }

  valueChange() {
    let value = null;
    if (this.value) {
      value = this.value.reduce((acc, cur) => {
        const index = acc.findIndex(a => a.id === cur.industry_id)
        if (index > -1) {
          acc[index].industry_segments.push(cur.id)
        } else {
          const industry = { id: cur.industry_id, industry_segments: [cur.id] }
          acc.push(industry)
        }
        return acc;
      }, [])
    }
    this.onChange(value)
  }

  writeValue(value: any[] | null) {
    this.value = value?.flatMap(i => i.industry_segments.map(s => ({ id: s.id, name: s.name, industry_id: i.id, industry: i.name }))) || null;

    // update control value
    setTimeout(() => {
      this.valueChange();
    }, 0);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.value ? null : { invalidForm: { valid: false, message: "Invalid industries" } };
  }

  remove(id: number) {
    if (this.value.length) {
      this.value = this.value.filter(v => v.id !== id);
    }
  }

  ngOnDestroy(): void {
    this.onChangeSub?.unsubscribe();
  }

}
