<ng-template #commentForm>
  <div class="report-nugget" *ngIf="form">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div>
        <textarea formControlName="comment" rows="3" maxlength="250"></textarea>
        <div class="help mt-5" i18n>Reported {{data.type | lowercase}} will be reviewed by the practice leaders and
          they will take the appropriate action.</div>
      </div>
      <button class="btn btn-primary btn-sm mt-10" type="submit" [disabled]="form.invalid || submitted"
        [ngClass]="{'btn-disabled':form.invalid,'submitted':submitted}">{{submitText}}</button>
      <button class="btn btn-link btn-sm mt-10" type="button" (click)="edit=false" *ngIf="edit" i18n>Cancel</button>
    </form>
  </div>
</ng-template>

<div class="row members-row no-gutters " *ngIf="(userFlag && !edit);else commentForm;">
  <div class="col-md-1">
    <span class="avatar avatar-sm">
      <ng-container *ngIf="userFlag.person && userFlag.person.login; else inactiveIcon;">
        <ng-template #toolTipContent>
          <app-tooltip-person-card [data]="userFlag.person" (mouseleave)="t.close()">
          </app-tooltip-person-card>
        </ng-template>
        <a [routerLink]="['/team',userFlag['person']['login']]" [ngbTooltip]="toolTipContent"
          tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
          (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)" class="d-inline-block">
          <img [src]="userFlag['person'].photo_url">
        </a>
      </ng-container>
      <ng-template #inactiveIcon>
        <span>
          <img [src]="userFlag['person'].photo_url">
        </span>
      </ng-template>
    </span>
  </div>
  <div class="col-md-11 pl-20">
    <a [routerLink]="['/team',userFlag['person']['login']]"
      *ngIf="userFlag.person && userFlag.person.login; else inactive;">{{getPersonName(userFlag['person'])}}</a>
    <ng-template #inactive>
      <span>
        {{getPersonName(userFlag['person'])}}
      </span>
    </ng-template>
    <span class="date ml-10 mr-5">{{userFlag.created_at | date:'medium'}}</span>

    <p class="mt-5">{{userFlag.comment}}</p>

    <div class="report-action">
      <a class="circle-actions circle-actions-bg" href="javascript:;" i18n-ngbTooltip ngbTooltip="Edit" container="body"
        (click)="edit = !edit"><i class="fas fa-pencil-alt"></i></a>
      <a class="circle-actions circle-actions-bg" (click)="deleteFlag($event)" href="javascript:;" i18n-ngbTooltip
        ngbTooltip="Delete" container="body">
        <i class="fas fa-trash-alt"></i> </a>
      <a class="circle-actions circle-actions-bg" (click)="createResolveForm(userFlag.id)" href="javascript:;"
        i18n-ngbTooltip ngbTooltip="Resolve" container="body" *ngIf="resolveFlagId!==userFlag.id && data.editAccess"> <i
          class="fas fa-check"></i> </a>
    </div>
  </div>
  <div class="col-md-12 mt-10" *ngIf="resolveFlagId===userFlag.id">
    <div class="report-nugget" *ngIf="resolveForm">
      <form [formGroup]="resolveForm" (ngSubmit)="onResolve()">
        <div>
          <textarea formControlName="comment" rows="3" maxlength="250"></textarea>
        </div>
        <button class="btn btn-primary btn-sm mt-10" type="submit" [disabled]="resolveForm.invalid || resolveSubmitted"
          [ngClass]="{'btn-disabled':resolveForm.invalid,'submitted':resolveSubmitted}" i18n>Submit</button>
        <button class="btn btn-link btn-sm mt-10" type="button" (click)="cancelResolve()" i18n>Cancel</button>
      </form>
    </div>
  </div>
</div>

<ng-container *ngIf="reports.length">
  <h4 class="mb-10 font-weight-bold mt-20" i18n>Below users have marked this {{data.type | lowercase}} as inappropriate
  </h4>
  <ng-container *ngFor="let report of reports">
    <div class="row members-row no-gutters">
      <div class="col-md-1">
        <span class="avatar avatar-sm">
          <ng-container *ngIf="report.person && report.person.login; else inactiveIcon;">
            <ng-template #toolTipContent>
              <app-tooltip-person-card [data]="report.person" (mouseleave)="t.close()">
              </app-tooltip-person-card>
            </ng-template>
            <a [routerLink]="['/team',report['person']['login']]" [ngbTooltip]="toolTipContent"
              tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
              (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)" class="d-inline-block">
              <img [src]="report['person'].photo_url">
            </a>
          </ng-container>
          <ng-template #inactiveIcon>
            <span>
              <img [src]="report['person'].photo_url">
            </span>
          </ng-template>
        </span>
      </div>
      <div class="col-md-11 pl-20">
        <a [routerLink]="['/team',report['person']['login']]"
          *ngIf="report.person && report.person.login; else inactive;">{{getPersonName(report['person'])}}</a>
        <ng-template #inactive>
          <span>
            {{getPersonName(report['person'])}}
          </span>
        </ng-template>
        <span class="date ml-10 mr-5">{{report.created_at | date:'medium'}}</span>

        <p class="mt-5">{{report.comment}}</p>

        <div class="report-action" *ngIf="resolveFlagId !== report.id && data.editAccess">
          <a class="circle-actions circle-actions-bg" (click)="createResolveForm(report.id)" href="javascript:;"
            i18n-ngbTooltip ngbTooltip="Resolve" container="body"> <i class="fas fa-check"></i> </a>
        </div>
      </div>
      <div class="col-md-12 mt-10" *ngIf="resolveFlagId===report.id">
        <div class="report-nugget" *ngIf="resolveForm">
          <form [formGroup]="resolveForm" (ngSubmit)="onResolve()">
            <div>
              <textarea formControlName="comment" rows="3" maxlength="250"></textarea>
            </div>
            <button class="btn btn-primary btn-sm mt-10" type="submit"
              [disabled]="resolveForm.invalid || resolveSubmitted"
              [ngClass]="{'btn-disabled':resolveForm.invalid,'submitted':resolveSubmitted}" i18n>Submit</button>
            <button class="btn btn-link btn-sm mt-10" type="button" (click)="cancelResolve()" i18n>Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<p class="text-center mt-10" *ngIf="loading"><i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i></p>
