<label for="Location">{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 [data]="locations" [options]="options" [value]="values" (valueChanged)="valueChanged($event)"
  [id]="'location-options'" class="custom-select-field location-options" width="100%"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}"></ng-select2>
<div class="search-tag-wrapper row" *ngIf="multiple">
  <span class="search-tag" *ngFor="let location of selectedLocations">{{location.text}}<a
      (click)="remove(location.id)">X</a></span>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    {{placeholder}}
  </div>
</div>
