import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsService } from 'app/services/documents/documents.service';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-submit-document',
  templateUrl: './submit-document.component.html',
  styleUrls: ['./submit-document.component.css']
})
export class SubmitDocumentComponent implements OnInit {
  @Input() data;
  form: UntypedFormGroup;
  submitted = false;
  invalidForm = false;
  constructor(private documentService: DocumentsService, private fb: UntypedFormBuilder, private formValidator: FormValidatorService,
    private toastrService: ToastrService, private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      title: this.fb.control(this.data.document.name, [this.formValidator.whiteSpaceValidation, Validators.maxLength(255)])
    });
  }

  onSubmit() {
    this.invalidForm = true;
    if (!this.submitted) {
      if (this.form.invalid) {
        return false;
      }
      this.submitted = true;
      this.invalidForm = false;
      const values = { document: this.data.document, title: this.form.get('title').value };

      if (this.data.id) {
        // update api call
        this.documentService.updateDocument(this.data.id, values)
          .subscribe(response => {
            if (response['message']) {
              this.toastrService.success(response['message']);
            }
            this.documentService.documentEvent.emit({ action: 'update' });
            this.activeModal.close();
          }, error => {
            this.invalidForm = true;
            this.submitted = false;
          });
      } else {
        this.documentService.submitDocument(values)
          .subscribe(response => {
            if (response['message']) {
              this.toastrService.success(response['message']);
            }
            this.documentService.documentEvent.emit({ action: 'create' });
            this.activeModal.close();
          }, error => {
            this.invalidForm = true;
            this.submitted = false;
          });
      }
    }
  }
}
