import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import * as lodash from 'lodash';

import { LoginService } from 'app/services/auth/login.service';
import { ConferenceActivityService } from 'app/services/conference-activities/conference-activity.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CloudPickerService } from "../../../services/cloud-picker/cloud-picker.service";

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {
  @Input() data;
  isAccess = false;
  showPreviewIcon = false;

  constructor(private conferenceActivityService: ConferenceActivityService, private modalService: ModalPopupService, private cloudPickerService: CloudPickerService, private loginService: LoginService) { }

  ngOnInit() {
    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })
  }

  getMembers() {
    let members = this.data.members;
    members = this.data.creator ? [this.data.creator].concat(members) : members;
    return lodash.uniqBy(members, 'person_id');
  }

  open() {
    const data = { title: this.data.title, message: '', component: 'EventInfoComponent', data: this.data, size: 'lg' };
    this.modalService.open(data);
  }

  canEditDelete() {
    this.isAccess = this.conferenceActivityService.canEditDelete(this.data);
    return this.isAccess;
  }

  deleteEvent(id: number, title: string) {
    const data = {
      title: $localize`Delete Event`, message: $localize`Are you sure you want to delete ${title} event?`, size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.conferenceActivityService.deleteEventConfirmEvent.emit();
          this.conferenceActivityService.deleteEvent(id)
            .subscribe(() => {
              const headers = new HttpHeaders({
                'Cache-Control': 'no-cache'
              });
              this.conferenceActivityService.deleteEventEvent.emit(headers);
            });
        }
      })
      .catch(() => { });
  }

  // openPreviewModal(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const previewData = { practice: this.data.practices[0], entityId: this.data.id, entityCategory: "event", previewUrl: this.data.preview_url};
  //   const data = { title: "Change Preview", message: '', component: 'CloudPickerComponent', data: previewData, size: 'lg' };
  //   this.modalService.open(data);
  // }

}
