import { Component, OnInit, Input } from '@angular/core';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-supported-language',
  templateUrl: './supported-language.component.html',
  styleUrls: ['./supported-language.component.css']
})
export class SupportedLanguageComponent implements OnInit {
  @Input() languages;
  @Input() data;
  @Input() displayLength = 200;
  // languagesList = [];
  displayNumber = 0;
  moreLanguages = 0;

  constructor(private modalService: ModalPopupService) { }

  ngOnInit() {
    if (this.data) {
      this.languages = this.data;
      this.displayLength = 100000;
    }

    // if (this.languages.length === undefined) {
    //   this.languagesList.push(this.languages);
    // } else {
    //   this.languagesList = this.languages;
    // }

    // get no of languages display
    let languagesName = '';
    for (const language of this.languages) {
      languagesName += language + ' ';
      if (languagesName.length > (this.displayLength - 6)) {
        break;
      }
      languagesName += ' ';
      this.displayNumber++;
    }
    // check is only one language left and it can be display
    if (this.languages.length === (this.displayNumber + 1)) {
      if (languagesName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }

    this.moreLanguages = this.languages.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Supported Languages`, message: '', component: 'SupportedLanguageComponent', data: this.languages };
    this.modalService.open(data);
  }
}
