import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {

  pendingHTTPRequests$ = new Subject<void>();

  constructor(router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url.indexOf('/team/') > -1) {
          return false;
        }
        // Cancel pending calls
        this.pendingHTTPRequests$.next();
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let clonedRequest;
    if ($localize.locale) {
      clonedRequest = request.clone({ headers: request.headers.append('accept-language', $localize.locale) });
      // Pass the cloned request instead of the original request to the next handle
      next.handle(clonedRequest);
    } else {
      clonedRequest = request.clone()
    }

    const hosts = environment.uploadHosts;
    if (hosts.some(host => clonedRequest.url.includes(host))) {
      return next.handle(clonedRequest).pipe(takeUntil(this.pendingHTTPRequests$.asObservable()));
    }
    if (clonedRequest.method === 'GET') {
      return next.handle(clonedRequest).pipe(takeUntil(this.pendingHTTPRequests$.asObservable()));
    }
    return next.handle(clonedRequest);
  }
}
