import { Component, OnInit, ViewChild, ElementRef, DoCheck } from '@angular/core';

import { LoginService } from 'app/services/auth/login.service';
import { UserAccessService } from 'app/services/auth/user-access.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router, NavigationStart } from '@angular/router';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';
import { FilterService } from 'app/services/filter/filter.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SearchService } from 'app/services/search/search.service';
import { environment } from 'environments/environment';
import { interval, of } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { DriveService } from 'app/services/drive/drive.service';
import { IntrojsService } from 'app/services/introjs/introjs.service';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, DoCheck {
  @ViewChild('searchField') searchField: ElementRef;

  env = environment;
  adminAccess = false;
  isExternalUser = false;
  sysadminAccess = false;
  userName = '';
  userIcon = 'https://storage.googleapis.com/gl-practice-library/images/avatar.jpg';
  practices = [];
  // vPractices = [];
  hPractices = [];
  collections = [];

  locations = [$localize`Argentina`, $localize`India`, $localize`Ukraine`, $localize`United States`, $localize`Poland`, $localize`Slovakia`,
  $localize`Croatia`, $localize`Canada`, $localize`Chile`, $localize`Israel`, $localize`Sweden`, $localize`United Kingdom`];

  adminLinks = [
    // { name: $localize`Practices Task Tracker`, link: `admin/task-tracker`, access: false },
    { name: $localize`Practice Awards`, link: `admin/awards`, access: false, awardAccess: true },
    // { name: $localize `Practice Newsletter Preview`, link: `admin/show-practice-newsletter-preview`, access: false },
    { name: $localize`Nuggets Contributions`, link: `admin/contributions`, access: false },
    { name: $localize`Digest Introductions`, link: `admin/digest-introductions`, access: false },
    { name: $localize`Practices Mailing Lists`, link: `public/practice-mailing-lists`, access: true },
    { name: $localize`Skill Repository`, link: `public/skill-repository`, access: true },
    { name: $localize`Practice Newsletter Archive`, link: `newsletters`, access: true },
    { name: $localize`Portal Releases`, link: `release-notes`, access: true },
    { name: $localize`Tags`, link: `tags`, access: true },
    { name: $localize`Delayed Innovations`, link: `admin/delayed-pocs`, access: false, sysadminAccess: true },
    // { name: $localize'Forum', link: 'ask-questions', access: false },
  ];

  sysadminLinks = [
    { name: $localize`Manage Portal Feedback`, link: `sysadmin/manage-portal-feedback`, access: false, sysadminOnly: false },
    { name: $localize`Award Submission Timeline`, link: `sysadmin/award-submission-timeline`, access: false, sysadminOnly: true },
    // { name: $localize`Search History`, link: `sysadmin/search-history`, access: false, sysadminOnly: false },
    {
      name: $localize`Google Analytics`, link: `https://analytics.google.com/analytics/web/#embed/report-home/a98813971w145224762p149938950`,
      external: true, access: false, sysadminOnly: false
    },
    { name: $localize`Check Nuggets URL`, link: `sysadmin/check-nuggets-url`, access: false, sysadminOnly: true },
    { name: $localize`Sync Documents`, link: `sysadmin/sync-documents`, access: false, sysadminOnly: true },
  ];

  scrollbarYOptions = {
    axis: 'y', theme: 'minimal-light', mouseWheel: { preventDefault: true }
  };

  focused = false;
  searchTerm = '';
  people = [];
  nuggetList = [];
  trending = [];
  searchStart = 0;
  searchEnd = 0;
  peopleCount = 0;
  nuggetCount = 0;
  showSubmitNugget = false;
  showUserMenu = false;
  showAdminMenu = false;
  showSysadminMenu = false;
  adminMenuLinks = [];
  // adminMenuAccess = false;
  showMobileSearch = false;
  showMobileMenu = false;
  showMobileMenuPractice = false;
  showMobileMenuIndustry = false;
  showMobileMenuNugget = false;
  showMobileMenuInnovation = false;
  showMobileMenuTool = false;
  showMobileMenuSalesTool = false;
  showMobileMenuInitiative = false;
  showMobileMenuGeo = false;
  showMobileMenuDA = false;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  showHaveQuery = false;
  showHaveQueryData = false;
  queryType = '';
  mailingInfo;
  introRun = false;
  activeNugget = false;
  activePractice = false;
  activeIndustry = false;

  constructor(public loginService: LoginService, private userAccess: UserAccessService, private practicesService: PracticesService,
    private router: Router, public commonService: CommonMethodsService, private peopleService: PeopleService,
    public searchService: SearchService, private filterService: FilterService, private jwtHelper: JwtHelperService,
    private driveService: DriveService, private introjsService: IntrojsService,
    private collectionServices: CollectionsService) { }

  ngDoCheck(): void {
    if (!this.introRun) {
      this.introjsService.siteIntro();
      this.introRun = true;
    }
  }

  activeParent(url) {
    this.activeNugget = ['case-studies', 'pocs', 'tech-papers', 'tech-talks', 'blogs']
      .filter(n => url.includes(n)).length > 0;
    this.activePractice = url.includes('digital-delivery');
  }

  ngOnInit() {
    this.activeParent(this.router.url)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.hideUserMenu();
        this.hideSubmenu();
        this.hideHaveQuery();
        this.showMobileMenu = false;
        this.showUserMenu = false;
        this.activeParent(event.url)
      }
    });

    const secondsCounter = interval(1000).subscribe(() => {
      if (!this.jwtHelper.isTokenExpired(localStorage.getItem('accessToken'))) {
        this.practicesService.getPractices()
          .subscribe(practices => {
            this.practices = practices['rows']; // load master practices
            const hPractices = this.practices.filter(item => item.type.c_name === "horizontal");
            const parent = hPractices.filter(item => !item.parent_id)
            const child = hPractices.filter(item => item.parent_id)
            parent.forEach((practice) => {
              practice['childPractice'] = child.filter(item => item.parent_id === practice.id);
              practice['showChildMenu'] = false;
            })
            this.hPractices = parent.reduce((acc, curr) => {
              if (!curr.category?.c_name) {
                return acc;
              }

              const category = acc.find((c) => c.category?.c_name === curr.category?.c_name);
              if (category) {
                category['practices'].push(curr);
              } else {
                acc.push({ 'category': curr.category, practices: [curr] });
              }
              return acc;
            }, []);
            // const vPractices = this.practices.filter(item => item.type.c_name === "vertical");
            // const vParent = vPractices.filter(item => !item.parent_id)
            // const vChild = vPractices.filter(item => item.parent_id)
            // vParent.forEach((practice) => {
            //   practice['childPractice'] = vChild.filter(item => item.parent_id === practice.id);
            //   practice['showChildMenu'] = false;
            // })
            // this.vPractices = vParent;

            this.userAccess.checkUserAccess()
              .subscribe(response => {
                this.adminAccess = response['adminAccess'];
                this.sysadminAccess = response['sysadminAccess'];
                // this.createMenu();
              });
          });

        if (this.loginService.isExternalUser()) {
          this.isExternalUser = true;
        }

        this.getRecentSearchHistory();
        this.getTrending();
        this.getCollections();
        secondsCounter.unsubscribe();
      }
    });

    const person = this.loginService.currentUser.person;
    if (person.name) {
      this.userName = this.peopleService.getPersonName(person);
    } else {
      this.userName = this.loginService.currentUser.user.name;
    }
    this.createSearchForm();
    setTimeout(() => {
      this.getMailingInfo();
    }, 1000)

    this.practicesService.practiceEvent
      .subscribe(value => {
        setTimeout(() => {
          this.practicesService.getPractices()
            .subscribe(practices => {
              this.practices = practices['rows'];
            }, console.error)
        })
      }, 0);

    this.collectionServices.collectionEvent.subscribe({
      next: (data) => {
        if (data['action'] != 'removeItem') {
          this.getCollections();
        }
      }
    });

  }

  shoWChildMenu(event, type, i = -1, value = true, category = '') {
    event.preventDefault();
    event.stopPropagation();

    if (type === 'practice') {
      this.hPractices = this.hPractices.map(c => {
        const cat = { category: c.category, practices: c.practices?.map(p => ({ ...p, showChildMenu: false })) };
        if (c.category.c_name !== category) {
          return cat;
        } if (i > -1 && cat['practices'][i])
          cat['practices'][i]['showChildMenu'] = !value;
        return cat;
      })
    }
    // else if (type === 'indistry') {
    //   this.vPractices = this.vPractices.map(p => ({ ...p, showChildMenu: false }));
    //   if (i > -1)
    //     this.vPractices[i]['showChildMenu'] = !value;
    // }
  }

  hideMobileSearch() {
    this.showMobileSearch = false;
  }

  toggleElement(event, type) {
    event.preventDefault();
    event.stopPropagation();
    switch (type) {
      case 'search':
        this.hideSubmenu();
        this.hideUserMenu();
        this.hideHaveQuery();
        this.showMobileSearch = !this.showMobileSearch;
        this.showMobileMenu = false;
        break;
      case 'menu':
        this.hideSubmenu();
        this.hideUserMenu();
        this.hideHaveQuery();
        this.showMobileMenu = !this.showMobileMenu;
        this.hideMobileSearch();
        break;
      case 'user-menu':
        this.hideSubmenu();
        this.hideHaveQuery();
        this.showAdminMenu = false;
        this.showSysadminMenu = false;
        this.hideMobileSearch();
        this.showMobileMenu = false;
        this.showUserMenu = !this.showUserMenu;
        break;
      case 'p-menu':
        this.hideHaveQuery();
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuPractice = !this.showMobileMenuPractice;
        break;
      case 'i-menu':
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = !this.showMobileMenuIndustry;
        break;
      case 'n-menu':
        this.hideHaveQuery();
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuNugget = !this.showMobileMenuNugget;
        break;
      case 't-menu':
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuTool = !this.showMobileMenuTool;
        this.showMobileMenuSalesTool = false;
        break;
      case 'st-menu':
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuSalesTool = !this.showMobileMenuSalesTool;
        break;
      case 'g-menu':
        this.hideHaveQuery();
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuGeo = !this.showMobileMenuGeo;
        break;
      case 'da-menu':
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuNugget = false;
        this.showMobileMenuInnovation = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuDA = !this.showMobileMenuDA;
        break;
      case 'in-menu':
        this.showMobileMenuPractice = false;
        this.showMobileMenuIndustry = false;
        this.showMobileMenuNugget = false;
        this.showMobileMenuDA = false;
        this.showMobileMenuSalesTool = false;
        this.showMobileMenuTool = false;
        this.showMobileMenuGeo = false;
        this.showMobileMenuInitiative = false;
        this.showMobileMenuInnovation = !this.showMobileMenuInnovation;
    }
  }

  hideSubmenu() {
    this.showMobileMenuPractice = false;
    this.showMobileMenuIndustry = false;
    this.showMobileMenuNugget = false;
    this.showMobileMenuTool = false;
    this.showMobileMenuInitiative = false;
    this.showMobileMenuGeo = false;
  }

  hideHaveQuery() {
    this.showHaveQuery = false;
    this.showHaveQueryData = false;
    this.selectQuery();
  }

  selectQuery(type = '') {
    this.queryType = type;
    this.showHaveQuery = false;
    this.showHaveQueryData = !!type;
  }

  onClickOutside(e, type) {
    if (!e.target.closest('.submit-nugget') && type === 'nugget') {
      this.showSubmitNugget = false;
    } else if (!e.target.closest('.logged-user-action') && type === 'user') {
      this.showUserMenu = false;
    } else if (!e.target.closest('.practice-query') && !e.target.closest('.query-dd') && type === 'query') {
      this.selectQuery();
    }
  }

  hideUserMenu() {
    this.showUserMenu = false;
    this.showAdminMenu = false;
    this.showSysadminMenu = false;
  }

  expendAdminMenu(event, type) {
    event.preventDefault();
    const person = this.loginService.currentUser.person;

    if (type === 'admin') {
      this.showAdminMenu = true;
      this.showSysadminMenu = false;
      if (!(this.loginService.isPrivilegedUser() || this.loginService.isRegionalHead() || this.loginService.isAwardNominator())) {
        this.adminMenuLinks = this.adminLinks.filter(l => !l.awardAccess);
      } else {
        this.adminMenuLinks = this.adminLinks;
      }
      if (this.loginService.hasDigestAccess()) {
        // this.adminMenuLinks = this.adminLinks.filter(l => l.link !== 'admin/digest-introductions');
        this.adminMenuLinks.forEach(l => {
          if (l.link === 'admin/digest-introductions') {
            l.access = true;
          }
        });
      }

      if (this.loginService.isAwardNominator()) {
        this.adminMenuLinks.forEach(l => {
          if (l.link === 'admin/awards') {
            l.access = true;
          }
        });
      }
      // this.adminMenuAccess = this.adminAccess;
    } else {
      this.showSysadminMenu = true;
      this.showAdminMenu = false;
      if (this.loginService.isRegionalHead()) {
        this.adminMenuLinks = this.sysadminLinks.filter(l => !l.sysadminOnly);
      } else {
        this.adminMenuLinks = this.sysadminLinks;
      }
      if (!(this.loginService.isServiceAccount() || this.loginService.isSuperUser() || this.loginService.isGlobalHead() ||
        this.loginService.isDev())) {
        this.adminMenuLinks = this.adminMenuLinks.filter(l => l.link !== 'sysadmin/sync-documents');

      }
      // this.adminMenuAccess = this.sysadminAccess;
    }
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  getRecentSearchHistory() {
    const filters = [{ key: 'page', values: [1] }, { key: 'perPage', values: [5] }];
    const params = this.filterService.getApiParams(filters);
    this.searchService.getSearchHistory(params)
      .subscribe(response => {
        this.searchService.searchHistory = response['rows'];
      });
  }

  getTrending() {
    const filters = [{ key: 'page', values: [1] }, { key: 'perPage', values: [5] }];
    const params = this.filterService.getApiParams(filters);
    this.searchService.getTrending(params)
      .subscribe(response => {
        this.trending = response['rows'];
      });
  }

  getApiFilters() {
    const filters = [
      { key: 'page', values: [1] },
      { key: 'perPage', values: [5] },
      { key: 'queryType', values: ['search'] },
      { key: 'filters[search]', values: [encodeURIComponent(this.searchTerm)] },
    ];
    return this.filterService.getApiParams(filters);
  }

  getPeople() {
    const params = this.getApiFilters();
    return this.peopleService.searchPeople(params);
  }

  getNuggets() {
    let params = this.getApiFilters();
    params = params.append('filters[except_other_entities_pages]', true)
    return this.searchService.searchNuggets(params);
  }

  createSearchForm() {
    this.commonService.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null, Validators.required)
    });

    this.commonService.searchForm.get('search').valueChanges
      .pipe(debounceTime(500),
        switchMap(value => {
          this.peopleCount = 0;
          this.searchStart = new Date().getTime();
          this.searchTerm = (value || '').trim();
          if (this.searchTerm) {
            return this.getPeople();
          } else {
            return of({
              people: [],
              total_count: 0
            });
          }
        }))
      .subscribe(response => {
        this.people = response['people'];
        this.searchEnd = new Date().getTime();
        this.peopleCount = response['total_count'];
      }, error => {
        this.searchEnd = new Date().getTime();
        this.peopleCount = 0;
        this.people = [];
      });

    this.commonService.searchForm.get('search').valueChanges
      .pipe(debounceTime(500),
        switchMap(value => {
          this.nuggetCount = 0;
          this.searchTerm = (value || '').trim();
          if (this.searchTerm) {
            return this.getNuggets();
          } else {
            return of({
              nuggets: [],
              total_count: 0
            });
          }
        }))
      .subscribe(response => {
        this.nuggetCount = response['total_count'];
        this.searchEnd = new Date().getTime();

        this.nuggetList = response['nuggets']
          .map(this.searchService.mapSearchData)
      }, error => {
        this.nuggetCount = 0;
        this.searchEnd = new Date().getTime();
        this.nuggetList = [];
      });
  }

  onSearch() {
    this.hideMobileSearch()
    this.searchField.nativeElement.blur();
    this.router.navigate(['/search-results'], { queryParams: { psearchterm: this.commonService.searchForm.get('search').value } });
  }

  clearHistory() {
    this.searchService.deleteSearchHistory()
      .subscribe(response => {
        this.getRecentSearchHistory();
      });
  }

  saveSearchHistory() {
    const duration = (this.searchEnd - this.searchStart) / 1000;
    const data = { term: this.searchTerm, result_count: this.peopleCount + this.nuggetCount, duration: duration };

    this.searchService.saveSearchHistory(data)
      .subscribe(() => {
        const filters = [{ key: 'page', values: [1] }, { key: 'perPage', values: [5] }];
        const params = this.filterService.getApiParams(filters);
        this.searchService.getSearchHistory(params)
          .subscribe(response => {
            this.searchService.searchHistory = response['rows'];
          });
      });
  }

  createMenu() {
    $(function () {
      const $navMenuCont = $($('[data-toggle="slide-collapse"]').data('target'));

      function hideMenu() {
        $navMenuCont.animate({ 'width': 'hide' }, 350);
        $('body').removeClass('overflow-hidden');
      }

      $('[data-toggle="slide-collapse"]').on('click', function () {
        if ($('body').hasClass('overflow-hidden')) {
          $('body').removeClass('overflow-hidden');
        } else {
          window.scrollTo(0, 0);
          $('body').addClass('overflow-hidden');
        }

        $('.navbar-collapse .navbar-nav ul').slideUp('slow');
        $('.navbar-collapse .navbar-nav > li > a').removeClass('open');
        $navMenuCont.animate({ 'width': 'toggle' }, 350);
      });

      $('.nav-link').on('click', function () {
        hideMenu();
      });

      $(document).on('click', function (e) {
        if ($(e.target).closest('#navbarSupportedContent').length === 0 && ($(e.target).closest('.hamburger').length === 0
        )) {
          hideMenu();
        }
      });

      $(document).keyup(function (e) {
        if (e.key === 'Escape') {
          hideMenu();
        }
      });

      $('.icon').click(function () {
        if ($(this).parent().hasClass('open')) {
          $(this).parent().removeClass('open').next().slideUp('slow');
        } else {
          $('.navbar-collapse .navbar-nav ul').slideUp('slow');
          $('.navbar-collapse .navbar-nav > li > a').removeClass('open');
          $(this).parent().addClass('open').next().slideDown('slow');
        }
        return false;
      });

      $('.mobile-search').click(function () {
        $('.search-grid').slideToggle();
        $(this).toggleClass('ms-open');
      });
    });
  }

  submitAccess() {
    return this.loginService.submitAccess();
  }

  getMailingInfo() {
    this.practicesService.getMailingInfo()
      .subscribe(response => {
        this.mailingInfo = response;
      })
  }

  getCollections() {
    const params = {
      perPage: 0,
      include_entities: false
    }
    this.collectionServices.getCollections(params)
      .subscribe({
        next: (response) => {
          this.collections = response['collections'];
        }
      });
  }

  preventDefault(e) {
    e.preventDefault();

  }
}
