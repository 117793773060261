import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CuratedContentService {
  apiUrl = environment.apiUrl;
  deleteCuratedContentEvent = new EventEmitter();

  constructor(private http: HttpClient, private loginService: LoginService,) { }

  getCuratedContents(params) {
    return this.http.get(this.apiUrl + `/v1/curated-contents`, { params });
  }

  getCuratedContent(id, params?) {
    return this.http.get(this.apiUrl + `/v1/curated-contents/${id}`, { params });
  }

  submitCuratedContent(data) {
    return this.http.post(this.apiUrl + '/v1/curated-contents', data);
  }

  updateCuratedContent(id, data) {
    return this.http.put(this.apiUrl + `/v1/curated-contents/${id}`, data);
  }

  updateCuratedContentPosition(data) {
    return this.http.post(this.apiUrl + `/v1/curated-contents/position`, data);
  }

  deleteCuratedContent(id) {
    return this.http.request('delete', this.apiUrl + `/v1/curated-contents/${id}`,
      { body: { email_id: this.loginService.currentUser.user.email } });
  }
}

export interface CuratedContents {
  curated_contents: [];
  total_count: number;
}
