import { Component, OnInit, Input } from '@angular/core';
import * as lodash from 'lodash';
import { PeopleService } from 'app/services/people/people.service';

@Component({
  selector: 'app-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.css']
})
export class EventInfoComponent implements OnInit {
  @Input() data = {};
  event;

  constructor(public peopleService: PeopleService) { }

  ngOnInit() {
    this.event = this.data;
  }

  getMembers() {
    let members = this.event.members;
    members = this.event.creator ? [this.event.creator].concat(members) : members;
    return lodash.uniqBy(members, 'person_id');
  }

}
