import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';

@Component({
  selector: 'app-tooltip-person-card',
  templateUrl: './tooltip-person-card.component.html',
  styleUrls: ['./tooltip-person-card.component.css']
})
export class TooltipPersonCardComponent implements OnInit {
  @Input() data;
  personName = '';

  constructor(private peopleService: PeopleService) { }

  ngOnInit() {
    if (!this.data.contact) {
      this.data.contact = { location: {}, email_id: '' };
      this.data.contact.location = this.data.location;
      this.data.contact.email_id = this.data.email_id;
    }
    this.personName = this.peopleService.getPersonName(this.data);
  }

  getPersonTitle(person) {
    return this.peopleService.getPersonTitle(person);
  }
}
