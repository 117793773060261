<div>
  <ng-template #toolTipContent>
    <app-tooltip-person-card [data]="headInfo" (mouseleave)="t.close()">
    </app-tooltip-person-card>
  </ng-template>
  <a [routerLink]="['/team',headInfo.login]" class="avatar avatar-60" [ngbTooltip]="toolTipContent"
    tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
    (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)"><img [src]="headInfo.photo_url"></a>
  <div class="p-details">
    <div class="mute">{{headInfo.label}}</div>
    <div><a [routerLink]="['/team',headInfo.login]">{{peopleService.getPersonName(headInfo)}}</a></div>
    <div>
      <a [href]="headInfo.glo_url" target="_blank" class="circle-actions mt-5" *ngIf="headInfo.glo_url"><i
          class="icon-c-glo"></i></a>
      <a [href]="headInfo.linkedin" target="_blank" class="circle-actions mt-5" *ngIf="headInfo.linkedin"><i
          class="fab fa-linkedin-in"></i></a>
    </div>
  </div>
</div>
