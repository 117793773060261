import { Component, Input } from '@angular/core';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-tool-accelerator-card',
  templateUrl: './tool-accelerator-card.component.html',
  styleUrls: ['./tool-accelerator-card.component.css']
})
export class ToolAcceleratorCardComponent {
  @Input() data;
  showPreviewIcon = false;

  constructor( private collectionsService: CollectionsService ) { }

  removeTool(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'tools', action: 'removeItem' });
  }

}
