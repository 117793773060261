<label *ngIf="label">{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 width="100%" [options]="options" (valueChanged)="valueChanged($event)"
  class="custom-select-field {{controlName}}-options" [id]="controlName"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}" [data]="data" [value]="values"
  [disabled]="disabled">
</ng-select2>
<div class="search-tag-wrapper row" [ngClass]="{'scroll-container': scroll}">
  <div class="authors" *ngFor="let person of selectedPeople">
    <div class="avatar avatar-sm">
      <img [src]="person.photo_url">
    </div>
    <div class="mt-8">
      <a [routerLink]="['/team', person.login]" *ngIf="person.id!=0;else noLogin">{{person.text}}</a>
      <ng-template #noLogin>
        <a class="no-link">{{person.text}}</a>
      </ng-template>
    </div>
    <div class="date mt-5">{{person.title}}</div>
    <div class="authors-action">
      <a (click)="remove(person.id)" class="circle-actions circle-actions-sm"><i class="fas fa-times"></i></a>
    </div>
  </div>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    {{placeholder}}
  </div>
  <div *ngIf="form.get(controlName).errors.maxlength">
    {{label}} should be less than {{form.get(controlName).errors.requiredLength}}
  </div>
</div>