import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { DriveService } from 'app/services/drive/drive.service';
import { finalize } from 'rxjs';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { LoginService } from 'app/services/auth/login.service';
import { Router } from '@angular/router';
import { CloudPickerService } from "../../../../services/cloud-picker/cloud-picker.service";
import { lastValueFrom } from 'rxjs';
import { HttpEventType } from '@angular/common/http';

declare var gapi: any;
declare var google: any;

@Component({
  selector: 'app-google-picker',
  templateUrl: './google-picker.component.html',
  styleUrls: ['./google-picker.component.css']
})
export class GooglePickerComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  // @Input() value;
  @Input() controlName = '';
  @Input() submitted = false;
  @Input() label = '';
  @Input() fileTypes = [];
  @Input() mimeTypes = '';
  // @Input() folderId = environment.tempFolderId;
  @Input() optional = false;
  @Input() helpText = '';
  @Input() multiple = false;
  @Input() fileTypesHint = true;
  // @Input() allowSharedDrive = false;
  @Input() parentUrl = '';
  @Input() nuggetType;
  @Input() onlySelect: boolean = false;
  @Input() extension;
  @Input() readFile = false;

  @Output() onFileUpload = new EventEmitter<any>();
  @Output() touch = new EventEmitter<any>();

  dialogDimensions = { width: 816, height: 504 };
  pickerApiLoaded = false;
  invalidFile = false;
  touched = false;

  id = '';
  url = '';
  fileName = '';
  artifacts = [];
  oauthToken;
  errorMessage = "";

  token = "";

  @ViewChild('forClickGooglePicker', { static: true }) forClick: ElementRef;

  constructor(public driveService: DriveService, private changeDetectorRef: ChangeDetectorRef,
    public commonService: CommonMethodsService, private loginService: LoginService, private router: Router,
    private cloudPickerService: CloudPickerService) { }

  ngOnInit() {
    // if (this.value && (this.value['url'] && this.value['name'] || this.value[0] && this.value[0]['url'] && this.value[0]['name'])) {
    //   if (this.multiple) {
    //     this.artifacts = this.value.map(file => {
    //       const url = file['url'];
    //       const id = this.commonService.getDriveFileIdFromUrl(url);
    //       const fileName = file['name'];
    //       const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
    //       return { id: id, url: url, fileName: fileName, fileType: fileType, mimeType: file['mime_type'] || '' };
    //     });

    //     this.form.get(this.controlName).setValue(this.artifacts);
    //   } else {
    //     const url = this.value['url'];
    //     const id = this.commonService.getDriveFileIdFromUrl(url);
    //     const fileName = this.value['name'];
    //     const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
    //     this.artifacts.push({ id: id, url: url, fileName: fileName, fileType: fileType, mimeType: this.value['mime_type'] || '' });
    //     this.form.get(this.controlName).setValue({
    //       id: id, url: url, fileName: fileName, fileType: fileType,
    //       mimeType: this.value['mime_type'] || ''
    //     });
    //   }
    // }
    // this.getToken();
  }

  // getToken() {
  //   // refresh token in 30 min
  //   this.driveService.pickerSubscription = timer(0, 1800000).pipe(
  //     switchMap(() => this.driveService.getAuthToken())
  //   ).subscribe(response => {
  //     this.driveService.pickerToken = response['token'];
  //   }, () => {
  //     if (this.parentUrl !== 'my-documents') {
  //       // this.router.navigate([this.parentUrl])
  //     }
  //     // this.loginService.logout();
  //   });
  // }

  // removeFile(id) {
  //   if (id) {
  //     const data = { user_access_token: this.oauthToken };
  //     this.driveService.removeFile(id, data)
  //       .subscribe();
  //   }
  // }

  get driveAccess() {
    return true || this.loginService.driveAccess;
  }

  openPicker() {
    // gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
    gapi.load('client:picker', this.onAuthApiLoad.bind(this));
  }

  async onAuthApiLoad() {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    const scope = 'https://www.googleapis.com/auth/drive';
    google.accounts.oauth2.initTokenClient(
      {
        'client_id': environment.googleClientId,
        'scope': scope,
        callback: this.handleAuthResult.bind(this)
      }).requestAccessToken();
  }

  handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
    }
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      this.errorMessage = undefined;
      const view = new google.picker.DocsView(google.picker.ViewId.DOCS);
      view.setMimeTypes(this.mimeTypes);
      // if (!this.allowSharedDrive) {
      //   view.setOwnedByMe(true);
      // }
      // if (this.onlySelect) {
      const origin = window.location.protocol + '//' + window.location.host;
      new google.picker.PickerBuilder()
        .addView(view)
        .hideTitleBar()
        .setOAuthToken(this.oauthToken)
        .setDeveloperKey(environment.googleDeveloperKey)
        .setCallback(this.pickerCallback.bind(this))
        .setOrigin(origin)
        .setSize(this.dialogDimensions.width - 2, this.dialogDimensions.height - 2)
        .build()
        .setVisible(true);
      // } else {
      //   new google.picker.PickerBuilder()
      //     .addView(new google.picker.DocsUploadView().setParent(environment.tempFolderId))
      //     .addView(view)
      //     .hideTitleBar()
      //     .setOAuthToken(this.oauthToken)
      //     .setDeveloperKey(environment.googleDeveloperKey)
      //     .setCallback(this.pickerCallback.bind(this))
      //     .setOrigin(window.location.protocol + '//' + window.location.host)
      //     .setSize(this.dialogDimensions.width - 2, this.dialogDimensions.height - 2)
      //     .build()
      //     .setVisible(true);
      // }
    }
  }

  pickerCallback(data) {
    const action = data[google.picker.Response.ACTION];
    if (action === google.picker.Action.PICKED) {
      const doc = data[google.picker.Response.DOCUMENTS][0];
      const id = doc[google.picker.Document.ID];
      const fileName = doc[google.picker.Document.NAME];
      const fileSize = doc[google.picker.Document.Oba];
      const mimeType = doc['mimeType'];
      const fileData = {
        user_access_token: this.oauthToken,
        // service_account_access_token: this.driveService.pickerToken,
        file_id: id,
        file_name: fileName,
        // original_folder_id: this.folderId,
        mime_type: mimeType,
        file_size: fileSize
      };

      if (this.readFile) {
        this.onFileUpload.emit(fileData)
        this.commonService.fileLoaderEvent.emit(true);
        // this.changeDetectorRef.detectChanges();
        return false
      }
      // if (doc.isNew) {//remove if block after move to storage
      //   if (this.mimeTypes.length) {
      //     this.invalidFile = !this.mimeTypes.includes(mimeType);
      //   }
      //   if (this.invalidFile) {
      //     this.changeDetectorRef.detectChanges();
      //     this.form.get(this.controlName).setValue(null);
      //     this.removeFile(id);
      //     this.artifacts = [];
      //   } else {
      //     this.commonService.loaderEvent.emit(true);
      //     this.driveService.upload(fileData)
      //       .pipe(
      //         finalize(() => this.touched = true)
      //       ).subscribe(response => {
      //         response['file']['url'] = response['file']['url'].replace('?usp=drivesdk', '');
      //         this.setFileValue(response['file']);
      //       }, error => this.setFileValue(null));
      //   }
      // } else {
      this.commonService.fileLoaderEvent.emit(true);
      this.setFileValue(fileData);
      this.changeDetectorRef.detectChanges();
      // }
    } else if (action === google.picker.Action.CANCEL) {
      this.touched = true;
      this.touch.emit(true);
      this.changeDetectorRef.detectChanges();
    }
  }

  async updateAcl(objectSelfLink, key) {
    try {
      const metaData = await lastValueFrom(this.cloudPickerService.getAclData(objectSelfLink, this.token));
      if (key[0] == "nugget") {
        const newAcls = environment.aclDomains.map(domainName => ({
          entity: `domain-${domainName}`,
          role: 'READER'
        }))
        metaData['acl'].push(...newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      } else if (key[0] == "preview") {
        const newAcls = {
          entity: 'allUsers',
          role: 'READER'
        }
        metaData['acl'].push(newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      }
    } catch (error) {
      console.error(error)
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async getToken() {
    try {
      const res = await lastValueFrom(this.cloudPickerService.getToken());
      return res['token']
    } catch (error) {
      console.error(error)
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async uploadGCS(fileName, prefix, file, fileKey) {
    let newFileName = fileName.replaceAll(/[/|;:+"]/gu, "-")
    const filePath = prefix + newFileName;
    const key = fileKey.split("-");
    this.token = await this.getToken();
    this.cloudPickerService.uploadFile(filePath, file, this.token).subscribe(async res => {
      if (res.type === HttpEventType.Response) {
        await this.updateAcl(res.body['selfLink'], key);
        const filterFileDetails = this.cloudPickerService.filterFileDetails(res.body, fileKey, file.name);
        const fileData = {
          kind: 'storage#file',
          id: '',
          name: filterFileDetails['name'].split("/")[filterFileDetails['name'].split("/").length - 1],
          // name: filterFileDetails['original_name'],
          url: filterFileDetails['authenticated_url'],
          extension: '',
          mime_type: filterFileDetails['mime_type']
        }
        this.form.get(this.controlName).setValue(fileData);
        this.forClick.nativeElement.click();
        this.commonService.fileLoaderEvent.emit(false);
      }
      if (res.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * res.loaded / res.total);
        this.cloudPickerService.uploadProgress = percentDone
        this.forClick.nativeElement.click();
      }
    }, error => {
      this.cloudPickerService.uploadProgress = 0;
      this.commonService.fileLoaderEvent.emit(false);
    })
  }

  getGoogleMimeType(fileMimeType) {
    if (fileMimeType == "application/vnd.google-apps.document") {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    }
    if (fileMimeType == "application/vnd.google-apps.spreadsheet") {
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }
    if (fileMimeType == "application/vnd.google-apps.presentation") {
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    }
  }

  getFileExtension(fileMimeType) {
    if (fileMimeType == "application/vnd.google-apps.document") {
      return "docx"
    }
    if (fileMimeType == "application/vnd.google-apps.spreadsheet") {
      return "xlsx"
    }
    if (fileMimeType == "application/vnd.google-apps.presentation") {
      return "pptx"
    }
  }

  onUploadError() {
    this.cloudPickerService.uploadProgress = 0;
    this.commonService.fileLoaderEvent.emit(false);
    this.cloudPickerService.upload = false;
  }

  async setFileValue(file) {
    if (this.multiple) {
      this.artifacts.push(file);
      this.form.get(this.controlName).setValue(this.artifacts);
    } else {

      const maxFileSize = 1024 * 1024 * 1024 // 1024MB
      if (file.file_size > maxFileSize) {
        this.errorMessage = 'File too large';
        this.commonService.fileLoaderEvent.emit(false);
        return;
      }
      this.cloudPickerService.upload = true;
      this.cloudPickerService.uploadProgress = 0;

      const timeStamp = Date.now()

      const fileKey = this.cloudPickerService.getFileKey(file.mime_type, this.nuggetType)
      const prefix = this.cloudPickerService.getPrefix(fileKey);

      const splittedFileName = file.file_name.split(".");
      let extension;
      let fileName;
      let blob;

      try {
        const documentMimeTypes = `application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet,application/vnd.google-apps.presentation`
        if (documentMimeTypes.includes(file.mime_type)) {
          blob = await lastValueFrom(this.cloudPickerService.downloadDriveGoogleDocFile(file.file_id, this.getGoogleMimeType(file.mime_type), this.oauthToken))
          extension = this.getFileExtension(file.mime_type)
          fileName = `${splittedFileName.join(".")}-${timeStamp}.${extension}`
        } else {
          blob = await lastValueFrom(this.cloudPickerService.downloadDriveFile(file.file_id, this.oauthToken))
          extension = splittedFileName.pop()
          fileName = `${splittedFileName.join(".")}-${timeStamp}.${extension}`
        }
        await this.uploadGCS(fileName, prefix, blob, fileKey)
      } catch (error) {
        // this.errorMessage = "File not found";
        if (error.error) {
          const jsonError = JSON.parse(await error.error.text())
          if (jsonError.error.code === 403) {
            this.errorMessage = 'You are not allowed to download, print and copy this file.'
          } else {
            this.errorMessage = jsonError.error.message;
          }
          this.onUploadError();
        } else {
          this.errorMessage = 'Unknown error. Please try again.'
        }
      }
    }
  }

  openPopup() {
    this.loginService.cookieAlertClosed = false;
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.document-tooltip');
  }

  ngOnDestroy() {
    // this.driveService.pickerSubscription.unsubscribe();
    this.onUploadError();
  }
}
