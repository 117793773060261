import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { AppService } from 'app/services/app/app.service';
import { CloudPickerService } from "../../../services/cloud-picker/cloud-picker.service";
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-deck-card',
  templateUrl: './deck-card.component.html',
  styleUrls: ['./deck-card.component.css']
})
export class DeckCardComponent implements OnInit {
  @Input() data;
  @Input() showSortIcons;
  @Input() first;
  @Input() last;
  isPrivileged = false;
  downloadUrl = '';
  showPreviewIcon = false;

  constructor(private practicesService: PracticesService, private loginService: LoginService, public commonService: CommonMethodsService,
    private modalService: ModalPopupService, private appService: AppService, private cloudPickerService: CloudPickerService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    if (this.loginService.isPrivilegedUser([this.data.practice?.id]) && !this.practicesService.showPublicView) {
      this.isPrivileged = true;
    } else {
      this.isPrivileged = false;
    }
    if (this.data.deck_file_url?.indexOf("storage.cloud.google.com") > -1) {
      this.downloadUrl = this.data.deck_file_url;
    } else {
      this.downloadUrl = this.commonService.getDownloadUrl(this.data['deck_file_url']);
    }
    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })
  }

  deleteDeck(event: Event, id: number, title: string) {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: $localize`Delete Deck`, message: $localize`Are you sure you want to delete ${title} deck?`, size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.practicesService.deleteDeck(id)
            .subscribe(response => {
              this.practicesService.deleteDeckEvent.emit();
              this.commonService.showLoader = false;
            }, () => this.commonService.showLoader = false);
        }
      })
      .catch(() => { });
  }

  updateOrder(event, direction) {
    event.preventDefault();
    this.commonService.itemSortEvent.emit({ type: 'deck', direction, id: this.data.id, position: +this.data.position });
  }

  saveLog(event) {
    event.preventDefault;
    this.cloudPickerService.download(this.downloadUrl);
    const data = { entity_id: this.data['id'], entity_category_id: 15, referer: window.location.href, action_type: 2 };
    this.appService.saveVisitorLog(data)
      .subscribe(r => { },
        e => { });
  }

  removeDeck(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'deck', action: 'removeItem' });
  }

  // open(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const previewData = { practice: this.data.practice, entityId: this.data.id, entityCategory: "deck", previewUrl: this.data.preview_url };
  //   const data = { title: "Change Preview", message: '', component: 'CloudPickerComponent', data: previewData, size: 'lg' };
  //   this.modalService.open(data);
  // }
}
