import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class AdvisoriesService {
  apiUrl = environment.apiUrl;
  termsAcceptedEvent = new EventEmitter();

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getAdvisories(params) {
    return this.http.get(this.apiUrl + '/v1/advisories', { params });
  }

  checkAccess(id) {
    return this.http.get(this.apiUrl + `/v1/advisories/${id}/check-access`,
      { params: { email_id: this.loginService.currentUser.user.email } });
  }

  requestAccess(advisory) {
    return this.http.request('post', this.apiUrl + `/v1/advisories/${advisory.advisory.id}/request-access`,
      { body: { email_id: this.loginService.currentUser.user.email, advisory: advisory.advisory, file: advisory.file } });
  }
}

export interface Advisories {
  advisories: [];
  total_count: number;
}
