import { Component, OnInit, Input, OnChanges, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { environment } from 'environments/environment';
import { TagService } from 'app/services/tags/tag.service';
// import { HttpHeaders } from '@angular/common/http';
import { FilterService } from 'app/services/filter/filter.service';
import { Options } from 'select2';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-tag-chips',
  templateUrl: './tag-chips.component.html',
  styleUrls: ['./tag-chips.component.css']
})
export class TagChipsComponent implements OnInit, OnChanges {
  @Input() tags = [];
  @Input() editAccess = false;
  @Input() type;
  @Input() entityId;
  @Input() blogId;

  entityCategoryId = 0;
  edit = false;
  form: UntypedFormGroup;
  data;
  options: Options;
  value: string[];
  current: string;
  apiUrl = environment.apiUrl;
  statusMsg = '';
  alertClass = '';
  dataOption = [];
  originalValues;

  constructor(private tagService: TagService, private filterService: FilterService, private commonService: CommonMethodsService, private modalService: ModalPopupService,) { }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean | Promise<boolean> {
    return (this.commonService.isEqual(this.originalValues, this.form?.value))
  }

  ngOnInit() {
  }

  ngOnChanges() {
    switch (this.type) {
      case 'blog': this.entityCategoryId = 5;
        break;
      case 'case-study': this.entityCategoryId = 6;
        break;
    }

    this.createForm();
  }

  createForm() {
    this.data = this.tags.map(tag => ({ id: tag.name, text: tag.name }));
    this.value = this.tags.map(tag => tag.name);
    this.options = {
      tags: true,
      multiple: true,
      placeholder: $localize`Specify tags`,
      language: { errorLoading: () => 'Searching...' },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/tags',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1,
          'filters[approved]': 1
        }),
        processResults: (data) => {
          this.dataOption = data.tags.map((tag) => ({
            id: tag.name,
            text: tag.name
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 30) < data.total_count
            }
          };
        }
      },
      createTag: (params) => {
        const term = $.trim(params.term.toLowerCase());

        if (term === '') {
          return null;
        }

        if (this.validateTag(term)) {
          return {
            id: term,
            text: term,
            newTag: true
          };
        }

        return null;
      }
    };
    this.form = new UntypedFormGroup({
      tags: new UntypedFormControl(this.value)
    });

    setTimeout(() => {
      this.originalValues = this.form.value;
    }, 2000);

    this.form.valueChanges
      .subscribe(value => {
        if (this.form.pristine) {
          this.originalValues = value;
        }
      });
  }

  validateTag(tag) {
    const tagRegex = /^[a-zA-Z0-9-+. ]*$/;
    return tagRegex.test(tag);
  }

  valueChanged(event) {
    this.form.markAsDirty();
    this.form.get('tags').setValue(event);
  }

  cancel() {
    if (!this.commonService.isEqual(this.originalValues, this.form.value)) {
      const data = {
        title: $localize`Leave page`, message: $localize`This page has unsaved changes, are you sure you want to leave this page?`,
        size: 'md', showFooter: true, btnOkText: $localize`Yes`, btnOkClass: 'btn-light', btnCancelText: 'No', btnCancelClass: 'btn-primary'
      };
      this.modalService.confirm(data)
        .then((confirmed) => {
          if (confirmed) {
            this.edit = false;
            this.createForm();
          }
        })
        .catch(() => { });
    } else {
      this.edit = false;
      this.createForm();
    }
  }

  onSubmit() {
    this.statusMsg = $localize`Updating Tags..`;
    this.alertClass = 'alert-info';
    this.tagService.updateTags(this.entityId, this.form.value.tags, this.entityCategoryId, this.blogId)
      .subscribe(response => {
        this.statusMsg = response['message'];
        this.alertClass = 'alert-success';
        this.tags = this.form.value.tags.map(t => ({ name: t }));
        this.createForm();
        this.edit = false;
        setTimeout(() => {
          this.statusMsg = '';
          this.alertClass = '';
        }, 5000);
      }, error => {
        this.alertClass = 'alert-error';
        this.statusMsg = error;
      });
  }
}
