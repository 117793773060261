import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { environment } from 'environments/environment';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { LoginService } from 'app/services/auth/login.service';
import { FilterService } from 'app/services/filter/filter.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-nugget-recommendation',
  templateUrl: './nugget-recommendation.component.html',
  styleUrls: ['./nugget-recommendation.component.css']
})
export class NuggetRecommendationComponent implements OnInit {
  @Input() nuggets = [];
  @Input() route;
  @Input() type;
  env = environment;
  label = 'Recommended';
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public commonService: CommonMethodsService, private loginService: LoginService, private filterService: FilterService) { }

  ngOnInit() {
    if (this.type === 'deck') {
      this.label = 'Overview & Offerings';
    }
  }

  getTitle(nugget) {
    if (this.type === 'case-study') {
      return `${nugget.project_name} - ${nugget.client_name}`;
    } else {
      return nugget.title;
    }
  }

  click() {
    this.filterService.deleteFilter();
  }
}
