import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CollectionsService } from 'app/services/collections/collections.service';
import { ToastrService } from "ngx-toastr";
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-add-in-collection',
  templateUrl: './add-in-collection.component.html',
  styleUrls: ['./add-in-collection.component.css']
})
export class AddInCollectionComponent implements OnInit {
  @Input() data;
  create = false;
  collectionName = new FormControl('', [this.formValidator.whiteSpaceValidation,
  Validators.maxLength(50)]);
  collections = [];
  submitted = false;
  invalidForm = false;

  constructor(private collectionServices: CollectionsService, private toster: ToastrService, private formValidator: FormValidatorService) { }

  ngOnInit(): void {
    this.getCollections();
  }

  getCollections() {
    const params = {
      perPage: 0,
      entity_id: this.data.entityId,
      entity_category_id: this.data.entityCategory
    }
    this.collectionServices.getCollections(params)
      .subscribe({
        next: (response) => {
          this.collections = response['collections'];
        }
      });
  }

  save() {
    this.invalidForm = true;
    if (this.submitted || this.collectionName.invalid)
      return false;

    this.submitted = true;
    const data = {
      name: this.collectionName.value,
      entity_id: this.data.entityId,
      entity_category_id: this.data.entityCategory
    };

    this.invalidForm = false;
    this.collectionServices.createCollection(data)
      .subscribe({
        next: (response) => {
          this.create = false;
          response['collection']['entities'] =
            [{
              entity_id: this.data.entityId,
              entity_category_id: this.data.entityCategory
            }]
          this.collections.push(response['collection']);
          orderBy(this.collections, 'name')
          this.toster.success(response['message'])
          this.submitted = false;
          this.collectionServices.collectionEvent.next({action: 'create'});
        },
        error: (error) => {
          this.toster.error(error['message'])
          this.submitted = false;
        }
      })
  }

  updateCollectionEntities(id) {
    const data = {
      entity_id: this.data.entityId,
      entity_category_id: this.data.entityCategory
    };
    this.collectionServices.updateCollectionEntities(id, data)
      .subscribe({
        next: (response) => {
          this.toster.success(response['message']);
        },
        error: (error) => {
          this.toster.error(error['message'])
        }
      })
  }
}
