import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.css']
})
export class MembersListComponent implements OnInit {
  @Input() data = [];

  constructor(public peopleService: PeopleService, private commonService: CommonMethodsService) { }

  ngOnInit() {
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }
}
