<label>{{label}}</label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 width="100%" [options]="options" (valueChanged)="valueChanged($event)"
  class="custom-select-field tag-options" [id]="label | removeSpace"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}" [data]="data" [value]="values">
</ng-select2>
<div class="search-tag-wrapper row" *ngIf="selectedTags.length">
  <span class="search-tag" *ngFor="let tag of selectedTags">{{tag.text}}<a (click)="remove(tag.id)">X</a></span>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required" i18n>Select {{label | lowercase}}</div>
</div>
