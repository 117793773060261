import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InitiativesService {
  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) { }

  getInitiatives(params) {
    return this.http.get(this.apiUrl + '/v1/initiatives', {params});
  }

  getInitiativeTypes(params?) {
    return this.http.get(this.apiUrl + '/v1/initiative-types', { params });
  }
}

export interface Initiative {
  initiatives: [];
  total_count: number;
}
