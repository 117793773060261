import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { PeopleService } from '../people/people.service';
import { uniq } from 'lodash';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

function mentionCustomization(editor: any) {
  // The upcast converter will convert <a class="ck-mention" href="" data-email="">
  // elements to the model 'mention' attribute.
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'ck-mention',
      attributes: {
        href: true,
        'data-email': true,
        'data-login': true,
      }
    },
    model: {
      key: 'mention',
      value: (viewItem: any) => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          email: viewItem.getAttribute('data-email'),
          login: viewItem.getAttribute('data-login'),
        });

        return mentionAttribute;
      }
    },
    converterPriority: 'high'
  });

  // Downcast the model 'mention' text attribute to a view <a> element.
  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue: any, option: any) => {
      if (!modelAttributeValue) {
        return;
      }
      return option.writer.createAttributeElement('a', {
        class: 'ck-mention',
        'data-email': modelAttributeValue.email,
        'href': `${window.location.origin}/team/${modelAttributeValue.login}`
      }, {
        priority: 20,
        id: modelAttributeValue.uid
      });
    },
    converterPriority: 'high'
  });
}

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  commentEvent = new EventEmitter();
  apiUrl = environment.apiUrl;
  siteUrl = environment.siteUrl;
  commentCount = 0;
  commentId = 0;
  editCommentId = 0;

  ckeConfig: any = {
    extraPlugins: [mentionCustomization],
    toolbar: {
      items: ['bold', 'italic', 'underline', '|', 'link']
    },
    mention: {
      feeds: [
        {
          marker: '@',
          minimumCharacters: 1,
          feed: (queryText: any) => {
            const headers = {
              Authorization: 'Bearer ' + localStorage.getItem('refreshToken')
            };

            return fetch(`${this.apiUrl}/v1/people/autocomplete?filters[search]=${queryText}`, { headers })
              .then((response) => response.json())
              .then((response: any) => {
                const data = response.people.map((p: any) => ({
                  email: p['contact']['email_id'],
                  id: '@' + this.peopleService.getPersonName(p),
                  login: p['login'],
                  photo: p['photo_url'],
                  text: this.peopleService.getPersonName(p)
                }))
                return data;
              });
          },
          itemRenderer: function (item: any) {
            const itemElement = document.createElement('div');
            itemElement.classList.add('avtar-list');
            itemElement.id = `mention-list-item-id-${item.email}`;
            itemElement.innerHTML = `<span class="avatar avatar-sm" > <img src="${item.photo}" > </span>
                 <div class="avtar-body avtar-body-member">
                   <span>${item.text} </span>
                   </div>`;
            return itemElement;
          }
        },
      ]
    }
  };
  constructor(private http: HttpClient, private peopleService: PeopleService, private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private documentRef: any, private route: ActivatedRoute) { }

  getComments(id, params) {
    return this.http.get(this.apiUrl + `/v1/nuggets/${id}/comments`, { params });
  }

  submitComment(id, data) {
    return this.http.post(this.apiUrl + `/v1/nuggets/${id}/comments`, data);
  }

  updateComment(id, cid, data) {
    return this.http.put(this.apiUrl + `/v1/nuggets/${id}/comments/${cid}`, data);
  }

  deleteComment(id, cid) {
    return this.http.delete(this.apiUrl + `/v1/nuggets/${id}/comments/${cid}`);
  }

  getTagedEmail(html) {
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const collection = temp.getElementsByTagName('a');
    let emails = [];
    for (const item in collection) {
      if (+item > -1) {
        emails.push(collection[item].getAttribute('data-email'));
      }
    }
    return emails = uniq(emails);
  }

  scrollToComment() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        this.commentId = +fragment.split('-')[1];
        setTimeout(() => {
          this.pageScrollService.scroll({
            document: this.documentRef,
            scrollTarget: '#' + fragment,
            scrollOffset: 140,
            duration: 200
          });
        }, 2000);
      }
    });
  }
}
