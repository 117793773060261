import { Component, OnInit, Input } from '@angular/core';
import { Options } from 'select2';
import { environment } from 'environments/environment';
import { TagService } from 'app/services/tags/tag.service';
import { LoginService } from 'app/services/auth/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tag-actions',
  templateUrl: './tag-actions.component.html',
  styleUrls: ['./tag-actions.component.css']
})

export class TagActionsComponent implements OnInit {

  @Input() data: { action: String, tag: {}, params: {} };

  currentUserEmail;

  action;
  currentTagId;
  currentTagName;
  currentTagEntityCount;
  currentTagApproved;

  message;
  helpMessage = "";

  apiUrl = environment.apiUrl;

  options: Options;
  tagOption = [];

  selectAlternateTag = true;
  alternateTagRequired = false;
  alternateTagId;

  hasError = false;
  errorMessage = "";

  buttonText;
  buttonDisabled = false;

  constructor(
    private tagService: TagService,
    public loginService: LoginService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.currentUserEmail = this.loginService.currentUser.user.email;

    const { action, tag, params } = this.data;

    this.action = action;
    this.currentTagId = tag["id"];
    this.currentTagName = tag["name"];
    this.currentTagEntityCount = tag["mappings_count"]["entity_count"];
    this.currentTagApproved = params["filters[approved]"]

    if (this.action == "delete") {
      this.buttonText = $localize`Delete Tag`
      if (this.currentTagEntityCount > 0) {
        if (this.currentTagApproved == 1) {
          this.alternateTagRequired = true;
        }
        this.message = $localize`Are you sure you want to delete tag ${this.currentTagName.toLowerCase()}? If yes then ${(this.currentTagApproved == 0 ? ' you can' : '')} provide the alternate tag against which existing mapped entities can be mapped`;
        this.setOptions(this.currentTagApproved);
      } else {
        this.selectAlternateTag = false;
        this.message = $localize`Are you sure you want to delete tag ${this.currentTagName.toLowerCase()}?`;
      }
    }

    if (this.action == "merge") {
      this.buttonText = $localize`Merge Tag`
      this.alternateTagRequired = true;
      this.message = $localize`Merge tag ${this.currentTagName.toLowerCase()} with below selected tag:`;
      this.helpMessage = $localize`*Note: After merge ${this.currentTagName.toLowerCase()} tag and all its references will be replaced by selected tag.`;
      this.setOptions(this.currentTagApproved);
    }

    if (this.action == "synonym") {
      this.buttonText = $localize`Add as Synonym`
      this.alternateTagRequired = true;
      this.message = $localize`Add tag ${this.currentTagName.toLowerCase()} as synonym of selected tag:`;
      this.setOptions(1, $localize`Select a Tag`);
    }

  }

  setOptions(approved, placeholder = $localize`Select Alternate Tag`) {
    this.options = {
      tags: false,
      multiple: false,
      placeholder,
      language: { errorLoading: () => $localize`Searching...` },
      minimumInputLength: 1,
      dropdownCssClass: 'modal-dropdown',
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/tags',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1,
          'filters[approved]': approved,
          'filters[exceptTagId]': this.currentTagId,
        }),
        processResults: (data) => {
          this.tagOption = data.tags.map((tag) => ({
            id: tag.id,
            text: tag.name
          }));
          return {
            results: this.tagOption,
            pagination: {
              more: (data.page * 30) < data.total_count
            }
          };
        }
      }
    };
  }

  valueChanged(event) {
    this.alternateTagId = this.tagOption.filter(tag => (tag.id == event))[0]['id'];
    this.hasError = false;
    this.errorMessage = "";
  }

  callService(id, alternateId, formData) {
    let serviceCall;
    if (this.action == "delete") {
      serviceCall = this.tagService.deleteTag(id, formData)
    }

    if (this.action == "merge") {
      serviceCall = this.tagService.mergeTag(id, alternateId, formData)
    }

    if (this.action == "synonym") {
      serviceCall = this.tagService.addAsSynonym(id, alternateId, formData)
    }

    serviceCall.subscribe(response => {
      if (response['message']) {
        this.toastrService.success(response['message']);
        this.tagService.tagActionEvent.emit();
      }
    }, error => {
      this.toastrService.error(error['message']);
      this.tagService.tagActionEvent.emit();
    })
  }

  submit() {
    this.buttonDisabled = true;

    if (this.action == "delete") {
      let formData;
      if (this.selectAlternateTag && this.alternateTagRequired) {
        if (this.alternateTagId > 0) {
          formData = { email: this.currentUserEmail, alternate_id: this.alternateTagId }
          this.callService(this.currentTagId, 0, formData);
        } else {
          this.hasError = true;
          this.errorMessage = $localize`Please select alternate tag`;
          this.buttonDisabled = false;
        }
      } else {
        formData = { email: this.currentUserEmail }
        if (this.alternateTagId > 0) {
          formData.alternate_id = this.alternateTagId;
        }
        this.callService(this.currentTagId, 0, formData);
      }
    }

    if (this.action == "merge") {
      let formData;
      if (this.selectAlternateTag && this.alternateTagRequired) {
        if (this.alternateTagId > 0) {
          formData = { email: this.currentUserEmail }
          this.callService(this.currentTagId, this.alternateTagId, formData);
        } else {
          this.hasError = true;
          this.errorMessage = $localize`Please select alternate tag`;
          this.buttonDisabled = false;
        }
      }
    }

    if (this.action == "synonym") {
      let formData;
      if (this.selectAlternateTag && this.alternateTagRequired) {
        if (this.alternateTagId > 0) {
          formData = { email: this.currentUserEmail }
          this.callService(this.currentTagId, this.alternateTagId, formData);
        } else {
          this.hasError = true;
          this.errorMessage = $localize`Please select a tag`;
          this.buttonDisabled = false;
        }
      }
    }
  }
}

