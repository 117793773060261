import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CollectionsService } from 'app/services/collections/collections.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-shared-person',
  templateUrl: './shared-person.component.html',
  styleUrls: ['./shared-person.component.css']
})
export class SharedPersonComponent implements OnInit {
  @Input() data = [];
  collection;
  people = [];
  form: UntypedFormGroup;
  httpError;
  persons = [];
  submitted = false;
  submitBtnText = $localize`Submit`;

  constructor(
    private fb: UntypedFormBuilder,
    private collectionsService: CollectionsService,
    private toastrService: ToastrService,
    private commonService: CommonMethodsService
  ) { }

  ngOnInit(): void {
    this.collection = this.data['collection'];
    this.people = this.data['people'];
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      people: this.fb.control([], []),
    });
  }

  onSubmit() {
    if (!this.submitted) {
      if (this.form.invalid) {
        return false;
      }
      this.saveData();
    }
  }

  saveData() {
    const values = {};
    Object.assign(values, this.form.value);
    this.httpError = undefined;
    this.submitted = true;
    this.collectionsService.shareCollection(this.collection['id'], values)
      .subscribe({
        next: response => {
          if (response['message']) {
            this.toastrService.success(response['message']);
            this.submitted = false;
            this.collectionsService.closeModelEvent.emit();
          }
        },
        error: error => {
          this.submitted = false;
          this.httpError = this.commonService.parseHttpErrors(error);
        }
      });
  }

  cancel() {
    this.collectionsService.closeModelEvent.emit();
  }

}
