import { Directive, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDisable]'
})
export class DisableDirective implements OnChanges {
  @Input('appDisable') disableControl;

  ngOnChanges(changes) {
    if (changes['disableControl']) {
      const action = this.disableControl ? 'disable' : 'enable';
      this.ngControl.control[action]();
    }
  }

  constructor(private ngControl: NgControl) {
  }
}
