<ng-container *ngIf="initiatives.length">
  <label for="{{htmlId}}">{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
  <div class="help" *ngIf="helpText">{{helpText}}</div>
  <ng-select2 [data]="initiatives" [options]="options" [value]="values" (valueChanged)="valueChanged($event)"
    id="{{htmlId}}" class="custom-select-field {{htmlId}}" width="100%"
    [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}"></ng-select2>
  <div class="search-tag-wrapper row">
    <span class="search-tag" *ngFor="let initiative of selectedInitiatives">{{initiative.text}}<a
        (click)="remove(initiative.id)">X</a></span>
  </div>
  <div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
    <div *ngIf="form.get(controlName).errors.required">
      {{placeholder}}
    </div>
  </div>
</ng-container>
