import { AfterViewInit, Component, ViewChild, ElementRef } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';
import { createPopper } from '@popperjs/core';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.css']
})
export class FilterDropdownComponent implements AfterViewInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  scrollbarOptions = { axis: 'y', theme: 'minimal-dark', scrollButtons: { enable: true } };
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('filterLabel') filterLabel: ElementRef;
  @ViewChild('filterOption') filterOption: ElementRef;
  popper;
  open = false;

  constructor() { }

  ngAfterViewInit(): void {
    // this.popper = createPopper(this.filterLabel.nativeElement, this.filterOption.nativeElement, {
    //   modifiers: [
    //     {
    //       name: 'offset',
    //       options: {
    //         offset: ({ placement }) => {
    //           if (placement === 'bottom') {
    //             return [0, -10];
    //           } else if (placement === 'top') {
    //             return [0, 2];
    //           } else {
    //             return []
    //           }
    //         },
    //       },
    //     },
    //   ]
    // })
  }

  toogle() {
    this.open = !this.open;
    // this.popper.update();
  }

  close() {
    this.open = false;
    // this.popper.update();
  }
}
