<label *ngIf="showLabel">{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText" [innerHTML]="helpText"></div>
<!-- <ng-container [formGroup]="form"> -->
<ckeditor [editor]="Editor" tagName="textarea" [config]="ckeConfig" [(ngModel)]="editorText" (change)="valueChange()"
  (blur)="onBlur()" (focus)="onFocus()" (ready)="onReady($event)">
</ckeditor>

<!-- <ck-editor [formControlName]="controlName" [config]="ckeConfig" skin="moono-lisa" [readonly]="readonly"
    (blur)="onBlur()" (focus)="onFocus()" (ready)="onReady($event)"> </ck-editor> -->
<div *ngIf="form.get(controlName).invalid && (form.get(controlName).touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required || form.get(controlName).errors.whitespace" i18n>Enter
    {{label | lowercase}}
  </div>
  <div *ngIf="form.get(controlName).errors.minlength" i18n>{{label}}(html source) should be more
    than {{form.get(controlName).errors.minlength.requiredLength}} characters</div>
  <div *ngIf="form.get(controlName).errors.maxlength" i18n>{{label}}(html source) should be less
    than {{form.get(controlName).errors.maxlength.requiredLength}} characters</div>
</div>
<div class="invalid" *ngIf="spellCheckService.errorData && spellCheckService.errorData[controlName]">
  {{spellCheckService.errorData[controlName]['error']}}
</div>
<!-- </ng-container> -->