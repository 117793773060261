<a href="javascript:;" class="reply mr-30" (click)="createReplyCommentForm()" *ngIf="!commentReplyForm && isPerson"><i
    class="fas fa-reply"></i>&nbsp;&nbsp; <ng-container i18n>Reply</ng-container></a>
<ng-container *ngIf="replies.length">
  <a href="javascript:;" class="reply mr-30" *ngIf="!hideReplies;else show" (click)="hideReplies=true">
    <i class="fas fa-chevron-up"></i>&nbsp;&nbsp; <ng-container i18n>Hide reply</ng-container>
  </a>
  <ng-template #show>
    <a href="javascript:;" class="reply mr-30" (click)="hideReplies=false">
      <i class="fas fa-chevron-down"></i>&nbsp;&nbsp; <ng-container i18n>Show reply</ng-container>
    </a>
  </ng-template>
</ng-container>

<ng-container *ngIf="currenrUser.login === comment.person.login">
  <a href="javascript:;" class="reply mr-30" (click)="editCommentForm(comment.id)"
    *ngIf="commentService.editCommentId !== comment.id">
    <i class="fas fa-pencil-alt"></i>&nbsp;&nbsp; <ng-container i18n>Edit</ng-container>
  </a>
</ng-container>

<ng-container *ngIf="currenrUser.login === comment.person.login || supperUser">
  <a href="javascript:;" class="reply mr-30" (click)="deleteComment(comment.id)">
    <i class="fas fa-trash-alt"></i>&nbsp;&nbsp; <ng-container i18n>Delete</ng-container>
  </a>
</ng-container>

<div class="reply-form" id="reply-form-{{comment.id}}" *ngIf="commentReplyForm">
  <div class="comment-box overflow-hidden">
    <form [formGroup]="commentReplyForm" (ngSubmit)="submitReplyComment()">
      <ng-template #inactiveIcon>
        <span class="avatar avatar-sm">
          <img src="https://storage.googleapis.com/gl-practice-library/images/avatar.jpg">
        </span>
      </ng-template>
      <ng-container *ngIf="currenrUser.login; else inactiveIcon;">
        <ng-template #CUTT>
          <app-tooltip-person-card [data]="currenrUser" (mouseleave)="t.close()" *ngIf="currenrUser.login">
          </app-tooltip-person-card>
        </ng-template>
        <a [routerLink]="['/team',currenrUser.login]" class="avatar avatar-sm" [ngbTooltip]="CUTT"
          tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
          (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)">
          <img [src]="currenrUser.photo_url">
        </a>
      </ng-container>

      <div class="form-group"
        [ngClass]="{'validate':commentReplyForm.get('comment').invalid && (commentReplyForm.get('comment').touched)}">
        <app-text-editor class="comment-editor" [form]="commentReplyForm" controlName="comment" i18n-label
          label="Comment" [showLabel]="false" [ckeConfig]="ckeConfig" [autoFocus]="true">
        </app-text-editor>

        <div class="mt-10 text-right">
          <button class="btn btn-primary" type="submit" [disabled]="commentReplyForm.invalid || submitted"
            [ngClass]="{'btn-disabled':commentReplyForm.invalid || submitted}" i18n>Reply</button>
          <span class="btn btn-light ml-5" (click)="cancel()" i18n>Cancel</span>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-container *ngIf="replies.length">
  <div class="comment-row pad-0" *ngFor="let reply of replies" [hidden]="hideReplies"
    id="comment-{{reply.original_id}}">
    <ng-container *ngIf="reply.person && reply.person.login; else inactiveIcon;">
      <ng-template #CPTT>
        <app-tooltip-person-card [data]="reply.person" (mouseleave)="t1.close()"></app-tooltip-person-card>
      </ng-template>
      <a [routerLink]="['/team',reply.person.login]" class="avatar avatar-sm" [ngbTooltip]="CPTT"
        tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t1="ngbTooltip"
        (mouseenter)="t1.open()" (mouseleave)="onMouseleave($event,t1)">
        <img [src]="reply.person.photo_url">
      </a>
    </ng-container>

    <div class="other-comment other-comment-reply">
      <div [ngClass]="{'comment-bg':commentService.editCommentId !== reply.id}">
        <div class="cmt-user-name">
          <a [routerLink]="['/team',reply.person.login]" *ngIf="reply.person && reply.person.login; else inactive;">
            {{getPersonName(reply.person)}}
          </a>
          <ng-template #inactive>
            <span>
              {{getPersonName(reply.person)}}
            </span>
          </ng-template>

          <span class="date ml-10  mr-5">{{reply.original_created_at | date:'medium'}} </span>
          <ng-container *ngIf="reply.is_edited">
            - <span class="status ml-5" *ngIf="reply.is_edited"><ng-container i18n>Edited</ng-container>
              ({{reply.created_at | date:'medium'}})</span>
          </ng-container>
        </div>

        <ng-container *ngIf="commentService.editCommentId !==reply.id; else edit;">
          <div [innerHTML]="reply.comment"></div>
        </ng-container>
      </div>
      <ng-container *ngIf="currenrUser.login===reply.person.login">
        <a href="javascript:;" class="reply mr-30" (click)="editCommentForm(reply.id, 'reply')"
          *ngIf="commentService.editCommentId !== reply.id">
          <i class="fas fa-pencil-alt"></i>&nbsp;&nbsp;<ng-container i18n>Edit</ng-container></a>
      </ng-container>

      <ng-container *ngIf="currenrUser.login === reply.person.login || supperUser">
        <a href="javascript:;" class="reply" (click)="deleteComment(reply.id, 'reply')">
          <i class="fas fa-trash-alt"></i>&nbsp;&nbsp;<ng-container i18n>Delete</ng-container></a>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #edit>
  <form [formGroup]="commentEditForm" (ngSubmit)="submitReplyComment('edit')" class="reply-edit-form">
    <div class="form-group"
      [ngClass]="{'validate':commentEditForm.get('comment').invalid && (commentEditForm.get('comment').touched)}">
      <app-text-editor class="comment-editor" [form]="commentEditForm" controlName="comment" i18n-label label="Comment"
        [showLabel]="false" [ckeConfig]="ckeConfig" [autoFocus]="true">
      </app-text-editor>
    </div>
    <div class="mt-10 text-right">
      <button class="btn btn-primary" type="submit" [disabled]="commentEditForm.invalid || submitted"
        [ngClass]="{'btn-disabled':commentEditForm.invalid || submitted}" i18n>Comment</button>
      <span class="btn btn-light ml-5" (click)="cancel('edit')" i18n>Cancel</span>
    </div>
  </form>
</ng-template>