import {
  Directive, ComponentFactoryResolver, Input, OnInit, ViewContainerRef
} from '@angular/core';
import { FilterDropdownComponent } from 'app/shared/components/fields/filter-dropdown/filter-dropdown.component';
import { FieldConfig } from 'app/interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { FilterActionComponent } from 'app/shared/components/filters/filter-action/filter-action.component';
import { DateComponent } from 'app/shared/components/fields/date/date.component';
import { InputComponent } from 'app/shared/components/fields/input/input.component';
import { FacetOptionsComponent } from 'app/shared/components/fields/facet-options/facet-options.component';
import { CustomerFilterComponent } from 'app/shared/components/fields/customer-filter/customer-filter.component';
import { LinksComponent } from 'app/shared/components/filters/links/links.component';
import { SearchPeopleComponent } from 'app/shared/components/fields/search-people/search-people.component';
import { TagFilterComponent } from 'app/shared/components/fields/tag-filter/tag-filter.component';
import { NestedFilterComponent } from 'app/shared/components/filters/nested-filter/nested-filter.component';

const componentMapper = {
  input: InputComponent,
  filterDropdown: FilterDropdownComponent,
  date: DateComponent,
  action: FilterActionComponent,
  facetOptions: FacetOptionsComponent,
  customerFilter: CustomerFilterComponent,
  filterLink: LinksComponent,
  searchPeople: SearchPeopleComponent,
  tagFilter: TagFilterComponent,
  nestedFilter: NestedFilterComponent,
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: UntypedFormGroup;

  componentRef: any;

  constructor(private resolver: ComponentFactoryResolver, private container: ViewContainerRef) { }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
