import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PracticesService } from 'app/services/practices/practices.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-secondary-practice-select',
  templateUrl: './secondary-practice-select.component.html',
  styleUrls: ['./secondary-practice-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SecondaryPracticeSelectComponent
    }
  ]
})
export class SecondaryPracticeSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() multiple = true;
  @Input() helpText = '';
  @Input() optional = false;
  @Input() filter = [];
  htmlId = 'secondary-practice';
  label = $localize`Secondary Practice`
  placeholder = $localize`Select secondary practice`;
  values = [];
  selectedPractices: any[] = [];
  secondaryPractices: any[] = [];
  options: any[] = [];
  disabled = false;
  touched = false;
  onChange: any = () => { }
  onTouched = () => { this.touched = true; }

  constructor(private practiceService: PracticesService,) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.options = this.secondaryPractices.filter(practice => !this.filter.includes(practice.id));
      this.selectedPractices = this.selectedPractices.filter(practice => !this.filter.includes(practice.id));
      this.onSelectionChange(this.selectedPractices);
    }
  }

  ngOnInit(): void {
    if (this.multiple) {
      this.htmlId += $localize`s`;
      this.label += $localize`s`;
      this.placeholder += $localize`s`;
    }
    this.practiceService.allOptionsSubject.subscribe(options => {
      this.secondaryPractices = options;
      this.options = this.secondaryPractices.filter(practice => !this.filter.includes(practice.id));
      setTimeout(() => {
        this.setSelectedPractices()
      }, 500);
    });
  }

  setSelectedPractices() {
    this.selectedPractices = this.options.filter(option => this.values.some(value => value === option.id));
    this.onSelectionChange(this.selectedPractices);
  }

  onSelectionChange(values: any[]) {
    values = values.map(v => v.id);
    this.onChange(values);
  }

  compareWith(item, selected) {
    if (selected.id && item.id) {
      return item.id === selected.id;
    }
    return false;
  }

  remove(id: number) {
    this.selectedPractices = this.selectedPractices.filter(p => p.id !== id);
    this.onSelectionChange(this.selectedPractices);
  }

  writeValue(values: any): void {
    this.values = values;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
