import { Component, OnInit, Input } from '@angular/core';
import { ModalPopupService } from '../../../services/util/modal-popup.service';
import { CommonMethodsService } from '../../../services/util/common-methods.service';

@Component({
  selector: 'app-other-skills',
  templateUrl: './other-skills.component.html',
  styleUrls: ['./other-skills.component.css']
})
export class OtherSkillsComponent implements OnInit {

  moreSkills = 0;
  skills = [];
  @Input() data;
  @Input() type = '';
  @Input() displayNumber = 20;
  constructor(private modalService: ModalPopupService, private commonMethods: CommonMethodsService) { }

  ngOnInit() {
    if (this.type === 'model') {
      this.displayNumber = 200;
    }
    this.skills = this.data;
    this.displayNumber = this.commonMethods.getDisplayCount(this.skills.length, this.displayNumber);
    this.moreSkills = this.skills.length - this.displayNumber;
  }

  open() {
    const data = { title: $localize`Other Skills`, message: '', component: 'OtherSkillsComponent', data: this.skills };
    this.modalService.open(data);
  }

}
