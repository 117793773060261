import { Component, OnInit, Input } from '@angular/core';

import { PeopleService } from 'app/services/people/people.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CloudPickerService } from "../../../services/cloud-picker/cloud-picker.service";
import { LoginService } from 'app/services/auth/login.service';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-nugget-card',
  templateUrl: './nugget-card.component.html',
  styleUrls: ['./nugget-card.component.css']
})
export class NuggetCardComponent implements OnInit {
  url: string;
  @Input() previewAccess: boolean = true;
  @Input() data: any;
  @Input() displayPractices = true;
  @Input() type: string;
  @Input() classes: string;
  types = '';
  isGloballogicEducation = false;
  displayNumber = 5;
  queryParams = {};
  adminAccess = false;
  isAccess = false;
  showPreviewIcon = false;
  entityCategoryid;

  constructor(private peopleService: PeopleService, private modalService: ModalPopupService, private cloudPickerService: CloudPickerService, private loginService: LoginService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    if (this.data['tags'] && this.data.search_type === 'tech_talk') {
      this.isGloballogicEducation = this.data['tags'].some(t => t.is_globallogic_education);
    }
    if (this.isGloballogicEducation) {
      this.displayNumber = 3;
    }
    if (['tech-thursdays', 'innovation-gl', 'writers-cafe'].includes(this.type)) {
      this.url = `/initiatives`;
      if (this.data.search_type === 'poc') {
        this.types = $localize`Innovation`;
        this.queryParams = { type: 'poc', initiative: this.type };
      } else if (this.data.search_type === 'tech_talk') {
        this.types = $localize`Tech Talk`;
        this.queryParams = { type: 'tech-talk', initiative: this.type };
      } else {
        this.types = $localize`Tech Document`;
        this.queryParams = { type: 'tech-document', initiative: this.type };
      }
    } else {
      if (this.type === 'poc') {
        this.url = `/pocs`;
      } else if (this.type === 'tech-talk') {
        this.url = `/tech-talks`;
      } else if (this.type === 'tech-doc') {
        this.url = `/tech-papers`;
        this.types = this.data.types?.map(t => t.name).join(', ');
      }
    }

    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId && this.data.search_type == response.entityCategory) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })

    let practices = [];
    if (this.data.practices && this.data.practices.length > 0) {
      practices = this.data.practices.map(practice => practice.id);
    }

    let locations = [];
    if (this.data.locations && this.data.locations.length > 0) {
      locations = this.data.locations.map(location => location.city)
    }

    if (this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices)) {
      this.adminAccess = true;
    } else {
      this.adminAccess = false;
    }

    this.data.authors = this.peopleService.getNuggetMembers(this.data);
    if ((this.data.authors.filter(author => author.contact.email_id === this.loginService.currentUser.user.email).length)
      || this.data.created_by === this.loginService.currentUser.user.email || this.adminAccess ||
      this.loginService.isPrivilegedUser()) {
      this.isAccess = true;
    } else {
      this.isAccess = false;
    }

    this.getEntityCategoryid();
  }

  getEntityCategoryid() {
    if (this.type === 'poc') {
      this.entityCategoryid = 4;
    } else if (this.type === 'tech-talk') {
      this.entityCategoryid = 2;
    } else if (this.type === 'tech-doc') {
      this.entityCategoryid = 3;
    }
  }

  removeNugget(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: this.type, action: 'removeItem' });
  }
  // getMembers() {
  //   return this.peopleService.getNuggetMembers(this.data);
  // }

}
