import { Component, OnInit } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.css']
})
export class TagFilterComponent implements OnInit {
  options;
  apiUrl = environment.apiUrl;
  dataOption = [];

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.options = {
      tags: false,
      multiple: true,
      placeholder: 'Tags',
      language: { errorLoading: () => $localize`Searching...` },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/tags',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1,
          'filters[approved]': 1
        }),
        processResults: (data) => {
          this.dataOption = data.tags.map((tag) => ({
            id: tag.id,
            text: tag.name
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 30) < data.total_count
            }
          };
        }
      }
    };
  }

  valueChanged(event) {
    let clients = this.dataOption.filter(o => event.includes(o.id.toString()))
      .map(tag => ({ id: tag.id, name: tag.text }));
    if (clients.length) {
      clients = clients.concat(this.filterService.form.get('tags').value || []);
      this.filterService.form.get('tags').setValue(clients);
      $('#tags-filter select').val(null).trigger('change');
    }
  }

}
