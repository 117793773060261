import { Component, OnInit, Input } from '@angular/core';

import { AdvisoriesService } from 'app/services/advisories/advisories.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PeopleService } from 'app/services/people/people.service';

@Component({
  selector: 'app-advisory-card',
  templateUrl: './advisory-card.component.html',
  styleUrls: ['./advisory-card.component.css']
})
export class AdvisoryCardComponent implements OnInit {
  advisory;
  @Input() data: any;

  constructor(private advisoryService: AdvisoriesService, private modalService: ModalPopupService,
    private commonService: CommonMethodsService, private peopleService: PeopleService) { }

  ngOnInit() {
  }

  getUserName(user) {
    return this.peopleService.getPersonName(user);
  }

  checkAccess(id: number) {
    this.commonService.showLoader = true;
    this.advisoryService.checkAccess(id)
      .subscribe(advisory => {
        this.commonService.showLoader = false;
        this.advisory = advisory;
        if (advisory['permission']) {
          window.open(advisory['advisory']['url'], '_blank');
        } else {
          this.openRequestAccessDialog();
        }
      });
  }

  openRequestAccessDialog() {
    const data = {
      title: 'Request Access', component: 'AdvisoryRequestAccessDialogComponent', size: 'md', showFooter: true, data: this.advisory,
      disableBtnOk: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.advisoryService.requestAccess(this.advisory)
            .subscribe((result) => {
              this.commonService.showLoader = false;
              const confirmData = {
                title: 'Request Access', message: result['message'], size: 'sm',
                showBtnCancel: false, showFooter: true
              };
              this.modalService.open(confirmData);
            });
        }
      })
      .catch(() => { });
  }
}
