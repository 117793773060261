<div class="form-group" id="demoGroup"
  [ngClass]="{'validate':(form.get(controlName).invalid && (touched || submitted))}">
  <label>{{label}}<span class="help" *ngIf="optional" i18n>(Optional)</span></label>
  <div class="help">{{helpText}}</div>

  <div class="custom-control-inline">
    <app-cloud-file-upload [form]="form" [controlName]="controlName" [nuggetType]="nuggetType"
      [asIndependent]="asIndependent" [fileTypes]="fileTypes" [mimeTypes]="mimeTypes" [extension]="extension"
      (touch)="touched=true">
    </app-cloud-file-upload>
  </div>
  <div class="custom-control-inline">
    <app-google-picker [form]="form" [controlName]="controlName" [nuggetType]="nuggetType" [fileTypes]="fileTypes"
      [mimeTypes]="mimeTypes" [submitted]="submitted" [onlySelect]="onlySelect" parentUrl="parentUrl"
      [extension]="extension" (touch)="touched=true">
    </app-google-picker>
  </div>
  <div *ngIf="!asIndependent">
    <div class="help help1 mt-5" i18n>Allowed file types: {{fileTypes.join(', ')}}. Max allowed file size is 1 GB.
    </div>
    <div class="file-url" *ngIf="fileDetails.url && fileDetails.name">
      <a [href]="fileDetails.url" target="_blank">{{fileDetails.name}}
        <span class="remove-file" (click)="remove($event,fileDetails)">X</span>
      </a>
    </div>
  </div>

  <div *ngIf="(form.get(controlName).invalid && (touched || submitted))" class="invalid">
    <div *ngIf="form.get(controlName).errors && form.get(controlName).errors.required" i18n>Select a file</div>
  </div>
</div>