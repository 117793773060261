import { NgModule } from '@angular/core';

import { ClassPipe } from 'app/pipes/class.pipe';
import { TruncatePipe } from 'app/pipes/truncate.pipe';
import { OrderByPipe } from 'app/pipes/order-by.pipe';
import { SafeHtmlPipe } from 'app/pipes/safe-html.pipe';
import { SafePipe } from 'app/pipes/safe.pipe';
import { RemoveSpacePipe } from 'app/pipes/remove-space.pipe';


@NgModule({
  declarations: [
    ClassPipe,
    TruncatePipe,
    OrderByPipe,
    SafeHtmlPipe,
    SafePipe,
    RemoveSpacePipe,
  ],
  exports: [
    ClassPipe,
    TruncatePipe,
    OrderByPipe,
    SafeHtmlPipe,
    SafePipe,
    RemoveSpacePipe,
  ]
})
export class PipeModule { }
