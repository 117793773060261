<div [perfectScrollbar]="config" [ngClass]="classes" class="comment-wrapper">
  <div class="comments" *ngIf="commentForm" id="comments">
    <div class="total-comment"><ng-container i18n>Comments</ng-container>
      <ng-container *ngIf="commentService.commentCount"> ({{commentService.commentCount}}) </ng-container>
    </div>
    <div class="comment-box overflow-hidden">
      <form [formGroup]="commentForm" (ngSubmit)="submitComment()">
        <ng-template #inactiveIcon>
          <span class="avatar avatar-sm">
            <img src="https://storage.googleapis.com/gl-practice-library/images/avatar.jpg">
          </span>
        </ng-template>
        <ng-container *ngIf="currenrUser.login; else inactiveIcon;">
          <ng-template #CUTT>
            <app-tooltip-person-card [data]="currenrUser" (mouseleave)="t.close()" *ngIf="currenrUser.login">
            </app-tooltip-person-card>
          </ng-template>
          <a [routerLink]="['/team',currenrUser.login]" class="avatar avatar-sm" [ngbTooltip]="CUTT"
            tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
            (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)">
            <img [src]="currenrUser.photo_url">
          </a>
        </ng-container>

        <div class="form-group"
          [ngClass]="{'validate':commentForm.get('comment').invalid && (commentForm.get('comment').touched)}">
          <app-text-editor class="comment-editor" [form]="commentForm" controlName="comment" i18n-label label="Comment"
            [showLabel]="false" [ckeConfig]="ckeConfig" (focus)="focused=true" (blur)="focused=false">
          </app-text-editor>

          <div class="mt-10 text-right" *ngIf="focused || commentForm.get('comment').value">
            <button class="btn btn-primary" type="submit" [disabled]="commentForm.invalid || submitted"
              [ngClass]="{'btn-disabled':commentForm.invalid || submitted}" i18n>Comment</button>
            <span class="btn btn-light ml-5" (click)="cancel()" i18n>Cancel</span>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="other-comments" *ngIf="comments?.length">
    <div class="comment-row" *ngFor="let comment of comments | slice: 0 : (showAll ? undefined : 1)"
      id="comment-{{comment.original_id}}">
      <ng-container *ngIf="comment.person && comment.person.login; else inactiveIcon;">
        <ng-template #CPTT>
          <app-tooltip-person-card [data]="comment.person" (mouseleave)="t1.close()"></app-tooltip-person-card>
        </ng-template>
        <a [routerLink]="['/team',comment.person.login]" class="avatar avatar-sm" [ngbTooltip]="CPTT"
          tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t1="ngbTooltip"
          (mouseenter)="t1.open()" (mouseleave)="onMouseleave($event,t1)">
          <img [src]="comment.person.photo_url">
        </a>
      </ng-container>

      <div class="other-comment">
        <div [ngClass]="{'comment-bg':commentService.editCommentId !== comment.id}">
          <div class="cmt-user-name">
            <a [routerLink]="['/team',comment.person.login]"
              *ngIf="comment.person && comment.person.login; else inactive;">
              {{getPersonName(comment.person)}}
            </a>
            <ng-template #inactive>
              <span>
                {{getPersonName(comment.person)}}
              </span>
            </ng-template>

            <span class="date ml-10 mr-5">{{comment.original_created_at | date:'medium'}}</span>
            <ng-container *ngIf="comment.is_edited">
              - <span class="status ml-5" *ngIf="comment.is_edited"> <ng-container i18n>Edited</ng-container>
                ({{comment.created_at | date:'medium'}})</span>
            </ng-container>
          </div>
          <ng-container *ngIf="commentService.editCommentId !==comment.id; else edit;">
            <div [innerHTML]="comment.comment"></div>
          </ng-container>
        </div>

        <app-comment-reply [comment]="comment" [replies]="comment.replies" [entityId]="entityId"
          [entityCategoryId]="entityCategoryId" (onreply)="onReply($event)"> </app-comment-reply>
      </div>
    </div>
  </div>

  <div class="more-comment" *ngIf="comments?.length > 1">
    <a href="javascript:;" (click)="showAll=true" *ngIf="!showAll;else wsLess;" i18n>Show more comments</a>
    <ng-template #wsLess>
      <a href="javascript:;" (click)="showAll=false" i18n>Show less comments</a>
    </ng-template>
  </div>
</div>

<ng-template #edit>
  <form [formGroup]="commentEditForm" (ngSubmit)="submitComment('edit')" id="edit-comment">
    <div class="form-group mb-0"
      [ngClass]="{'validate':commentEditForm.get('comment').invalid && (commentEditForm.get('comment').touched)}">
      <app-text-editor class="comment-editor" [form]="commentEditForm" controlName="comment" i18n-label label="Comment"
        [showLabel]="false" [ckeConfig]="ckeConfig" [autoFocus]="true">
      </app-text-editor>
      <div class="mt-10 text-right">
        <button class="btn btn-primary" type="submit" [disabled]="commentEditForm.invalid || submitted"
          [ngClass]="{'btn-disabled':commentEditForm.invalid || submitted}" i18n>Comment</button>
        <span class="btn btn-light ml-5" (click)="cancel('edit')" i18n>Cancel</span>
      </div>
    </div>
  </form>
</ng-template>