<div class="filter-vertical">
  <div class="filter-head">
    <ng-container i18n>Filters</ng-container>
    <span class="clear-filter-right" *ngIf="filterService.filters.length">
      <span class="anchor" (click)="filterService.form.reset()" i18n>Clear All</span>
    </span>
  </div>

  <div class="filters-body" *ngIf="filterService.filterConfig.length === filterService.pageFilters.length">
    <app-dynamic-form [fields]="filterService.filterConfig | orderBy:'order'"></app-dynamic-form>
  </div>
</div>