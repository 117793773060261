<div class="collection-modal">
  <div class="collections">
    <div class="scroll-container">
      <div class="form-check" *ngFor="let collection of collections">
        <input class="form-check-input" type="checkbox" [checked]="collection.entities?.length"
          (click)="updateCollectionEntities(collection.id)" id="collection-{{collection.id}}">
        <label class="form-check-label" for="collection-{{collection.id}}">
          {{collection.name}}
        </label>
      </div>
    </div>

    <a href="javascript:;" class="" (click)="create=true" *ngIf="!create;else createForm;">+ New Collection</a>

    <!-- Add New Collection -->
    <ng-template #createForm>
      <div class="row ">
        <div class="col-md-12">
          <div id="titleGroup" class="form-group mb-0"
            [ngClass]="{'validate':collectionName.invalid && (collectionName.touched||invalidForm)}">
            <label for="Title">Collection Name</label>
            <input type="text" placeholder="Enter collection name" [formControl]="collectionName" class="form-control">
            <div class="invalid" *ngIf="collectionName.invalid && (collectionName.touched||invalidForm)">
              <div *ngIf="collectionName.errors.whitespace" i18n>Enter collection name</div>
              <div *ngIf="collectionName.errors.maxlength" i18n>Collection name should be less than
                {{collectionName.errors.maxlength.requiredLength}} characters
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Add New Collection -->
    </ng-template>
  </div>

  <div class="modal-footer" *ngIf="create">
    <button type="button" class="btn btn-primary" (click)="save()">CREATE</button>
  </div>
</div>