<label>{{label}}<span class="help" *ngIf="optional">(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 width="100%" [options]="options" [data]="data" [value]="values" (valueChanged)="valueChanged($event)"
  class="custom-select-field" [id]="label | removeSpace"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}">
</ng-select2>
<div class="search-tag-wrapper row" *ngIf="multiple">
  <span class="search-tag" *ngFor="let client of selectedClients">{{client.text}}<a
      (click)="remove(client.id)">X</a></span>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    Select {{label | lowercase}}
  </div>
</div>
<div class="alert alert-info client-info-box mt-10" *ngIf="isSelected && !customerActivityService.clientId">
  <p class="mb-15">No such prospect found, do you want to add it as a new prospect?</p>
  <button type="button" class="btn btn-primary" (click)="onClick()">YES</button>
  <button type="button" class="btn btn-link" (click)="onCancel()">NO</button>
</div>