<a [routerLink]="['/team',data['login'] || data['person_id']]" class="user-box" [ngClass]="classes"
  (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false">
  <div class="person-top-actions">
    <app-create-collection [entityId]="data.person_id" [entityCategory]="7"
      *ngIf="showPreviewIcon && !data.readOnly"></app-create-collection>
    <a (click)="removePerson($event, data.person_id, '7')" class="circle-actions"
      *ngIf="data.removeCollection && showPreviewIcon" i18n-ngbTooltip i18n-ngbTooltip
      ngbTooltip="Remove from My Collection" container="body"><i class="fa-solid fa-xmark"></i></a>
  </div>
  <div class="user_avatar">
    <span class="avatar avatar-sm">
      <img [src]="data.photo_url" [title]="personName">
    </span>
  </div>
  <div class="user_details">
    <a [routerLink]="['/team',data['login'] || data['person_id']]" class="person-name">{{personName | titlecase}}</a>
    <div class="date mt-5">{{getPersonTitle()}} |
      <a class="type" [routerLink]="['/locations', data.contact.location.city | removeSpace]" appStopPropagation>
        {{data.contact.location.city}}
      </a>
    </div>
    <div class="date mt-5">
      <a class="type" href="http://mail.google.com/a/globallogic.com/mail?view=cm&to={{data.contact.email_id}}"
        target="_blank" appStopPropagation>{{data.contact.email_id| truncate:35}}</a>
    </div>
    <app-person-practices [person]="data" class="mt-10 d-block" *ngIf="!data.hidePractice"></app-person-practices>
  </div>
</a>