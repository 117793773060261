<div class="action-row">
  <ng-container *ngIf="isPerson">
    <a href="javascript:;" [ngClass]="{'liked':liked}" (click)="like()" *ngIf="showLike"><i
        class="fas fa-thumbs-up"></i>&nbsp;<ng-container i18n>Like</ng-container> </a>
    <a pageScroll [pageScrollDuration]="500" [pageScrollOffset]="140" href="#comments" *ngIf="showComment">
      <i class="fas fa-comment-alt"></i>&nbsp;<ng-container i18n>Comment</ng-container> </a>
  </ng-container>

  <div class="text-right" [ngClass]="{'float-right':(showLike || showComment) && isPerson}">
    <ng-container *ngIf="likeCount && showLike">
      <a href="javascript:;" (click)="open()" i18n> {{likeCount}} {likeCount, plural, >1 {Likes} other {Like}}</a> <i
        class="dot">•</i>
    </ng-container>
    <ng-container i18n>{{viewCount}} {viewCount, plural, >1 {Views} other {View}}</ng-container>
    &nbsp;
    <ng-container *ngIf="showUniqueView" i18n>({uniqueViewCount, plural, >1 {Unique Views} other {Unique View}}:
      {{uniqueViewCount}})
    </ng-container>
    <ng-container *ngIf="commentService.commentCount && showComment"><i class="dot">•</i>
      <ng-container i18n>{{commentService.commentCount}} {commentService.commentCount, plural, >1 {Comments} other
        {Comment} }</ng-container>
    </ng-container>
  </div>
</div>