import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CloudPickerService } from "../../../../services/cloud-picker/cloud-picker.service";
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { lastValueFrom } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-cloud-file-upload',
  templateUrl: './cloud-file-upload.component.html',
  styleUrls: ['./cloud-file-upload.component.css']
})
export class CloudFileUploadComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() nuggetType;
  @Input() fileTypes = [];
  @Input() mimeTypes = '';
  @Input() controlName = '';
  @Input() asIndependent = false;
  // @Input() value;
  @Input() extension;
  @Output() onFileUpload = new EventEmitter<any>();
  @Output() touch = new EventEmitter<any>();
  token = "";
  errorMessage = "";
  buttonText;

  chunkSize = 256 * 1024 * 40; // 256 * 1024 = 256 KB * 100 = 25.6 MB
  currentChunkIndex = 0;
  folderName = "";
  progress = 0;

  constructor(
    private cloudPickerService: CloudPickerService,
    private commonService: CommonMethodsService,
  ) { }

  ngOnInit(): void {
    if (this.asIndependent) {
      this.buttonText = $localize`Upload File`;
    } else {
      this.buttonText = $localize`Select File`;
    }
  }

  openPicker() {
    document.getElementById("uploader").click();
  }

  getFileExt(fileName) {
    var parts = fileName.split('.');
    var ext = parts[parts.length - 1].toLowerCase();
    return ext;
  }

  onBlur(event) {
    if (event.sourceCapabilities) {
      this.touch.emit(true);
    }
  }

  async getToken() {
    try {
      const res = await lastValueFrom(this.cloudPickerService.getToken());
      return res['token']
    } catch (error) {
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async updateAcl(objectSelfLink, key) {
    try {
      const metaData = await lastValueFrom(this.cloudPickerService.getAclData(objectSelfLink, this.token));
      if (key[0] == "nugget") {
        const newAcls = environment.aclDomains.map(domainName => ({
          entity: `domain-${domainName}`,
          role: 'READER'
        }))
        metaData['acl'].push(...newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      } else if (key[0] == "preview") {
        const newAcls = {
          entity: 'allUsers',
          role: 'READER'
        }
        metaData['acl'].push(newAcls);
        await lastValueFrom(this.cloudPickerService.updateAcl(objectSelfLink, metaData, this.token));
      }
    } catch (error) {
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async uploadGCS(fileName, prefix, file, fileKey) {
    let newFileName = fileName.replaceAll(/[/|;:+"]/gu, "-")
    const filePath = prefix + newFileName;
    const key = fileKey.split("-");
    this.cloudPickerService.uploadFile(filePath, file, this.token).subscribe(async res => {
      if (res.type === HttpEventType.Response) {
        await this.updateAcl(res.body['selfLink'], key);
        const filterFileDetails = this.cloudPickerService.filterFileDetails(res.body, fileKey, file.name);
        const fileData = {
          kind: 'storage#file',
          id: '',
          name: filterFileDetails['original_name'],
          url: filterFileDetails['authenticated_url'],
          extension: '',
          mime_type: filterFileDetails['mime_type']
        }
        this.form.get(this.controlName).setValue(fileData);
        this.commonService.showFileLoader = false;
      }
      if (res.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * res.loaded / res.total);
        this.cloudPickerService.uploadProgress = percentDone
      }
    }, error => {
      this.cloudPickerService.uploadProgress = 0;
      this.commonService.fileLoaderEvent.emit(false);
    })
  }

  async getFiles(prefix) {
    try {
      let getFilesRes = await lastValueFrom(this.cloudPickerService.getFiles(prefix, this.token))
      let files = getFilesRes["items"];
      files = files.sort((a, b) => {
        if (a.updated < b.updated) {
          return 1
        }
        if (a.updated > b.updated) {
          return -1
        }
        return 0
      })
      return files;
    } catch (error) {
      this.errorMessage = "File not uploaded";
      this.onUploadError();
    }
  }

  async uploadFile(file, fileKey) {
    const prefix = this.cloudPickerService.getPrefix(fileKey)
    const key = fileKey.split("-")
    let fileName;
    if (key[0] == "preview") {
      const files = await this.getFiles(prefix)
      var parts = file.name.split(".")
      var ext = parts[parts.length - 1]
      fileName = files.length + 1 + "." + ext
    } else if (key[0] == "nugget") {
      fileName = file.name
      const timeStamp = Date.now()
      const splittedFileName = fileName.split(".")
      if (splittedFileName.length > 1) {
        const extension = splittedFileName.pop()
        fileName = `${splittedFileName.join(".")}-${timeStamp}.${extension}`
      }
    }
    this.uploadGCS(fileName, prefix, file, fileKey)
  }

  async onFileChange(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      if (!this.mimeTypes.split(',').includes(file['type'])) {
        this.errorMessage = $localize`Invalid file type`;
        return;
      }
      const maxFileSize = 1024 * 1024 * 1024 // 1024MB
      if (file.size > maxFileSize) {
        this.errorMessage = $localize`File too large`;
        return;
      }
      this.cloudPickerService.upload = true;
      this.commonService.showFileLoader = true;
      this.errorMessage = ``;
      this.cloudPickerService.uploadProgress = 0;
      this.token = await this.getToken();
      const fileKey = this.cloudPickerService.getFileKey(file.type, this.nuggetType)
      await this.uploadFile(file, fileKey);
      // let formData = new FormData();
      // formData.append("file", file);
      // formData.append('file_key', this.cloudPickerService.getFileKey(file.type, this.nuggetType));
      // this.cloudPickerService.uploadFile(formData).subscribe((response) => {
      //   const fileData = {
      //     kind: 'storage#file',
      //     id: '',
      //     // name: response['name'].split("/")[response['name'].split("/").length - 1],
      //     name: response['original_name'],
      //     url: response['authenticated_url'],
      //     extension: '',
      //     mime_type: response['mime_type']
      //   }
      //   this.form.get(this.controlName).setValue(fileData);
      //   this.commonService.showLoader = false;
      // }, err => {
      //   this.errorMessage = err.error;
      //   this.commonService.showLoader = false
      // })
    }
  }

  onUploadError() {
    this.cloudPickerService.uploadProgress = 0;
    this.commonService.showFileLoader = false;
    this.cloudPickerService.upload = false;
  }

  ngOnDestroy(): void {
    this.onUploadError();
  }

}
