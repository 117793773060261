<span class="t-practices">
  <ng-container *ngFor="let practice of practicesList | slice:0:displayNumber; let last = last;">
    <a [routerLink]="['/practices',practice.c_name]" appStopPropagation
      [ngClass]="{'primary-practice':practice.is_primary}" container="body" i18n-ngbTooltip
      ngbTooltip="{{practice.is_primary ? 'Primary Practice' : ''}}">{{practice.name}}</a>{{last ? '' : ', '}}
  </ng-container>
  <ng-container *ngIf="moreSkills > 0">
    & <a href="javascript:;" (click)="open()" appStopPropagation i18n>more</a>
  </ng-container>
</span>
