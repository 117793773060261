<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form" class="loginForm">
  <div class="logo-large">
    <img i18n-alt alt="GLO" src="https://storage.googleapis.com/gl-practice-library/glo-logo.png">
  </div>
  <h1 i18n>GlobalLogic's Social Collaboration Platform</h1>
  <p class="sub-heading" i18n>Use your GlobalLogic / Windows credential to login</p>
  <div class="form-group mb-20i">
    <input type="text" class="form-control" formControlName="username">
  </div>
  <div class="form-group mb-20i">
    <input type="password" class="form-control" formControlName="password" i18n-placeholder placeholder="Enter password"
      autocomplete="off">
    <div class="invalid" *ngIf="form.get('password').invalid && (form.get('password').touched || invalidForm)">
      <div *ngIf="form.get('password').errors.whitespace">Enter password</div>
    </div>
  </div>
  <div class="invalid mb-20i" *ngIf="loginError">
    {{loginError}}
  </div>

  <!-- term accept -->
  <div class="form-group mb-20i" *ngIf="community">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="invalidLoginCheck" formControlName="accept">
      <label class="form-check-label" for="invalidLoginCheck" i18n>This blog will also be posted in Glo under <a
          [href]="communityUrl" target="_blank">{{community['name']}}</a> community. By posting this blog, you will also
        become member of this community, in case you are not already.</label>
    </div>
  </div>

  <div class="form-group mb-0">
    <button type="button" class="btn btn-light mr-5" (click)="closePopup()" i18n>Cancel</button>
    <button type="submit" class="btn btn-primary" [disabled]="(!form.get('accept').value || submitted)"
      [ngClass]="{'btn-disabled':!form.get('accept').value,'submitted':submitted}" i18n>Submit Blog</button>
  </div>
</form>