import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.css']
})
export class LocationsListComponent implements OnInit {
  @Input() data;
  bucketUrl = environment.bucketUrl;
  constructor() { }

  ngOnInit(): void {
  }

}
