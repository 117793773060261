import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldConfig } from 'app/interfaces/field';
import { UntypedFormGroup } from '@angular/forms';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { FilterService } from 'app/services/filter/filter.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-facet-options',
  templateUrl: './facet-options.component.html',
  styleUrls: ['./facet-options.component.css']
})
export class FacetOptionsComponent implements OnInit {
  field: FieldConfig;
  group: UntypedFormGroup;
  show = true;
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public commonService: CommonMethodsService, private filterService: FilterService) { }

  ngOnInit() {
  }

  onSelect(target) {
    this.filterService.targetElement = target;
  }
}
