<div class="ta-card" (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false"  [routerLink]="data.url">
  <div class="card-top-actions">
    <a (click)="removeTool($event, data.id, data.entityCategoryId)" class="circle-actions"
    *ngIf="data.removeCollection && showPreviewIcon" i18n-ngbTooltip i18n-ngbTooltip
    ngbTooltip="Remove from My Collection" container="body"><i class="fa-solid fa-xmark"></i></a>
  </div>
  <div class="ta-title truncate-2">
    <span [title]="data.title">
      {{data.title}} 
    </span>
  </div>
  <p class="mt-10 description truncate-4" [innerHTML]="data.description| truncate:200"></p>
  <div [ngClass]="data.type|class" class="ta-type">{{data.subtitle | truncate:60}}
  </div>
</div>