import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AwardsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  // get awards list
  getAwards(params?) {
    return this.http.get(this.apiUrl + '/v1/awards', { params });
  }

  // get award info
  getAward(id) {
    return this.http.get(this.apiUrl + `/v1/awards/${id}`);
  }

  submitAward(data) {
    return this.http.post(this.apiUrl + '/v1/awards', data);
  }

  updateAward(id, data) {
    return this.http.put(this.apiUrl + `/v1/awards/${id}`, data);
  }

  deleteAward(id) {
    return this.http.delete(this.apiUrl + `/v1/awards/${id}`);
  }

  // get awards types
  getAwardsTypes() {
    return this.http.get(this.apiUrl + '/v1/award-types');
  }

  // get financial quarters
  getAwardsQuarter(params) {
    return this.http.get(this.apiUrl + '/v1/financial-quarters', { params });
  }

  submitQuarter(data) {
    return this.http.post(this.apiUrl + '/v1/financial-quarters', data);
  }

  updateQuarter(id, data) {
    return this.http.put(this.apiUrl + `/v1/financial-quarters/${id}`, data);
  }

  getLastQuaterAwards(params?) {
    return this.http.get(this.apiUrl + '/v1/awards/last-quarter', { params });
  }

}

export interface Awards {
  awards: [];
  total_count: number;
}
