import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {
  apiUrl = environment.apiUrl;
  deleteBlogEvent = new EventEmitter();
  deleteBlogConfirmEvent = new EventEmitter();
  gloLoginEvent = new EventEmitter();
  practice;
  gloCommunity;
  updatedId = 0;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getBlogs(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/blogs', { params, headers });
  }

  deleteBlog(id) {
    return this.http.delete(this.apiUrl + `/v1/blogs/${id}`);
  }

  getBlogInfo(id: number, params?) {
    return this.http.get(this.apiUrl + `/v1/blogs/${id}`, { params });
  }

  getRecommendation(id) {
    return this.http.get(this.apiUrl + `/v1/blogs/${id}/recommended`);
  }

  // searchBlogs(params, headers?) {
  //   return this.http.get(this.apiUrl + '/v1/blogs/search', { params, headers });
  // }

  submitBlog(data) {
    return this.http.post(this.apiUrl + '/v1/blogs', data);
  }

  updateBlog(id, data) {
    return this.http.put(this.apiUrl + `/v1/blogs/${id}`, data);
  }

  approveReject(id, type, comments) {
    const data = {
      type,
      comments
    };
    return this.http.post(this.apiUrl + `/v1/blogs/${id}/status`, data);
  }

  gloLogin(data) {
    return this.http.post(this.apiUrl + `/v1/glo-login`, data);
  }

  updateBlogIndustry(id, data) {
    return this.http.put(this.apiUrl + `/v1/blogs/${id}/industries`, data);
  }
}

export interface Blogs {
  blogs: [];
  total_count: number;
}
