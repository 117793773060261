<content-loader height="350" width="300" primaryColor="#eee" secondaryColor="#ddd">
  <svg:rect x="0" y="0" width="300" height="150" />
  <svg:rect x="10" y="160" width="150" height="12" />
  <svg:rect x="10" y="180" width="250" height="12" />
  <svg:rect x="10" y="200" width="100" height="10" />
  <svg:rect x="10" y="230" width="280" height="10" />
  <svg:rect x="10" y="250" width="150" height="10" />
  <svg:rect x="10" y="280" width="100" height="40" />
  <svg:rect x="120" y="280" width="100" height="40" />

</content-loader>
