import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import * as introJs from 'intro.js';
import { findIndex } from 'lodash';
import { PageScrollService } from 'ngx-page-scroll-core';
import { AppService } from '../app/app.service';
import { LoginService } from '../auth/login.service';

declare var ResizeObserver: any;

@Injectable({
  providedIn: 'root'
})
export class IntrojsService {
  introJSEvent = new EventEmitter();
  displayTour = false;
  tourLoadedOnce = false;
  exitActionType = 0;

  constructor(private loginService: LoginService, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any,
    private appService: AppService) { }

  siteIntro(displayDontShowNextTime: boolean = true) {
    this.exitActionType = 0;
    this.tourLoadedOnce = true;
    const email = this.loginService.currentUser?.user.email;
    let introCompletedUser = localStorage.getItem('introCompletedUser') || '';
    if (window.innerWidth < 576 || (displayDontShowNextTime && introCompletedUser.includes(email)) || !this.loginService.isLoggedIn()) {
      return false;
    }
    this.introJSEvent.emit(true);
    const introJsObj = introJs();

    const steps: any[] = [
      {
        title: $localize`Welcome`,
        intro: `<div>
                  <div class="welcome-intro">` + $localize`Welcome to Practices Portal, it is a one-stop showcase for Technology and Design.` + `</div>
                  ${displayDontShowNextTime ? (
            '<div class="dont-show-next-time" id="dont-show-next-time">' + $localize`Don't show next time` + `</div>
            <div class="skip-intro" id="skip-intro">`+ $localize`Skip` + `</div>`
          ) : ''}
                </div>`,
      },
      {
        title: $localize`Search`,
        element: '#searchForm',
        intro: $localize`Search all your needs regarding technical nuggets, digital accelerators and SMEs.`,
      },
      {
        title: $localize`Practices`,
        element: '#practices-menu-items',
        intro: $localize`Practices is an enabler in success for business and delivery. Find all GlobalLogic Practices here.`,
      },
      {
        title: $localize`Innovation Hub`,
        element: '#innovation-menu-items',
        intro: $localize`Know about GlobalLogic Digital Accelerators and Technical Gallery.`,
      },
      {
        title: $localize`Insights`,
        element: '#nuggets-menu-items',
        intro: $localize`Explore insights in form of Tech Documents, Blogs and Tech Talks.`,
      },
      {
        title: $localize`Sales Toolbox`,
        element: '#sales-tools-menu-items',
        intro: $localize`Explore important sales tools like case studies, partnerships, assessment frameworks and SMEs.`,
      },
      {
        title: $localize`Engineer's Toolbox`,
        element: '#tools-menu-items',
        intro: $localize`Explore important practices tools like TechQ, Technology Radar and SCA.`,
      },
      {
        title: $localize`My Preferences`,
        element: '#my-preference',
        intro: $localize`Practice Portal Digest is a great way to keep yourself updated regularly. Schedule your digest here.`,
      },
      // {
      //   title: 'GlobalLogic Labs',
      //   element: '#ok-google',
      //   intro: '"Ok Google", "Talk to GlobalLogic Labs" - Try it from your mobile device.',
      //   position: 'left',
      // },
      // {
      //   title: $localize`Tour`,
      //   element: '#tour',
      //   intro: `<div>
      //             <p class="mb-15">` + $localize`Know More about the practices portal by visiting "Take a Video Tour". You can also take guided tour again by visiting "Guided Tour" Link.` + `</p>
      //             <a href="/tour" target="_blank" id="tour-link">
      //               <img src="https://storage.googleapis.com/gl-practice-library/images/tour-image.png" width="100%">
      //             </a>
      //           </div>`
      // }
    ];

    // click event on tour image
    $(document).on('click', '#tour-link', () => {
      this.setIntroCompleted(introCompletedUser, email);
      this.exitActionType = 12;
      introJsObj.exit();
    });

    // click event on skip intro link
    $(document).on('click', '#skip-intro', () => {
      this.exitActionType = 10;
      introJsObj.exit();
    });

    // click event on don't show next time link
    $(document).on('click', '#dont-show-next-time', () => {
      this.setIntroCompleted(introCompletedUser, email);
      this.exitActionType = 9;
      introJsObj.exit();

      const nextIntroJS = this.nextTimeIntro();
      const observer = new ResizeObserver(entries => {
        if (this.displayTour) {
          for (const entry of entries) {
            nextIntroJS.refresh();
            this.scrollToElement('#guided-tour');
          }
        }
      })
      observer.observe(document.body)
    });

    if (!this.loginService.currentUser?.person.login) {
      const index = findIndex(steps, { 'element': '#my-preference' });
      steps.splice(index, 1);
    }

    setTimeout(() => {
      this.displayTour = true;
      this.saveVisitorLog(1);
      introJsObj.setOptions({
        steps,
        hidePrev: true,
        exitOnOverlayClick: false,
        scrollToElement: true,
        disableInteraction: true,
      })
        .start()
        .onbeforechange(targetElement => {
          this.showHint(targetElement?.id);
        })
        .oncomplete(() => {
          this.exitActionType = 12;
          this.setIntroCompleted(introCompletedUser, email);
        })
        .onexit(() => {
          this.saveVisitorLog(this.exitActionType || 11);
          this.showHint();
          this.introJSEvent.emit(false);
          this.displayTour = false;
          window.scroll(0, 0);
        });
    });
  }

  nextTimeIntro() {
    this.introJSEvent.emit(true);
    const introJsObj = introJs();

    const steps: any[] = [
      {
        title: $localize`Guided Tour`,
        element: '#guided-tour',
        intro: $localize`You can always come here to take guided tour.`,
        position: 'top'
      },
    ];

    setTimeout(() => {
      this.displayTour = true;
      introJsObj.setOptions({
        steps,
        hidePrev: true,
        exitOnOverlayClick: true,
        showBullets: false,
        doneLabel: `Ok`,
      })
        .start()
        .onexit(() => {
          this.introJSEvent.emit(false);
          this.displayTour = false;
          window.scroll(0, 0);
        });
    });
    return introJsObj;
  }

  showHint(element = '') {
    document.getElementById('practices-menu-items').style.display = '';
    document.getElementById('nuggets-menu-items').style.display = '';
    document.getElementById('tools-menu-items').style.display = '';
    document.getElementById('sales-tools-menu-items').style.display = '';
    document.getElementById('innovation-menu-items').style.display = '';

    document.querySelector(".logged-user-action").setAttribute('hidden', 'true');

    if (element === 'my-preference' || element === 'my-document') {
      document.querySelector(".logged-user-action").removeAttribute('hidden');
    } else {
      if (element === 'da-menu') return;
      document.getElementById(element) ? document.getElementById(element).style.display = 'block' : '';
    }
  }

  setIntroCompleted(introCompletedUser, email) {
    if (!introCompletedUser.includes(email)) {
      introCompletedUser += (introCompletedUser ? `,` : '') + email;
      localStorage.setItem('introCompletedUser', introCompletedUser);
    }
  }

  scrollToElement(element) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: element,
      scrollOffset: 140,
      duration: 1000
    });
  }

  saveVisitorLog(action_type = 0) {
    const data = { action_type, referer: '/guided-tour', entity_category_id: null, entity_id: null };

    this.appService.saveVisitorLog(data)
      .subscribe(() => {
        this.appService.visitorLogEvent.emit(true);
      },
        e => { this.appService.visitorLogEvent.emit(true); });
  }
}
