import { Component, OnInit, Input } from '@angular/core';
import { AdvisoriesService } from 'app/services/advisories/advisories.service';

@Component({
  selector: 'app-advisory-request-access-dialog',
  templateUrl: './advisory-request-access-dialog.component.html',
  styleUrls: ['./advisory-request-access-dialog.component.css']
})
export class AdvisoryRequestAccessDialogComponent implements OnInit {
  @Input() data;
  advisory;
  termsAccepted = false;

  constructor(private advisoryService: AdvisoriesService) { }

  ngOnInit() {
    this.advisory = this.data;
  }

  onAccept() {
    this.termsAccepted = !this.termsAccepted;
    this.advisoryService.termsAcceptedEvent.emit(this.termsAccepted);
  }
}
