import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LikeService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  checkLike(id, params) {
    return this.http.get(this.apiUrl + `/v1/nuggets/${id}/like`, { params });
  }

  like(id, data) {
    return this.http.post(this.apiUrl + `/v1/nuggets/${id}/like`, data);
  }

  getLikes(id, params) {
    return this.http.get(this.apiUrl + `/v1/nuggets/${id}/likes`, { params });
  }
}
