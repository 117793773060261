<div class="partnership-card-body" [routerLink]="['/partners', data.c_name]">
  <div class="partnership-img">
    <img [src]="data.logo" [ngbTooltip]="data.name">
  </div>
  <div class="partnership-action" *ngIf="editAccess">
    <a [routerLink]="['/partners',data.c_name,'edit']" class="circle-actions mt-5" i18n-ngbTooltip ngbTooltip="Edit"
      container="body" appStopPropagation>
      <i class="fas fa-pencil-alt"></i></a>
    <a (click)="deletePartnership($event, data.id, data.name)" class="circle-actions mt-5" i18n-ngbTooltip
      ngbTooltip="Delete" container="body" appStopPropagation>
      <i class="fas fa-trash-alt"></i></a>
    <app-create-collection [entityId]="data.id" [entityCategory]="27"></app-create-collection>
  </div>
</div>