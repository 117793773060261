<div class="container-fluid fluid-bread">
  <div class="container">
    <ol class="breadcrumb">
      <ng-container *ngIf="commonService.breadcrumbs;else default">
        <li class="breadcrumb-item list" [ngClass]="{'active':last}"
          *ngFor="let breadcrumb of commonService.breadcrumbs;let last=last">
          <a [routerLink]="breadcrumb.routeLink" *ngIf="!last;else lastItemC">
            {{breadcrumb.label}} </a>
          <ng-template #lastItemC>
            <span class="mr-10">{{breadcrumb.label}}</span>
            <ng-container *ngIf="commonService.breadcrumbFilters.length">
              <i class="fas fa-chevron-down"></i>
              <div class="breadcrumb-div">
                <a [routerLink]="filter.link"
                  *ngFor="let filter of commonService.breadcrumbFilters;">{{filter.name}}</a>
              </div>
            </ng-container>
          </ng-template>
        </li>
      </ng-container>
      <ng-template #default>
        <li class="breadcrumb-item list" [ngClass]="{'active':last}"
          *ngFor="let breadcrumb of breadcrumbsService.breadcrumbs$ | async;let last=last">
          <a [routerLink]="breadcrumb.routeLink" *ngIf="!last;else lastItem"> {{breadcrumb.label}} </a>
          <ng-template #lastItem>
            <span class="mr-10">{{breadcrumb.label}}</span>
            <ng-container *ngIf="commonService.breadcrumbFilters.length">
              <i class="fas fa-chevron-down"></i>
              <div class="breadcrumb-div">
                <a [routerLink]="filter.link"
                  *ngFor="let filter of commonService.breadcrumbFilters;">{{filter.name}}</a>
              </div>
            </ng-container>
          </ng-template>
        </li>
      </ng-template>
    </ol>
  </div>
</div>
