import { Component, OnInit, Input, Inject, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { SpellCheckService } from 'app/services/spell-check/spell-check.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ToastrService } from 'ngx-toastr';
import { PracticesService } from 'app/services/practices/practices.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-submit-important-link',
  templateUrl: './submit-important-link.component.html',
  styleUrls: ['./submit-important-link.component.css']
})
export class SubmitImportantLinkComponent implements OnInit, OnDestroy {
  @Input() data;
  form: UntypedFormGroup;
  invalidForm = false;
  submitted = false;
  submitBtnText = $localize`Submit`;
  httpError;
  originalValues;

  constructor(private fb: UntypedFormBuilder, private formValidator: FormValidatorService, public spellCheckService: SpellCheckService,
    private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any, private practiceService: PracticesService,
    private commonService: CommonMethodsService, private toastrService: ToastrService, private router: Router,
    private modalService: ModalPopupService) { }

  @HostListener('window:beforeunload', ['$event'])
  onClose(event) {
    if (!this.commonService.isEqual(this.originalValues, this.form.value)) {
      event.returnValue = true;
    }
  }

  ngOnInit() {
    let importantLink = {};
    if (this.data.importantLink) {
      this.submitBtnText = $localize`Update`;
      importantLink = this.data.importantLink;
    }
    this.form = this.fb.group({
      entity_id: this.data.practiceId,
      entity_category_id: 12,
      name: this.fb.control(importantLink['name'] || '', [this.formValidator.whiteSpaceValidation, Validators.maxLength(100)]),
      url: this.fb.control(importantLink['url'] || '', [this.formValidator.whiteSpaceValidation, this.formValidator.urlValidation,
      Validators.maxLength(500)]),
      target: this.fb.control(importantLink['target'] || '_self', [Validators.required]),
      position: this.fb.control(importantLink['position'] || '', [Validators.pattern(/^\d*\.?\d*$/)]),
    });
    this.onChanges('name');

    this.originalValues = this.form.value;
    this.form.valueChanges
      .subscribe(value => {
        if (this.form.pristine) {
          this.originalValues = value;
        }
      });
  }

  onSubmit() {
    this.invalidForm = true;
    // check form submitted
    if (!this.submitted) {
      if (this.form.invalid) {
        try {
          setTimeout(() => {
            this.pageScrollService.scroll({
              document: this.document,
              scrollTarget: '.validate',
              scrollOffset: 140,
              duration: 200
            });
          });

        } catch (e) {
          console.error(e);
        }
        return false;
      }
      // check form spell errors
      if (this.spellCheckService.spellChecked) {
        this.saveData();
      } else {
        this.submitted = true;
        const data = {
          name: this.form.get('name').value,
        };
        this.spellCheckService.checkSpelling(data)
          .subscribe(response => {
            this.spellCheckService.spellChecked = true;
            this.spellCheckService.errorData = {};
            this.spellCheckService.spellError = false;

            if (response['spell'].name.length) {
              this.spellCheckService.spellError = true;
              this.spellCheckService.errorData['name'] = {
                error: this.spellCheckService.uniqueErrors(response['spell'].name),
                label: $localize`Title`,
                id: 'titleGroup',
                order: 0
              };
            }

            if (this.spellCheckService.spellError) {
              if (this.data.importantLink) {
                this.submitBtnText = $localize`Update without spell check`;
              } else {
                this.submitBtnText = $localize`Submit without spell check`;
              }
              this.submitted = false;
            } else {
              this.saveData();
            }
          });
      }
    }
  }

  saveData() {
    const values = {};
    Object.assign(values, this.form.value);
    this.httpError = undefined;
    this.invalidForm = false;

    this.submitted = true;
    if (this.data.importantLink) {
      // update api call
      this.practiceService.updateImportantLinks(this.data.importantLink.id, values)
        .subscribe(response => {
          if (response['message']) {
            this.toastrService.success(response['message']);
          }
          this.modalService.close();
          this.practiceService.importantLinkEvent.emit('update');
        }, error => {
          this.invalidForm = true;
          this.submitted = false;
          this.httpError = this.commonService.parseHttpErrors(error);
        });
    } else {
      this.practiceService.saveImportantLinks(values)
        .subscribe(response => {
          if (response['message']) {
            this.toastrService.success(response['message']);
          }
          this.modalService.close();
          this.practiceService.importantLinkEvent.emit('create');
        }, error => {
          this.invalidForm = true;
          this.submitted = false;
          this.httpError = this.commonService.parseHttpErrors(error);
        });
    }
  }

  onChanges(control) {
    this.form.get(control).valueChanges.subscribe(val => {
      if (this.spellCheckService.spellChecked) {
        this.spellCheckService.spellChecked = false;
        if (this.data.importantLink) {
          this.submitBtnText = $localize`Update`;
        } else {
          this.submitBtnText = $localize`Submit`;
        }
      }
    });
  }

  cancel() {
    if (!this.commonService.isEqual(this.originalValues, this.form.value)) {
      const data = {
        title: $localize`Leave form`, message: $localize`This form has unsaved changes, are you sure you want to leave this form?`,
        size: 'md', showFooter: true, btnOkText: $localize`Yes`, btnOkClass: 'btn-light', btnCancelText: $localize`No`, btnCancelClass: 'btn-primary'
      };
      this.modalService.confirm(data)
        .then((confirmed) => {
          if (confirmed) {
            this.modalService.close();
          }
        })
        .catch(() => { });
    } else {
      this.modalService.close();
    }
  }

  ngOnDestroy() {
    this.spellCheckService.reset();
  }
}
