import { NgModule } from '@angular/core';

import { MatchHeightDirective } from 'app/directives/match-height.directive';
import { DynamicFieldDirective } from 'app/directives/dynamic-field.directive';
import { DisableDirective } from 'app/directives/disable.directive';
import { StopPropagationDirective } from 'app/directives/stop-propagation.directive';
import { SortTableDirective } from 'app/directives/sort-table.directive';

@NgModule({
  declarations: [
    MatchHeightDirective,
    DynamicFieldDirective,
    DisableDirective,
    StopPropagationDirective,
    SortTableDirective,
  ],
  exports: [
    // HostComponentDirective,
    MatchHeightDirective,
    DynamicFieldDirective,
    DisableDirective,
    StopPropagationDirective,
    SortTableDirective,
  ]
})
export class DirectiveModule { }
