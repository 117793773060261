<div class="row">
  <div class="col-md-6">
    <content-loader height="500" width="500" primaryColor="#eee" secondaryColor="#ddd">
      <svg:rect x="0" y="0" rx="5" ry="5" width="400" height="20" />
      <svg:rect x="0" y="30" rx="5" ry="5" width="300" height="14" />
      <svg:rect x="0" y="60" width="500" height="300" />
      <svg:rect x="0" y="370" width="150" height="18" />
      <svg:rect x="300" y="370" width="200" height="18" />
      <svg:rect x="400" y="410" width="100" height="30" />
    </content-loader>
  </div>
  <div class="col-md-6">
    <content-loader height="500" width="500" primaryColor="#eee" secondaryColor="#ddd">
      <ng-container *ngIf="['poc','tech-doc','tech-talk'].includes(type)">
        <svg:circle cx="20" cy="80" r="18" />
        <svg:circle cx="60" cy="80" r="18" />
        <svg:circle cx="100" cy="80" r="18" />
        <svg:circle cx="140" cy="80" r="18" />
        <svg:circle cx="180" cy="80" r="18" />
      </ng-container>

      <ng-container *ngIf="['case-study','deck'].includes(type)">
        <svg:rect x="0" y="50" width="120" height="16" />
        <svg:rect x="0" y="80" width="380" height="12" />
      </ng-container>

      <ng-container *ngIf="!['deck'].includes(type)">
        <svg:rect x="0" y="120" width="120" height="16" />
        <svg:rect x="0" y="150" width="380" height="12" />
        <svg:rect x="0" y="190" width="120" height="16" />
        <svg:rect x="0" y="220" width="380" height="12" />
        <svg:rect x="0" y="260" width="120" height="16" />
        <svg:rect x="0" y="290" width="380" height="12" />
        <svg:rect x="0" y="330" width="120" height="16" />
        <svg:rect x="0" y="360" width="380" height="12" />
        <svg:rect x="0" y="400" width="120" height="16" />
        <svg:rect x="0" y="430" width="380" height="12" />
      </ng-container>
    </content-loader>
  </div>
</div>
