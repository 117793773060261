import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginService } from 'app/services/auth/login.service';
import { FilterService } from 'app/services/filter/filter.service';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { PeopleService } from 'app/services/people/people.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent implements OnInit {
  @Input() data;
  form: UntypedFormGroup;
  resolveForm: UntypedFormGroup;
  page = 1;
  pageEnd = false;
  loading = false;
  reports = [];
  userFlag;
  submitText = "Submit";
  edit = false;
  resolveFlagId;
  submitted = false;
  resolveSubmitted = false;

  constructor(private nuggetService: NuggetsService, private filterService: FilterService, private peopleService: PeopleService,
    private commonService: CommonMethodsService, private toastr: ToastrService, private modalService: ModalPopupService) { }

  ngOnInit(): void {
    this.getFlag();
  }

  createForm() {
    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl(this.userFlag ? this.userFlag.comment : '', [Validators.required, Validators.maxLength(250)])
    });
  }

  onSubmit() {
    this.submitted = true;
    this.nuggetService.submitFlag(this.data.id, { entity_category_id: this.data.cid, comment: this.form.value.comment })
      .subscribe(response => {
        this.submitted = false;
        if (response['message']) {
          this.toastr.success(response['message']);
        }
        if (response['flag']) {
          this.edit = false;
          this.userFlag = response['flag'];
          this.submitText = "Update";
        }
        this.nuggetService.reportNuggetEvent.emit('add');
      });
  }

  getFlag() {
    this.loading = true;
    const filter = [
      { key: 'perPage', values: [0] },
      { key: 'entity_category_id', values: [this.data.cid] }
    ];
    const params = this.filterService.getApiParams(filter);
    this.nuggetService.getFlags(this.data.id, params)
      .subscribe(response => {
        this.loading = false;
        this.reports = response['flags'];
        this.userFlag = response['current_user_flag'];
        if (this.userFlag) {
          this.submitText = "Update";
        }
        this.createForm();
      })
  }

  getPersonName(person) {
    return this.peopleService.getPersonName(person);
  }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.p-card-tooltip');
  }

  deleteFlag() {
    const data = {
      title: $localize`Delete Report`, message: $localize`Are you sure you want to delete this report?`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.nuggetService.deleteFlag(this.data.id, this.userFlag.id, { entity_category_id: this.data.cid })
            .subscribe((response) => {
              if (response['message']) {
                this.toastr.success(response['message']);
              }
              this.submitText = "Submit";
              this.userFlag = undefined;
              this.form.get('comment').setValue('');
              this.nuggetService.reportNuggetEvent.emit('delete');
            });
        }
      })
      .catch(() => { });
  }

  createResolveForm(id) {
    this.resolveFlagId = id;
    this.resolveForm = new UntypedFormGroup({
      comment: new UntypedFormControl('', [Validators.required, Validators.maxLength(250)])
    });
  }

  onResolve() {
    this.resolveSubmitted = true;
    this.nuggetService.resolveFlag(this.data.id, this.resolveFlagId, { entity_category_id: this.data.cid, comment: this.resolveForm.value.comment })
      .subscribe(response => {
        this.resolveSubmitted = false;
        if (response['message']) {
          this.toastr.success(response['message']);
        }
        if (this.userFlag && (this.resolveFlagId === this.userFlag.id)) {
          this.submitText = "Submit";
          this.userFlag = undefined;
          this.form.get('comment').setValue('');
        }
        this.reports = this.reports.filter(r => r.id !== this.resolveFlagId);

        this.edit = false;
        this.resolveFlagId = undefined;
        this.nuggetService.reportNuggetEvent.emit('resolve');
      });
  }

  cancelResolve() {
    this.resolveFlagId = undefined;
  }
}
