<ng-container [formGroup]="skillForm">
  <tr>
    <td>{{value['name']}}</td>
    <td>{{value['proficiency']}}</td>
    {{skillForm.get('status').invalid}} = {{submitted}}
    <td [ngClass]="{'validate':skillForm.get('status').invalid && submitted}">
      <select class="form-select" formControlName="status">
        <option value="" i18n>Please Select</option>
        <option value="3" i18n>OK</option>
        <option value="1" i18n>Raise Query</option>
      </select>
    </td>
    <td [ngClass]="{'validate':skillForm.get('comments').invalid && submitted}">
      <input type="text" class="form-control" formControlName="comments">
    </td>
  </tr>
</ng-container>