<p class="practice-desc mb-25">
  Advisories are GlobalLogic Confidential Property and can not be distributed without prior permission.
  By requesting the access, you acknowledge the following terms and conditions:
</p>
<ul class="list-items">
  <li>You will not download the advisory deck on your local machine without prior permission.</li>
  <li>You will not distribute it to anyone else without prior permission.</li>
  <li>You will not use the material 'As it is' in any other client deliverable.</li>
</ul>

<label class="mt-15">Owner: {{ advisory.advisory.owner ? advisory.advisory.owner.email_id : 'horizontal-practices@globallogic.com' }}</label>

<div class="mt-30">
  <label>
    <input type="checkbox" (click)="onAccept()"> I accept
  </label>
</div>
