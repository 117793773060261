import { Component, Input, OnInit } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-report-nugget',
  templateUrl: './report-nugget.component.html',
  styleUrls: ['./report-nugget.component.css']
})
export class ReportNuggetComponent implements OnInit {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() editAccess;
  type = '';
  isPerson = this.loginService.isPerson();

  constructor(private modalService: ModalPopupService, private loginService: LoginService) { }

  ngOnInit(): void {
    if (+this.entityCategoryId === 3) {
      this.type = $localize`Tech Document`;
    } else if (+this.entityCategoryId === 2) {
      this.type = $localize`Tech Talk`;
    } else if (+this.entityCategoryId === 5) {
      this.type = $localize`Blog`;
    } else if (+this.entityCategoryId === 4) {
      this.type = $localize`Innovation`;
    } else if (+this.entityCategoryId === 6) {
      this.type = $localize`Case Study`;
    }
  }

  open() {
    const data = {
      title: $localize`Report ${this.type}`, message: '', component: 'ReportViewComponent',
      data: { id: this.entityId, cid: +this.entityCategoryId, type: this.type, editAccess: this.editAccess }
    };
    this.modalService.open(data);
  }
}
