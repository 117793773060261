<!-- Nav Belt -->
<div class="nav-belt">
  <div class="menu-icon" (click)="toggleElement($event, 'menu')"><a href="javascript:;"><i class="fas fa-bars"></i></a>
  </div>
  <div class="logo"><a routerLink="/"><img src="assets/images/new-logo.png"></a></div>
  <div class="search-bar" (clickOutside)="hideMobileSearch()">
    <a class="mobile-search ml-auto mr-4" href="javascript:void(0);" (click)="toggleElement($event, 'search')"></a>
    <div class="mobile-search-container" [ngClass]="{'d-block': showMobileSearch}">
      <form [formGroup]="commonService.searchForm" (ngSubmit)="onSearch()" id="searchForm">
        <input type="text" class="text-input" i18n-placeholder placeholder="Search Practice/technology/expert"
          formControlName="search" (click)="focused = true" (clickOutside)="focused = false" #searchField>
        <input type="submit" class="search-btn"
          [disabled]="!(commonService.searchForm.get('search').value || '').trim() || !(people.length || nuggetList.length)">

        <!-- On Focus -->
        <div *ngIf="focused" class="search-wrapper">
          <div class="on-focus" *ngIf="!searchTerm">
            <ng-container *ngIf="trending.length">
              <h1 i18n>Trending</h1>
              <ul>
                <li *ngFor="let trend of trending" (click)="hideMobileSearch()">
                  <a routerLink="/search-results"
                    [queryParams]="{psearchterm: trend.search_term}">{{trend.search_term}}</a>
                </li>
              </ul>
              <hr *ngIf="searchService.searchHistory.length" />
            </ng-container>
            <ng-container *ngIf="searchService.searchHistory.length">
              <h1>
                <ng-container i18n>Recent Search</ng-container>
                <span class="btn-link cursor-pointer ml-20" (click)="clearHistory()" i18n>Clear All</span>
              </h1>
              <ul>
                <li *ngFor="let history of searchService.searchHistory" (click)="hideMobileSearch()">
                  <a routerLink="/search-results" [queryParams]="{psearchterm: history.term}">{{history.term}}</a>
                </li>
              </ul>
            </ng-container>
          </div>
          <!-- End of On Focus -->

          <!-- On type -->
          <div class="on-type" *ngIf="searchTerm">
            <ng-container *ngIf="nuggetList.length">
              <ul>
                <li *ngFor="let nugget of nuggetList" (click)="hideMobileSearch()">
                  <a [routerLink]="nugget['url']" (click)="saveSearchHistory()">
                    <img src="assets/images/src-{{nugget['img']}}.png">
                    <span [innerHTML]="nugget['title'] | truncate:50"></span><i></i>
                    {{nugget['parent_title']}}
                  </a>
                </li>
              </ul>
              <hr />
            </ng-container>

            <ng-container *ngIf="people.length">
              <h1 i18n>Experts</h1>
              <a class="avatar avatar-sm mr-5" *ngFor="let person of people" [routerLink]="['/team', person['login']]"
                [ngbTooltip]="getPersonName(person)" (click)="saveSearchHistory()">
                <img [src]="person['photo_url']">
              </a>
              <hr />
            </ng-container>
            <a routerLink="/search-results" [queryParams]="{psearchterm: searchTerm}"
              *ngIf="people.length || nuggetList.length;else noResult" i18n>See all results for
              “{{searchTerm}}”</a>
            <ng-template #noResult>
              <span i18n>No result found</span>
            </ng-template>
          </div>
          <!-- End of On type -->
        </div>
      </form>
    </div>
  </div>
  <div class="explore"><a routerLink="/technologytree" i18n>Explore Knowledge Base</a></div>
  <div class="action-holder">
    <!-- <div class="have-query">
      <a href="javascript:;" (click)="showHaveQuery=!showHaveQuery"
        (clickOutside)="onClickOutside($event, 'query')">Have a Query? <i class="fas fa-sort-down"></i></a>
      <div class="query-dd" [hidden]="!showHaveQuery">
        <ul>
          <li><a href="javascript:;" (click)="selectQuery('practice')">Practice Specific Query</a>
            <div>Ask a query to the members of a specific practice.</div>
          </li>
          <li><a href="http://mail.google.com/a/globallogic.com/mail?view=cm&amp;to={{mailingInfo?.practice_all_email}}"
              target="_blank" (click)="selectQuery()">Query to all Practices</a>
            <div>Not sure about practice? Ask your query to all practices.</div>
          </li>
          <li><a href="javascript:;" (click)="selectQuery('global')">Connect with Global Practice Head</a></li>
          <li><a routerLink="/feedback" (click)="selectQuery()">Give us Feedback</a></li>
        </ul>
      </div>
    </div> -->

    <button type="button" class="btn btn-border btn-blk mr-30 ipad-mr-10" (click)="showSubmitNugget = !showSubmitNugget"
      (clickOutside)="onClickOutside($event, 'nugget')" *ngIf="loginService.isPerson() && submitAccess()" i18n>Submit a
      Nugget</button>

    <div class="user-details">
      <div class="avatar avatar-sm" (click)="toggleElement($event, 'user-menu')"
        (clickOutside)="onClickOutside($event, 'user')">
        <img [src]="loginService.currentUser.person.photo_url || loginService.currentUser.user.picture || userIcon">
      </div>
      <div class="logged-user-action" [hidden]="!showUserMenu">
        <div class="">
          <div class="avatar avatar-60">
            <img [src]="loginService.currentUser.person.photo_url || loginService.currentUser.user.picture || userIcon">
          </div>

          <div class="mt-10 ml-10">
            <div class="title d-block">{{ userName }}</div>
            <div>{{ loginService.currentUser.user.email }} </div>
          </div>
        </div>
        <div class="divider divider-1"></div>

        <ul class="mt-10 user-menu-list" [perfectScrollbar]="config">
          <li *ngIf="loginService.currentUser.person.login" (click)="hideUserMenu()">
            <a [routerLink]="['/team', loginService.currentUser.person.login]"
              *ngIf="loginService.currentUser.person.login">
              <span><i class="fas fa-address-card"></i></span>
              <ng-container i18n>My Profile</ng-container>
            </a>
          </li>
          <li *ngIf="loginService.currentUser.person.login" (click)="hideUserMenu()" id="my-preference">
            <a routerLink="/my-preferences">
              <span><i class="fas fa-cog"></i></span> <ng-container i18n>My Preferences</ng-container></a>
          </li>
          <li (click)="hideUserMenu()" *ngIf="submitAccess()" id="my-document">
            <a routerLink="/my-documents">
              <span><i class="fas fa-file-alt"></i></span> <ng-container i18n>My Documents</ng-container> </a>
          </li>

          <li *ngIf="!isExternalUser" (click)="hideUserMenu()">
            <a routerLink="/admin" class="admin-links">
              <span><i class="fas fa-user"></i></span> <ng-container i18n>Admin</ng-container>
            </a>
            <span class="arrow" (click)="expendAdminMenu($event,'admin')" *ngIf="!showAdminMenu; else admin"
              appStopPropagation>
              <i class="fas fa-chevron-right pull-right"></i>
            </span>
          </li>

          <li *ngIf="sysadminAccess" (click)="hideUserMenu()">
            <a href="javascript:;" class="admin-links">
              <span><i class="fas fa-user-cog"></i></span> <ng-container i18n>Sys Admin</ng-container>
            </a>
            <span class="arrow" (click)="expendAdminMenu($event,'sysadmin')" *ngIf="!showSysadminMenu; else admin"
              appStopPropagation>
              <i class="fas fa-chevron-right pull-right"></i>
            </span>
          </li>
          <li><a href="javascript:;" (click)="loginService.logout()"><span><i class="fas fa-sign-out-alt"></i></span>
              <ng-container i18n>Logout</ng-container> </a></li>
        </ul>

        <ng-template #admin>
          <span class="arrow" (click)="showAdminMenu = false;showSysadminMenu = false;" appStopPropagation>
            <i class="fas fa-chevron-up pull-right"></i>
          </span>
          <ul (click)="hideUserMenu()">
            <ng-container *ngFor="let link of adminMenuLinks">
              <li *ngIf="link.access || adminAccess || link.sysadminAccess && sysadminAccess">
                <a [routerLink]="link.link" routerLinkActive="active" *ngIf="!link.external; else external;">{{
                  link.name }}</a>
                <ng-template #external>
                  <a [href]="link.link" target="_blank">{{ link.name }}</a>
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- Submit a Nugget -->
  <div *ngIf="showSubmitNugget" (click)="showSubmitNugget = true">
    <div class="background height-100vh"></div>
    <div class="submit-nugget">
      <div class="submit-head">
        <ng-container i18n>Submit a Nugget</ng-container> <span><a href="javascript:;"
            (click)="showSubmitNugget = false"><i class="fas fa-times"></i></a></span>
      </div>
      <div class="pad-20">
        <div class="title" i18n>What are Nuggets?</div>
        <p i18n>Nugget is a broader term representing any form of technical artifact like technical document, blog,
          Innovation
          or technical talk; can be in any format - presentation, pdf, video or document.</p>

        <ul class="nugget-list">
          <li>
            <a routerLink="/tech-papers/submit" (click)="showSubmitNugget = false"><span class="i-tech-document"></span>
              <div i18n>Tech Document</div>
            </a>
          </li>
          <li>
            <a routerLink="/tech-talks/submit" (click)="showSubmitNugget = false"><span class="i-tech-talk"></span>
              <div i18n>Tech Talk</div>
            </a>
          </li>
          <li>
            <a routerLink="/pocs/submit" (click)="showSubmitNugget = false"><span class="i-poc"></span>
              <div i18n>Tech Solution</div>
            </a>
          </li>
          <li *ngIf="loginService.isPerson()">
            <a routerLink="/blogs/submit" (click)="showSubmitNugget = false"><span class="i-blog"></span>
              <div i18n>Blog</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- End of Subit Nugget -->
</div>
<!-- End of Nav Belt -->

<!-- Navigation -->
<div class="main-nav" [ngClass]="{'d-block': showMobileMenu }" [hidden]="commonService.hideMenu">
  <ul>
    <li class="practices-m" id="practices-menu" (mouseleave)="shoWChildMenu($event,'practice')">
      <a routerLink="/practices" routerLinkActive="active" [ngClass]="{'active':activePractice}">
        <ng-container i18n>Practices</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 'p-menu')"
          *ngIf="showMobileMenuPractice; else pRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #pRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'p-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="practices-menu" [ngClass]="{'d-block': showMobileMenuPractice}" id="practices-menu-items">
        <div class="practices-sub-menu" *ngFor="let category of hPractices;">
          <label class="menu-label">{{category.category?.name}}</label>
          <ul>
            <li *ngFor="let practice of category.practices;let i = index;">
              <a [routerLink]="['/practices',practice.c_name]"
                [ngClass]="{'parent-menu': practice.childPractice.length}" routerLinkActive="active">{{ practice.name }}
                <span class="cp-arrow"
                  (click)="shoWChildMenu($event,'practice',i,practice.showChildMenu,category.category?.c_name)"
                  *ngIf="practice.childPractice.length">
                  <ng-container *ngIf="practice.showChildMenu;else right">
                    <i class="fas fa-chevron-up"></i>
                  </ng-container>
                  <ng-template #right>
                    <i class="fas fa-chevron-down"></i>
                  </ng-template>
                </span>
              </a>
              <ng-container *ngIf="practice.childPractice.length">
                <ul class="child-menu" [ngClass]="{'show-child': practice.showChildMenu}" appStopPropagation
                  (click)="preventDefault($event)">
                  <li *ngFor="let cPractice of practice.childPractice">
                    <a [routerLink]="['/practices',cPractice.c_name]" routerLinkActive="active">{{ cPractice.name
                      }}</a>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ul>
        </div>
        <div class="practices-sub-menu">
          <label class="menu-label"><ng-container i18n>My Collections</ng-container></label>
          <div class="scroll-container">
            <ul>
              <li>
                <a [routerLink]="['/collections']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  <ng-container i18n>About</ng-container>
                </a>
              </li>
              <li *ngFor="let collection of collections">
                <a [routerLink]="['/collections',collection.id]" routerLinkActive="active">
                  {{collection.name}}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="coe-menu">
          <label class="menu-label"><ng-container i18n>Center Of Excellence</ng-container></label>
          <ul>
            <li>
              <a routerLink="/digital-delivery/product-management" routerLinkActive="active">
                <ng-container i18n>Product Management - North America</ng-container>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </li>

    <!-- <li class="industries-m" id="industries-menu" (mouseleave)="shoWChildMenu($event,'indistry')">
      <a routerLink="/industries" routerLinkActive="active" [ngClass]="{'active':activeIndustry}">
        <ng-container i18n>Industries</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 'i-menu')"
          *ngIf="showMobileMenuIndustry; else iRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #iRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'i-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="industries-menu" [ngClass]="{'d-block': showMobileMenuIndustry}" id="industries-menu-items">
        <div class="industries-sub-menu">
          <label class="menu-label"> <ng-container i18n>Industries</ng-container></label>
          <ul>
            <li *ngFor="let practice of vPractices;let i = index;">
              <a [routerLink]="['/industries',practice.c_name]" routerLinkActive="active"
                [ngClass]="{'parent-menu': practice.childPractice.length}">{{ practice.name }}
                <span class="cp-arrow"
                  (click)="shoWChildMenu($event,'indistry',i,practice.showChildMenu,category.category?.c_name)"
                  *ngIf="practice.childPractice.length">
                  <ng-container *ngIf="practice.showChildMenu;else right">
                    <i class="fas fa-chevron-up"></i>
                  </ng-container>
                  <ng-template #right>
                    <i class="fas fa-chevron-down"></i>
                  </ng-template>
                </span></a>
              <ng-container *ngIf="practice.childPractice.length">
                <ul class="child-menu" [ngClass]="{'show-child': practice.showChildMenu}" appStopPropagation
                  (click)="preventDefault($event)">
                  <li *ngFor="let cPractice of practice.childPractice">
                    <a [routerLink]="['/practices',cPractice.c_name]" routerLinkActive="active">{{ cPractice.name
                      }}</a>
                  </li>
                </ul>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </li> -->

    <!-- <li class="nuggets-m" id="da-menu">
      <a routerLink="/digital-accelerators" routerLinkActive="active" i18n> Digital Accelerators
      </a>
    </li> -->

    <li class="innovation-m" id="innovation-menu">
      <a>
        <ng-container i18n>Innovation Hub</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 'in-menu')"
          *ngIf="showMobileMenuInnovation; else inRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #inRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'in-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="innovation-menu" [ngClass]="{'d-block': showMobileMenuInnovation}" id="innovation-menu-items">
        <ul>
          <li>
            <a routerLink="/digital-accelerators" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/nav-digital-accelrator.png">
              <div class="head" i18n>Digital Accelerators</div>
              <p i18n>Our IP showcase complementing our technical strength in upcoming technology trends and quality
                engineering processes to deliver at speed.</p>
            </a>
          </li>
          <li>
            <a routerLink="/pocs" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/9/5.png">
              <div class="head" i18n>Technical Gallery</div>
              <p i18n>Must be working code demonstrating modern technology concept designed for solving a
                real
                world problem.</p>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="nuggets-m" id="nuggets-menu">
      <a> <ng-container i18n>Insights</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 'n-menu')"
          *ngIf="showMobileMenuNugget; else nRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #nRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'n-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="nuggets-menu" [ngClass]="{'d-block': showMobileMenuNugget}" id="nuggets-menu-items">
        <ul>
          <li>
            <a routerLink="/tech-papers" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/2/2.png">
              <div class="head" i18n>Tech Documents</div>
              <p i18n>Abstract of a technology solution, tech research work relevant to company's business or technical
                investigation while building POC.</p>
            </a>
          </li>
          <li>
            <a routerLink="/blogs" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/4/16.png">
              <div class="head" i18n>Blogs</div>
              <p i18n>Technical posts done by Globytes in classified GLO communities relevant to Practice Technologies.
              </p>
            </a>
          </li>
          <li>
            <a routerLink="/tech-talks" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/4/1.png">
              <div class="head" i18n>Tech Talks</div>
              <p i18n>Recording of technical webinars or presentations done during internal or external conferences.</p>
            </a>
          </li>
        </ul>
      </div>
    </li>


    <li class="tools-m" id="tools-menu">
      <a>
        <ng-container i18n>Engineer's Toolbox</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 't-menu')"
          *ngIf="showMobileMenuTool; else tRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #tRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 't-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="tools-menu" [ngClass]="{'d-block': showMobileMenuTool}" id="tools-menu-items">
        <ul>
          <li>
            <a routerLink="/technology-radar" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-radar.png">
              <div class="head" i18n>Technology Radar</div>
              <p i18n>Forward looking tool to make room for new technologies. </p>
            </a>
          </li>
          <li>
            <a href="https://techq.globallogic.com/" target="_blank" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-techq.png">
              <div class="head" i18n>
                TechQ
                <!-- <img class="techq-new-img" src="/assets/images/new.gif" alt="New"> -->
              </div>
              <p i18n>One place for all your queries related to technologies, and domain questions.</p>
            </a>
          </li>
          <!-- <li>
            <a routerLink="/technology-selections" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-technology-selection.png">
              <div class="head" i18n>Technology Selections</div>
              <p i18n>Choose the right technology for your need by comparing multiple technology options.</p>
            </a>
          </li> -->
          <li>
            <a routerLink="/practices/security/sca" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-sca.png">
              <div class="head" i18n>SCA </div>
              <p i18n>Identify and remediate open source vulnerabilities and licensing compliance issues.</p>
            </a>
          </li>
        </ul>
        <!-- <div class="vpn-text">* Use VPN, if you are hitting this URL from outside company network</div> -->
      </div>
    </li>
    <li class="sales-tools-m" id="sales-tools-menu">
      <a>
        <ng-container i18n>Sales Toolbox</ng-container>
        <span class="arrow pull-right" (click)="toggleElement($event, 'st-menu')"
          *ngIf="showMobileMenuSalesTool; else stRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #stRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'st-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="sales-tools-menu" [ngClass]="{'d-block': showMobileMenuSalesTool}" id="sales-tools-menu-items">
        <ul>
          <li>
            <a routerLink="/case-studies" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/1/17.png">
              <div class="head" i18n>Case Studies</div>
              <p i18n>Fetched from Sales Portal case studies database.</p>
            </a>
          </li>
          <li>
            <a routerLink="/partners" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/nav-partnership.png">
              <div class="head" i18n>Partnerships</div>
              <p i18n>Explore our partnerships and latest partner success stories. </p>
            </a>
          </li>
          <li>
            <a routerLink="/assessments" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-assessment.png">
              <div class="head" i18n>Assessment Frameworks</div>
              <p i18n>An exhaustive framework for business teams and project owners to assess the current maturity of
                the product.</p>
            </a>
          </li>
          <li>
            <a [routerLink]="['/team']"
              [queryParams]="{ member: 'Core Member,Subject Matter Expert,Center Coordinator' }"
              routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/preview_gallery/9/4.png">
              <div class="head" i18n>Experts </div>
              <p i18n>Directory of Subject Matter Experts for Client Discussions.</p>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- <li class="initiatives-m" id="initiatives-menu">
      <a>Initiatives
        <span class="arrow pull-right" (click)="toggleElement($event, 'i-menu')"
          *ngIf="showMobileMenuInitiative; else tRight">
          <i class="fas fa-chevron-up pull-right"></i>
        </span>
        <ng-template #tRight>
          <span class="arrow pull-right" (click)="toggleElement($event, 'i-menu')">
            <i class="fas fa-chevron-right pull-right"></i>
          </span>
        </ng-template>
      </a>
      <div class="initiatives-menu" [ngClass]="{'d-block': showMobileMenuInitiative}" id="initiatives-menu-items">
        <ul>
          <li>
            <a routerLink="/initiatives/tech-thursdays" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-tech-thursdays.png">
              <div class="head">Tech Thursdays</div>
              <p>Thought leadership sessions on new and futuristic technology trends.</p>
            </a>
          </li>
          <li>
            <a routerLink="/initiatives/innovation-gl" routerLinkActive="active">
              <img src="https://storage.googleapis.com/gl-practice-library/images/menu-innovation-gl.png">
              <div class="head">Innovation@GL</div>
              <p>Internal knowledge-sharing platform for our people to foster a culture of non-stop innovation.</p>
            </a>
          </li>
        </ul>
      </div>
    </li> -->
  </ul>
  <!-- <div class="ok-google" id="ok-google">
    <a href="https://assistant.google.com/services/a/uid/0000000d27380dc4?hl=en&source=web" target="_blank">
      <img src="assets/images/ok-google.png">
    </a>
  </div> -->
</div>
<!-- End of Navigation -->


<app-have-a-query [queryType]="queryType" (closeQuery)="selectQuery()" [mailingInfo]="mailingInfo"
  *ngIf="showHaveQueryData && mailingInfo"></app-have-a-query>