import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  apiUrl = environment.apiUrl;
  assetUrl = environment.bucketUrl + '/wp-assets';
  breadcrumb = false;

  constructor(private http: HttpClient) { }

  getPages(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/pages', { params, headers });
  }

  getPage(page) {
    return this.http.get(this.apiUrl + `/v1/pages/${page}`);
  }

  getPageCMS(page, params) {
    return this.http.get(this.apiUrl + `/v1/pages/${page}/cms`, { params });
  }

  getPageTypes() {
    return this.http.get(this.apiUrl + `/v1/pages/parents`);
  }
}

export interface CmsPages {
  pages: [];
  total_count: number;
}
