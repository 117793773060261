import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-to',
  templateUrl: './assign-to.component.html',
  styleUrls: ['./assign-to.component.css']
})
export class AssignToComponent implements OnInit {
  form: UntypedFormGroup;
  submitted = false;
  invalidForm = false;
  httpError;
  @Input() data;

  constructor(private fb: UntypedFormBuilder, private activeModal: NgbActiveModal, private nuggetService: NuggetsService,
    private commonService: CommonMethodsService, private toastr: ToastrService) { }

  ngOnInit() {
    this.form = this.fb.group({
      assignee: this.fb.control('', [Validators.required])
    });
  }

  closePopup() {
    this.activeModal.dismiss();
  }

  onSubmit() {
    // check form submitted
    if (!this.submitted) {
      if (this.form.invalid) {
        this.invalidForm = true;
        return false;
      }
      this.invalidForm = false;
      this.submitted = true;
      const data = {
        reviewer: this.form.get('assignee').value[0],
        entity_category_id: this.data['entity_category_id']
      };
      this.nuggetService.assignTo(this.data['entity_id'], data)
        .subscribe(response => {
          this.closePopup();
          this.toastr.success(response['message']);
          this.nuggetService.assignReviewerEvent.emit('assign');
        }, error => {
          this.invalidForm = true;
          this.submitted = false;
          this.httpError = this.commonService.parseHttpErrors(error);
        });
    }
  }
}
