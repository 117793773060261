<div class="practices-ctag">
    <div class="wrapper">
        <ng-container *ngFor="let practice of practices | slice:0:displayPracticeNumber;">
            <span class="ctag" [routerLink]="['/practices',practice.c_name]" ngClass="{{practice.c_name}}" [ngbTooltip]="practice.name" tooltipClass='pm-tooltip'>
                {{practice.name | slice:0:1|uppercase}}
            </span>
        </ng-container>
    </div>
<a href="javascript:;" *ngIf="moreMember > 0" (click)="open()" appStopPropagation>
    <span class="more-practices">
        +{{moreMember}}
    </span>
</a>
</div>