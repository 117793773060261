<ng-container *ngFor="let member of likes">
  <div class="row members-row no-gutters" *ngIf="member['person']">
    <div class="col-md-2">
      <span class="avatar avatar-sm">
        <ng-container *ngIf="member.person && member.person.login; else inactiveIcon;">
          <ng-template #toolTipContent>
            <app-tooltip-person-card [data]="member.person" (mouseleave)="t.close()">
            </app-tooltip-person-card>
          </ng-template>
          <a [routerLink]="['/team',member['person']['login']]" [ngbTooltip]="toolTipContent"
            tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
            (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)">
            <img [src]="member['person'].photo_url">
          </a>
        </ng-container>
        <ng-template #inactiveIcon>
          <span>
            <img [src]="member['person'].photo_url">
          </span>
        </ng-template>
      </span>
    </div>
    <div class="col-md-9 mt-15 ml-10">
      <a [routerLink]="['/team',member['person']['login']]"
        *ngIf="member.person && member.person.login; else inactive;">{{getPersonName(member['person'])}}</a>
      <ng-template #inactive>
        <span>
          {{getPersonName(member['person'])}}
        </span>
      </ng-template>
    </div>
  </div>
</ng-container>

<p class="text-center mt-10" *ngIf="loading"><i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i></p>
