import { Component, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { Router, ActivatedRoute } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { environment } from 'environments/environment';
import { DriveService } from 'app/services/drive/drive.service';

@Component({
  selector: 'app-cms-login',
  templateUrl: './cms-login.component.html',
  styleUrls: ['./cms-login.component.css']
})
export class CmsLoginComponent implements OnInit, OnDestroy {
  invalidLogin = false;
  error: any;
  cms = '';
  siteUrl = environment.siteUrl;
  apiUrl = environment.apiUrl;

  constructor(private router: Router, private renderer: Renderer2,
    private loginService: LoginService, private route: ActivatedRoute, private jwtHelper: JwtHelperService,
    private commonService: CommonMethodsService, private driveService: DriveService) {
    this.commonService.showFooter = false;
  }

  ngOnInit(): void {
    this.renderer.addClass(document.body, 'bg');
    this.renderer.addClass(document.body, 'cms-bg');
    this.route.queryParamMap
      .subscribe(param => {
        if (param.has('cmsUrl')) {
          this.cms = param.get('cmsUrl');
        } else {
          this.router.navigate(['/']);
        }
      });

    this.invalidLogin = !!localStorage.getItem('error');
    localStorage.removeItem('error');
  }

  duoLogin() {
    localStorage.setItem('loginSource', 'cms');
    window.open(this.apiUrl + '/v1/login/sso', '_parent');
  }

  // socialSignIn(socialPlatform: string) {
  //   let socialPlatformProvider;
  //   if (socialPlatform === 'google') {
  //     socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //   }

  //   this.socialAuthService.signIn(socialPlatformProvider).then(
  //     (userData) => {
  //       if (userData) {
  //         this.signIn(userData);
  //       }
  //     }
  //   );
  // }

  // signIn(credentials) {
  //   this.loginService.login(credentials)
  //     .subscribe(result => {
  //       if (result) {
  //         this.loginService.getToken(result)
  //           .subscribe(response => {
  //             parent.postMessage(response['cmsJwtToken'], this.cms);
  //           });
  //       } else {
  //         this.invalidLogin = true;
  //       }
  //     },
  //       error => {
  //         this.error = error; // error path
  //         this.invalidLogin = true;
  //         // this.loginService.clear();
  //       }
  //     );
  // }

  ngOnDestroy() {
    this.commonService.showFooter = true;
    this.renderer.removeClass(document.body, 'bg');
    this.renderer.removeClass(document.body, 'bg cms-bg');
  }

}
