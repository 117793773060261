import { Injectable } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/shared/components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {

  constructor(private modalService: NgbModal, private config: NgbModalConfig) { }

  open(data: ModelData) {
    const modalRef = this.modalService.open(ModalComponent, { centered: true, size: data['size'] || '' });
    modalRef.componentInstance.data = data;
  }

  confirm(data: ModelData): Promise<boolean> {
    const modalRef = this.modalService.open(ModalComponent, { centered: true, size: data['size'] || 'sm' });
    modalRef.componentInstance.data = data;

    return modalRef.result;
  }

  // confirmDelete(data: ModelData): Promise<boolean> {
  //   const modalRef = this.modalService.open(ModalComponent, { centered: true, size: data['size'] || 'sm' });
  //   modalRef.componentInstance.data = data;

  //   return modalRef.result;
  // }

  close() {
    this.modalService.dismissAll();
  }
}

export interface ModelData {
  id?: string;
  title?: string;
  message?: string;
  component?: string;
  data?: any;
  type?: string;
  disableBtnOk?: boolean;
  btnOkText?: string;
  btnOkClass?: string;
  btnOkTooltip?: string;
  showBtnCancel?: boolean;
  btnCancelText?: string;
  btnCancelClass?: string;
  btnCancelTooltip?: string;
  showFooter?: boolean;
  showHeader?: boolean;
  classes?: string;
}
