<label for="industry-options" *ngIf="!hideLabel">{{label}}
  <span class="help" *ngIf="optional" i18n>(Optional)</span></label>
<div class="help" *ngIf="helpText">{{helpText}}</div>
<ng-select2 [data]="industries" [options]="options" [value]="values" (valueChanged)="valueChanged($event)"
  [id]="'industry-options'" class="custom-select-field industry-options" width="100%"
  [ngClass]="{'validate':form.get(controlName).invalid && (touched || submitted)}"></ng-select2>
<div class="search-tag-wrapper row">
  <span class="search-tag" *ngFor="let industry of selectedindustries">
    {{industry.text}}<a (click)="remove(industry.id)">X</a>
  </span>
</div>
<div *ngIf="form.get(controlName).invalid && (touched || submitted)" class="invalid">
  <div *ngIf="form.get(controlName).errors.required">
    {{placeholder}}
  </div>
</div>