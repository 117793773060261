import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { filter, findIndex } from 'lodash';
import { PeopleService } from '../people/people.service';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PracticesService {
  apiUrl = environment.apiUrl;
  updateMembershipEvent = new EventEmitter();
  deleteMembershipEvent = new EventEmitter();
  joinPracticeEvent = new EventEmitter();
  leavePracticeEvent = new EventEmitter();
  deleteDeckEvent = new EventEmitter();
  importantLinkEvent = new EventEmitter();
  practiceDeleteEvent = new EventEmitter();
  practiceEvent = new EventEmitter();
  selectVerticalPracticeEvent = new EventEmitter();
  allOptionsSubject = new BehaviorSubject<any[]>([]);
  cName = '';
  currentPractice;
  showPublicView = false;
  withCommunity = false;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private router: Router,
    private toastrService: ToastrService,
    public commonService: CommonMethodsService,
    private modalService: ModalPopupService,
    private peopleService: PeopleService,
  ) { }

  getPractices(params?) {
    return this.http.get(this.apiUrl + '/v1/practices', { params });
  }

  getPracticeInfo(id, params?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}`, { params });
  }

  getPracticesTree() {
    return this.http.get(this.apiUrl + '/v1/practices/tree');
  }

  editDescription(id, description) {
    return this.http.put(this.apiUrl + `/v1/practices/${id}/description`, { description });
  }

  editName(id, name) {
    return this.http.put(this.apiUrl + `/v1/practices/${id}/name`, { name });
  }

  getPracticeDecks(params, headers?: HttpHeaders) {
    return this.http.get(this.apiUrl + `/v1/sales-collaterals`, { params, headers });
  }

  getTopContributors(params) {
    return this.http.get(this.apiUrl + `/v1/contributors`, { params });
  }

  getNotifications(params) {
    return this.http.get(this.apiUrl + `/v1/notifications`, { params });
  }

  getRecommendedMembers(id, params, headers?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/recommended-members`, { params, headers });
  }

  getImportantLinks(params, headers?) {
    return this.http.get(this.apiUrl + `/v1/important-links`, { params, headers });
  }

  saveImportantLinks(data) {
    return this.http.post(this.apiUrl + `/v1/important-links`, data);
  }

  updateImportantLinks(id, data) {
    return this.http.put(this.apiUrl + `/v1/important-links/${id}`, data);
  }

  deleteImportantLinks(id) {
    return this.http.delete(this.apiUrl + `/v1/important-links/${id}`);
  }

  deleteDeck(id) {
    return this.http.request('delete', this.apiUrl + `/v1/sales-collaterals/${id}`,
      { body: { email_id: this.loginService.currentUser.user.email } });
  }

  getCommunity(id: number, headers?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/community`, { headers });
  }

  getPracticeDeck(id, params?) {
    return this.http.get(this.apiUrl + `/v1/sales-collaterals/${id}`, { params });
  }

  submitPracticeDeck(data) {
    return this.http.post(this.apiUrl + '/v1/sales-collaterals', data);
  }

  updatePracticeDeck(id, data) {
    return this.http.put(this.apiUrl + `/v1/sales-collaterals/${id}`, data);
  }

  getCollateralType() {
    return this.http.get(this.apiUrl + `/v1/collateral-types`);
  }

  getPracticesCards(headers?, params?) {
    return this.http.get(this.apiUrl + '/v1/practices/cards', { headers, params });
  }

  getPracticeOrgMembers(id) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/org-members`);
  }

  getPracticeMembers(id, params) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/members`, { params });
  }

  getPracticeLocationMembers(id) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/location-members`);
  }

  updateMembership(id, mid, data) {
    return this.http.put(this.apiUrl + `/v1/practices/${id}/members/${mid}/membership`, data);
  }

  removeMembership(id, mid) {
    return this.http.put(this.apiUrl + `/v1/practices/${id}/members/${mid}/remove`, '');
  }

  getSelfNominatedMembers(id, params, headers?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/nominations`, { params, headers });
  }

  deleteSelfNomination(id, nid) {
    return this.http.delete(this.apiUrl + `/v1/practices/${id}/nominations/${nid}`);
  }

  updateGmailGroups(emailId, practiceId, action, member_type_id, from_member_type_id) {
    let url = 'https://script.google.com/a/globallogic.com/macros/s/AKfycbzNRxcK0V4rSyi0oGFz6PjZ3JH-ja9fdEQfSGmQFQfBR3SlXw/exec?' +
      'emailId=' + emailId + '&practiceId=' + practiceId + '&action=' + action + '&src=' + environment.src;
    url += member_type_id ? '&member_type_id=' + member_type_id : '';
    url += from_member_type_id ? '&from_member_type_id=' + from_member_type_id : '';

    return this.http.jsonp(url, 'callback');
  }

  checkNomination(id) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/check-nomination`);
  }

  nominate(id, data) {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/nominations`, data);
  }

  getDndList(id, params) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/dnd-list`, { params });
  }

  checkDnd(id, personId?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/check-dnd`, { params: { person_id: personId } });
  }

  leave(id, data) {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/leave`, data);
  }

  removeFromDND(id, data) {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/remove-dnd`, data);
  }

  addMember(id, personId, data) {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/members/${personId}/add`, data);
  }

  checkFollow(id) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/follow`);
  }

  follow(id, data = '') {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/follow`, data);
  }

  updateDeckPosition(data) {
    return this.http.post(this.apiUrl + `/v1/sales-collaterals/position`, data);
  }

  getMailingInfo() {
    return this.http.get(this.apiUrl + `/v1/practices/mailing-info`);
  }

  leavetMembershipText(practice) {
    let text = '';
    const personPractice = (this.loginService.currentUser.person.practices || [])
      .filter(p => p.c_name === practice.c_name)[0];
    const memberships = personPractice ? personPractice['memberships'] : [];
    memberships.forEach(membership => {
      if (text.includes(membership.type)) {
        return false;
      }
      text += text.length > 0 ? ' and ' : '';
      if (membership.c_name === 'center_coordinator') {
        text += `${membership.type} `;
      } else if (membership.c_name === 'core') {
        text += `${membership.type} `;
      } else {
        text += `Member `;
      }
    });
    text = $localize`Remove yourself as a ${text} of ${practice.name} practice.`;
    return text;
  }

  updatePractice(id, data) {
    return this.http.put(this.apiUrl + `/v1/practices/${id}`, data);
  }

  submitPractice(data) {
    return this.http.post(this.apiUrl + `/v1/practices`, data);
  }

  deletePractice(id) {
    return this.http.delete(this.apiUrl + `/v1/practices/${id}`);
  }

  skillReminderUpdate(id, personId) {
    return this.http.post(this.apiUrl + `/v1/practices/${id}/members/${personId}/skill-update-reminder`, {});
  }

  deletePracticeHelper(practiceName, practiceId) {
    const data = {
      title: $localize`Delete Practice`, message: $localize`Are you sure you want to delete ${practiceName} practice? All nuggets, members, digital accelerators mapping associated with this practice will be removed.`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.deletePractice(practiceId)
            .subscribe(response => {
              this.toastrService.success(response['message']);
              this.router.navigate(['/practices']);
              this.practiceDeleteEvent.emit({ practiceName, practiceId });
              this.practiceEvent.emit('delete');
              this.commonService.showLoader = false;
            }, error => {
              this.toastrService.error(error['error']['error']);
              this.practiceDeleteEvent.emit({ practiceName, practiceId });
              this.commonService.showLoader = false;
            });
        }
      })
      .catch(() => { });
  }

  updatePosition(data) {
    return this.http.post(this.apiUrl + `/v1/practices/position`, data);
  }

  getToolsAccelerators(id, params?) {
    return this.http.get(this.apiUrl + `/v1/practices/${id}/tools-accelerators`, {params})
  }

  // changeType(event, from, to, person) {
  //   event.preventDefault();
  //   const personName = this.peopleService.getPersonName(person);
  //   let message;
  //   if (to === 2 && from === 1) {
  //     message = `Are you sure you want to change ${personName} membership from Subject Matter Expert to Member?`;
  //   }
  //   if (to === 1 && from === 2) {
  //     message = `Are you sure you want to change ${personName} membership from Member to Subject Matter Expert?`
  //   }
  //   if (to === 4 && from === 2) {
  //     message = `Are you sure you want to change ${personName} membership from Member to Core Member?`;
  //   }

  //   const data = {
  //     title: 'Update Membership', message: message, size: 'md', showFooter: true, btnOkText: 'Yes', btnCancelText: 'No'
  //   };
  //   this.modalService.confirm(data)
  //     .then((confirmed) => {
  //       if (confirmed) {
  //         const memberData = {
  //           original_member_type: from,
  //           member_type: to
  //         };

  //         this.updateMembership(this.currentPractice[0]['id'], person['login'], memberData)
  //           .subscribe(response => {
  //             const headers = new HttpHeaders({
  //               'Cache-Control': 'no-cache'
  //             });
  //             this.toastrService.success(response['message'])
  //             this.updateMembershipEvent.emit(headers);
  //             this.updateGmailGroupsCall(person['contact'].email_id, this.currentPractice[0]['id'], 'move', to, from);
  //           });
  //       }
  //     })
  //     .catch(() => { });
  // }

  removeMember(event, person) {
    event.preventDefault();
    const personName = this.peopleService.getPersonName(person);
    const data = {
      title: $localize`Remove Membership`, message: $localize`Are you sure you want to remove ${personName} from
       ${this.currentPractice[0]['name']} practice?`,
      size: 'md', showFooter: true, btnOkText: 'Yes', btnCancelText: 'No'
    };

    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.removeMembership(this.currentPractice[0]['id'], person['login'])
            .subscribe(() => {
              const headers = new HttpHeaders({
                'Cache-Control': 'no-cache'
              });
              this.deleteMembershipEvent.emit({ headers, type: 'practice-member' });

              this.updateGmailGroupsCall(person['contact'].email_id, this.currentPractice[0]['id'], 'remove_all');
            });
        }
      })
      .catch(() => { });
  }

  moveFrom(event, from, person) {
    const personName = this.peopleService.getPersonName(person);
    event.preventDefault();
    const memberData = {
      id: this.currentPractice[0]['id'],
      login: person['login'],
      email: person['contact']['email_id'],
      personName: personName,
      orignalMemberId: from
    };

    const data = {
      title: $localize`Update Membership`,
      message: '',
      component: 'MoveFromCoreComponent', data: memberData
    };

    this.modalService.open(data);
  }

  editLocation(event, person) {
    event.preventDefault();
    const currentPracticeMemberships = filter(person['practices'], { id: this.currentPractice[0]['id'] })[0]['memberships'];
    let locations = [];
    if (findIndex(currentPracticeMemberships, { c_name: 'center_coordinator' }) > -1) {
      locations = currentPracticeMemberships.filter(member => member['center']).map(member => member['center']);
    }
    const memberData = {
      id: this.currentPractice[0]['id'],
      mid: person['login'],
      email: person['contact']['email_id'],
      locations: locations
    };
    const data = { title: $localize`Center Coordinator`, message: '', component: 'EditCcLocationComponent', data: memberData };
    this.modalService.open(data);
  }

  updateGmailGroupsCall(emailId, practiceId, action, member_type_id?, from_member_type_id?) {
    this.updateGmailGroups(emailId, practiceId, action, member_type_id, from_member_type_id)
      .subscribe();
  }

  openContacUs(mail, label) {
    const data = {
      title: $localize`Contact ${label}`, component: 'ContactUsComponent', data: { mail }, size: 'md', showFooter: false
    };
    this.modalService.open(data)
  }

  fetchInitialPracticesOptions() {
    let params;
    if(this.withCommunity) {
      params = new HttpParams().set('filters[with_community]', this.withCommunity)
    }
    this.getPractices(params).pipe(
      map(optionsData => {
        return optionsData['rows'].map(option => ({
          id: option.id,
          label: option.name,
          parent: option.type.name == 'Vertical' ? "Industries" : "Practices",
          practiceCommunity: option.practice_community || null,
          parent_id: option.parent_id
        }));
      })
    ).subscribe(options => {
      this.allOptionsSubject.next(options);
    });
  }
}

export interface Decks {
  sales_collaterals: [];
  total_count: number;
}

export interface Notifications {
  notifications: [];
  total_count: number;
}

export interface ImportantLinks {
  important_links: [];
  total_count: number;
}
