import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import * as lodash from 'lodash';
import { CustomerActivityService } from 'app/services/customer-activities/customer-activity.service';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-nugget-option',
  templateUrl: './nugget-option.component.html',
  styleUrls: ['./nugget-option.component.css']
})
export class NuggetOptionComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() value;
  @Input() controlName = '';
  @Input() label = '';
  @Input() placeholder = $localize`Select nugget`;
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() optional = false;

  apiUrl = environment.apiUrl;
  people;
  options: Options;
  touched = false;
  data = [{ id: '', text: '' }];
  uri = '';
  apiUri = '';
  type = '';
  nuggetType = '';
  nuggetTypes = [];
  selectedNuggets = [];
  nuggetLoaded = false;
  dataOption = [];

  constructor(private customerActivityService: CustomerActivityService) { }

  ngOnInit() {
    this.getNuggetType();
    this.setOptions();
    this.setValues();
    this.form.get('entity_type').valueChanges
      .subscribe(value => {
        this.setNuggetType(value);
        this.options = undefined;
        setTimeout(() => {
          this.setOptions();
        }, 0);
      });
  }

  ngOnChanges() {
    if (!this.nuggetLoaded) {
      setTimeout(() => {
        this.nuggetLoaded = true;
      }, 1000);
      this.setValues();
    }
  }

  setValues() {
    if (this.value && this.value.length) {
      this.data = this.value.map((nugget) => ({
        id: nugget.entity.id,
        text: nugget.entity.title,
        entity_category_id: nugget.entity_category.id,
        id_type: nugget.entity.id + '-' + nugget.entity_category.id
      })
      );
      this.onSelect({ data: this.data });
    }
  }

  setNuggetType(value) {
    switch (value) {
      case '2':
        this.uri = this.apiUri = 'tech-talks';
        this.type = 'tech_talks';
        this.nuggetType = 'Tech Talk';
        break;
      case '3':
        this.uri = 'tech-papers';
        this.apiUri = 'tech-documents';
        this.type = 'tech_documents';
        this.nuggetType = 'Tech Document';
        break;
      case '4':
        this.uri = this.apiUri = 'pocs';
        this.type = 'pocs';
        this.nuggetType = 'Innovation';
        break;
      case '6':
        this.uri = this.apiUri = 'case-studies';
        this.type = 'case_studies';
        this.nuggetType = 'Case Study';
        break;
    }
  }

  getNuggetType() {
    this.customerActivityService.getNuggetType()
      .subscribe(response => {
        this.nuggetTypes = response['rows'].filter(type => type.id !== 5);
      });
  }

  setOptions() {
    this.options = {
      tags: false,
      multiple: this.multiple,
      placeholder: this.placeholder,
      language: { errorLoading: () => $localize`Searching...` },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/' + this.apiUri,
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1
        }),
        processResults: (data) => {
          this.dataOption = data[this.type].map((nugget) => ({
            id: nugget.id,
            text: this.type === 'case_studies' ? `${nugget.client_name} - ${nugget.project_name}` : nugget.title,
            entity_category_id: this.form.get('entity_type').value,
            id_type: nugget.id + '-' + this.form.get('entity_type').value
          })
          );

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      }
    };
  }

  valueChanged(event) {
    this.onSelect({
      data: this.dataOption.filter(o =>
        Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
    });
  }

  onSelect(event) {
    const nuggets = event.data.map(value => {
      this.setNuggetType(value.entity_category_id.toString());
      return {
        entity_id: value.id,
        entity_category_id: value.entity_category_id,
        title: value.text,
        id_type: value.id_type,
        uri: this.uri,
        nugget_type: this.nuggetType,
      };
    });

    if (nuggets.length) {
      this.touched = true;
      if (this.multiple) {
        this.selectedNuggets = lodash.uniqBy(nuggets.concat(this.form.get(this.controlName).value || []), 'id_type');
      } else {
        this.selectedNuggets = nuggets;
      }
      if (!isEqual(this.selectedNuggets, this.data)) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(this.selectedNuggets);
      $('#' + this.controlName + ' select').val(null).trigger('change');
    }
  }

  remove(id) {
    this.touched = true;
    this.selectedNuggets = this.form.get(this.controlName).value.filter(nugget => nugget.entity_id !== id);
    if (!isEqual(this.selectedNuggets, this.data)) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(this.selectedNuggets);
    $('#' + this.controlName + ' select option[value="' + id + '"]').remove();
  }
}
