<app-practices-chip [practices]="event.practices" displayLength="200"></app-practices-chip>
<div class="customer-act-body mt-5" [innerHTML]="event.description"></div>
<div class="date mt-20">
  <span style="font-weight: 700;" i18n>Added By:</span>
  <ng-container *ngIf="event.creator;else defaultCreator">
    <span class="avatar avatar-sm ml-5">
      <a [routerLink]="['/team', event.creator['login'] || event.creator['person_id']]">
        {{peopleService.getPersonName(event.creator)}}
      </a>
    </span>
  </ng-container>
  <ng-template #defaultCreator>
    horizontal-practices
  </ng-template>
  on {{event.created_at | date:'mediumDate':'GMT'}}
</div>
<div class="date mt-5">
  <span class="font-weight-bold" i18n>Completion Date:</span>
  {{event.activity_date | date:'mediumDate':'GMT'}}
</div>
<div class="date mt-5" *ngIf="getMembers().length > 0">
  <span class="font-weight-bold d-block mb-5" i18n>Participants:</span>
  <div>
    <app-members-chip [members]="getMembers()" [displayNumber]="50" [fixHeight]="false"></app-members-chip>
  </div>
</div>