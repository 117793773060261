<div class="form-group" *ngIf="data.type !== 'carousel' && data.showPractices && data.type !== 'nugget'">
  <label i18n>Primary Practice</label>
  <select class="form-select" (change)="changePratice($event)" [(ngModel)]='selectedPratice'>
    <optgroup label="Practices">
      <option [value]="practice.id" *ngFor="let practice of hPractices"
        [ngClass]="{'selected-option': selectedPratice == practice.id}">{{practice.name}}</option>
    </optgroup>
    <optgroup label="Industries">
      <option [value]="practice.id" *ngFor="let practice of vPractices"
        [ngClass]="{'selected-option': selectedPratice == practice.id}">{{practice.name}}</option>
    </optgroup>
  </select>
</div>
<div class="form-group">
  <label *ngIf="data.type !== 'carousel' && data.type !== 'nugget'" i18n>Select Preview Image</label>
  <ul class="new-nugeet-preview" [perfectScrollbar]="config">
    <li *ngIf="uploadAccess">
      <div class="preview-upload-btn" [ngbTooltip]="tooltipText" tooltipClass="lock-tootip" container="body"
        *ngIf="data.type === 'carousel' || data.type === 'nugget'; else pg">
        <a (click)="uploader.click()">
          <ng-container i18n>Upload</ng-container>&nbsp;<i class="far fa-question-circle"></i>
        </a>
      </div>
      <ng-template #pg>
        <div>
          <a (click)="uploader.click()">
            <ng-container i18n>Upload</ng-container>
            <div>
              {{uploadMessage}}
            </div>
          </a>
        </div>
      </ng-template>
      <input hidden #uploader type="file" accept="image/*" onclick="value = null" (change)="onFileChange($event)">
    </li>
    <li *ngFor="let image of images" (click)="updatePreviewUrl(image.authenticated_url)">
      <img [ngStyle]="{'border': selectedPreviewUrl == image.authenticated_url ? '4px solid #f37037' : 'none' }"
        src='{{image.authenticated_url}}' />
    </li>
  </ul>
</div>
<div class="preview-update" *ngIf="data.type != 'carousel' && data.type != 'nugget'">
  <button class="btn btn-primary" (click)="updatePreview()" [disabled]="errorMessage || !selectedPreviewUrl"
    [ngClass]="{'btn-disabled':errorMessage || !selectedPreviewUrl}" i18n>UPDATE PREVIEW</button>
  <div class="invalid" *ngIf="errorMessage">{{errorMessage}}</div>
</div>
<div class="invalid" *ngIf="errorMessage && (data.type == 'carousel' || data.type == 'nugget')">{{errorMessage}}</div>