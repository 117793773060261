import { Component, Input, OnInit } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-practices-ctag',
  templateUrl: './practices-ctag.component.html',
  styleUrls: ['./practices-ctag.component.css']
})
export class PracticesCtagComponent implements OnInit {
  @Input() data;
  @Input() practices: any;
  @Input() displayNumber = 7;
  displayPracticeNumber = 0;
  moreMember = 0;

  constructor(private modalService: ModalPopupService, private commonService: CommonMethodsService,) { }

  ngOnInit(): void {
    if (this.data) {
      this.practices = this.data['practices'];
      this.displayNumber = this.data['displayNumber'];
    }
    this.displayPracticeNumber = this.commonService.getDisplayCount(this.practices.length, this.displayNumber);
    this.moreMember = this.practices.length - this.displayPracticeNumber;
  }

  open() {
    const data = {
      title: $localize`Practices Partnership`, message: '', component: 'PracticesCtagComponent',
      data: { practices: this.practices, displayNumber: this.practices?.length }
    };
    this.modalService.open(data);
  }

}
