import { Component, Renderer2, OnDestroy, OnInit, NgZone } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { Router, ActivatedRoute } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';
import { timer, Subscription } from 'rxjs';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { DriveService } from 'app/services/drive/drive.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  invalidLogin = false;
  error: any;
  timerSubscription = new Subscription();
  cms = '';
  // thirdPartyCookieEnabled = true;
  cookieDisableError = false;
  apiUrl = environment.apiUrl;

  constructor(private router: Router, private renderer: Renderer2,
    private loginService: LoginService, private route: ActivatedRoute, private jwtHelper: JwtHelperService,
    private commonService: CommonMethodsService, private zone: NgZone, private driveService: DriveService) {
    this.commonService.showFooter = false;
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'bg');

    this.timerSubscription = timer(100).subscribe(() => {
      const accessToken = localStorage.getItem('accessToken');
      if (!this.jwtHelper.isTokenExpired(accessToken)) {
        let destination = this.route.snapshot.queryParamMap.get('destination');
        destination = ((destination || '').indexOf('login') > -1) ? '/' : destination;
        this.renderer.removeClass(document.body, 'bg');
        this.router.navigateByUrl(destination || '/');
      } else {
        this.invalidLogin = !!localStorage.getItem('error');
      }
      localStorage.removeItem('error');
    });
  }

  duoLogin() {
    let destination = this.route.snapshot.queryParamMap.get('destination');
    destination = ((destination || '').indexOf('login') > -1) ? '' : destination;
    if (destination) {
      localStorage.setItem('destination', destination);
    }
    if ($localize.locale) {
      localStorage.setItem('locale', $localize.locale);
    }
    localStorage.setItem('loginSource', 'practice');
    window.open(this.apiUrl + '/v1/login/sso', '_self');
  }

  // socialSignIn(socialPlatform: string) {
  //   let socialPlatformProvider;
  //   if (socialPlatform === 'google') {
  //     socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
  //   }
  //   this.cookieDisableError = false;
  //   this.socialAuthService.signIn(socialPlatformProvider).then(
  //     (userData) => {
  //       if (userData) {
  //         this.signIn(userData);
  //       }
  //     }
  //   ).catch(e => {
  //     if (e.error === 'popup_closed_by_user' && !this.thirdPartyCookieEnabled) {
  //       this.cookieDisableError = true;
  //     }
  //   });;
  // }

  // signIn(credentials) {
  //   this.loginService.login(credentials)
  //     .subscribe(result => {
  //   if (credentials) {
  //     localStorage.setItem('refreshToken', credentials);
  //     this.loginService.getToken()
  //       .subscribe(response => {
  //         localStorage.setItem('accessToken', response['jwtToken']);
  //         localStorage.setItem('cmsToken', response['cmsJwtToken']);
  //         this.loginService.currentUser = this.jwtHelper.decodeToken(response['jwtToken']);
  //         const destination = this.route.snapshot.queryParamMap.get('destination');
  //         this.driveService.checkDriveAccess();
  //         this.renderer.removeClass(document.body, 'bg');
  //         this.router.navigateByUrl(destination || '/');
  //       });
  //   } else {
  //     this.invalidLogin = true;
  //   }
  //   },
  //     error => {
  //       this.error = error; // error path
  //       this.invalidLogin = true;
  //       this.loginService.clear();
  //     }
  //   );
  // }

  openPopup() {
    this.loginService.cookieAlertClosed = false;
  }

  // checkThirdPartyCookie() {
  //   window.addEventListener("message", event => {
  //     if (event.data === 'MM:3PCunsupported') {
  //       this.thirdPartyCookieEnabled = false;
  //     } else if (event.data === 'MM:3PCsupported') {
  //       this.thirdPartyCookieEnabled = true;
  //     }
  //   }, false);
  // }

  onMouseleave(event, t) {
    this.commonService.closeTooltip(event, t, '.login-cookie-tooltip');
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
    this.commonService.showFooter = true;
    this.renderer.removeClass(document.body, 'bg');
  }
}
