<section class="filter-section" [formGroup]="group">
  <div class="filter-name">
    {{field.label}}
    <span class="chevron-up">
      <i class="fas fa-chevron-up"></i>
    </span>
    <span class="chevron-down">
      <i class="fas fa-chevron-down"></i>
    </span>
  </div>

  <ul class="filter_list" [ngClass]="{'open':field.name==='practices'}" class="{{'facet-'+field.name}}"
    [perfectScrollbar]="config" ngbPanelContent>
    <li [formArrayName]="field.name" *ngFor="let item of field.options; let i = index;" (click)="onSelect(field.name)">
      <div *ngIf="item.first" class="option-group">{{item.groupBy}}</div>
      <label [ngClass]="{'option-item':item.groupBy}">
        <input type="checkbox" [formControlName]="i">
        <span [innerHTML]="item.name"></span>
        <!-- {{item.name}} -->
      </label>
    </li>
  </ul>
</section>