import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvisoriesService } from 'app/services/advisories/advisories.service';
import { Subscription } from 'rxjs';
import { ScrollService } from 'app/services/scroll/scroll.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() data: any;
  disableBtnOk = false;
  showBtnCancel = true;
  btnOkClass = 'btn-primary';
  btnCancelClass = 'btn-light';
  showHeader = true;
  termsAcceptedEventSubscribtion = new Subscription();
  scrollEvent = new Subscription();
  classes = '';

  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, scrollYMarginOffset: 10 };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public activeModal: NgbActiveModal, private advisoryService: AdvisoriesService, private scrollService: ScrollService) { }

  ngOnInit() {
    this.disableBtnOk = this.data.disableBtnOk || false;
    this.showBtnCancel = this.data.showBtnCancel !== undefined ? this.data.showBtnCancel : true;
    this.showHeader = this.data.showHeader !== undefined ? this.data.showHeader : true;
    this.classes = this.data.classes !== undefined ? this.data.classes : '';
    this.btnOkClass = this.disableBtnOk ? 'btn-disabled' : this.data.btnOkClass || this.btnOkClass;
    this.btnCancelClass = this.data.btnCancelClass || this.btnCancelClass;

    this.termsAcceptedEventSubscribtion = this.advisoryService.termsAcceptedEvent
      .subscribe(result => {
        this.disableBtnOk = !result;
        this.btnOkClass = this.disableBtnOk ? 'btn-disabled' : this.data.btnOkClass || 'btn-primary';
      });

    this.scrollEvent = this.scrollService.scrollEvent
      .subscribe(response => {
        if (response['event'] === 'update') {
          this.directiveRef.update();
        }
      });

    setTimeout(() => {
      this.directiveRef.update();
    }, 1000);
  }

  public cancel() {
    this.activeModal.close(false);
  }

  public accept(id) {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  psYReachEnd() {
    this.scrollService.scrollEvent.emit({ event: 'complete' });
  }

  ngOnDestroy() {
    this.termsAcceptedEventSubscribtion.unsubscribe();
    this.scrollEvent.unsubscribe();
  }
}
