<div class="container-fluid header" [class]="getHeaderClass()" [ngClass]="{'position-absolute':introRunning}" id="top"
  *ngIf="loginService.currentUser && !cms">
  <app-header></app-header>

  <app-filters *ngIf="filterService.showFilter">
  </app-filters>
  <!-- <app-back-button *ngIf="showBackButton"></app-back-button> -->
  <!-- condition removed because button not visible for first time page view-->
  <app-back-button></app-back-button>
</div>

<div class="global-container container-margin" [ngClass]="filterService.class + class">
  <ng-container *ngIf="loginService.currentUser && !cms">
    <!-- page breadcrumb -->
    <div class="breadcrumb-flex">
      <app-breadcrumb *ngIf="commonService.showBreadcrumb" class="breadcrumb-container"></app-breadcrumb>
      <div class="p-actions" (clickOutside)="showActions = false;showNotifications = false"
        *ngIf="commonService.notifications.length || commonService.actions.length">
        <div class="position-relative p-notifications" *ngIf="commonService.notifications.length">
          <a href="javascript:;" class="circle-actions mt-5"
            (click)="showNotifications = !showNotifications;showActions = false;">
            <i class="far fa-bell"></i>
          </a>
          <div class="badge" *ngIf="commonService.notifications.length;">
            {{commonService.notifications.length}}
          </div>
          <div class="p-notifications-list" [perfectScrollbar]="config" [hidden]="!showNotifications">
            <div class="notifications-row" *ngFor="let notification of commonService.notifications">
              {{notification.label}}
              <span class="date disp-block">{{notification.created_at| timeago:'live'}}</span>
              <div class="notifications-row-action">
                <a [routerLink]="notification.uri" [queryParams]="notification.params" class="btn btn-review btn-sm"
                  i18n>REVIEW</a>
              </div>
            </div>
          </div>
        </div>

        <div class="position-relative ml-10 p-more-actions" *ngIf="commonService.actions.length">
          <a href="javascript:;" class="circle-actions mt-5"
            (click)="showActions = !showActions;showNotifications = false;">
            <i class="fas fa-ellipsis-h"></i>
          </a>
          <div class="p-more-actions-list" [perfectScrollbar]="config" [hidden]="!showActions">
            <ng-container *ngFor="let action of commonService.actions">
              <a [href]="action.uri" *ngIf="action.external;else internal" target="_blank">{{action.label}}</a>
              <ng-template #internal>
                <a [routerLink]="action.uri" *ngIf="action.task != 'deletePractice'; else delete"
                  [queryParams]="action.params" [fragment]="action.fragment">{{action.label}}</a>
              </ng-template>
              <ng-template #delete>
                <a (click)="deletePractice(action.params)">{{action.label}}</a>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- filters chips-->
    <app-chips *ngIf="filterService.showFilter"></app-chips>
  </ng-container>

  <!-- router-outlet to show page -->
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<ng-container *ngIf="loginService.currentUser && !cms">
  <app-footer></app-footer>
</ng-container>

<div class="ajax-loading" *ngIf="commonService.showLoader && !cms">
  <span>
    <p id="please_wait"><strong i18n>Please wait..</strong></p>
    <p><i class="fa fa-spinner fa-spin fa-lg" aria-hidden="true"></i></p>
  </span>
</div>

<div class="ajax-loading file-progress-loader" *ngIf="commonService.showFileLoader && !cms">
  <span class="width-30">
    <ng-container *ngIf="cloudPickerService.uploadProgress == 0;else progress">
      <p id="please_wait">
        <strong>Preparing for Upload.</strong>
      </p>
      <p class="font-size-12 mb-10">It may take sometime based on the file size and internet speed.</p>
      <div class="indeterminate-progress-bar">
        <div class="indeterminate-progress-bar__progress"></div>
      </div>
    </ng-container>

    <ng-template #progress>
      <p id="please_wait" class="mb-10">
        <strong>Uploading ...</strong>
      </p>
      <div class="progress-bar">
        <div class="progress-bar__progress " [style.width.%]="cloudPickerService.uploadProgress || 0">
          {{cloudPickerService.uploadProgress || 0}}%
        </div>
      </div>
    </ng-template>
  </span>
</div>

<!-- call third party cookie url-->
<iframe src="https://mindmup.github.io/3rdpartycookiecheck/start.html" style="display:none"></iframe>

<app-cookie-alert></app-cookie-alert>