import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InitiativesService } from 'app/services/initiatives/initiatives.service';
import * as lodash from 'lodash';
import { FilterService } from 'app/services/filter/filter.service';
import { isEqual } from 'lodash';
import { Options } from 'select2';

@Component({
  selector: 'app-initiative-options',
  templateUrl: './initiative-options.component.html',
  styleUrls: ['./initiative-options.component.css']
})
export class InitiativeOptionsComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() value = [];
  @Input() controlName = '';
  @Input() helpText = '';
  @Input() submitted = false;
  @Input() multiple = false;
  @Input() optional = false;
  @Input() label = $localize`Initiative`;
  @Input() entityCategoryId = 0;
  placeholder = $localize`Select initiative`;
  initiatives = [{ id: '', text: '' }];
  options: Options;
  selectedInitiatives = [];
  touched = false;
  initiativeList = [];
  loaded = false;
  htmlId = 'initiative-options';
  values: string[];
  filters = [];

  constructor(private initiativesService: InitiativesService, private filterService: FilterService) { }

  ngOnInit(): void {
    if (this.multiple && this.label === 'Initiative') {
      this.placeholder = $localize`Select initiatives`;
      this.label = $localize`Initiatives`;
    }

    let filter = [{ key: 'filters[categories][]', values: [this.entityCategoryId] }];
    filter = filter.concat(this.filters);
    // get api params
    const params = this.filterService.getApiParams(filter);

    this.initiativesService.getInitiativeTypes(params)
      .subscribe(response => {
        this.initiativeList = response['initiative_types'].filter(t => !t.is_obsolete);
        this.setValue();
      });
    this.options = {
      placeholder: this.placeholder,
      multiple: this.multiple
    };
    if (!this.value) {
      this.value = [];
    }
  }

  setOption() {
    this.initiatives = this.initiativeList.map(initiative => ({ id: initiative.id, text: initiative.name }));
  }

  setValue() {
    this.setOption();
    if (this.value && this.value.length) {
      // this.onSelect({ data: this.value.map(initiative => ({ id: initiative.id, text: initiative.name })) });
      // if (this.multiple) {
      this.values = this.value.map(v => v.id.toString());
      // } else {
      //   this.values = this.value ? this.value[0]?.id : null;
      // }
    }
  }

  valueChanged(event) {
    if (event) {
      this.onSelect({
        data: this.initiatives.filter(o =>
          Array.isArray(event) ? event.includes(o.id.toString()) : event === o.id.toString())
      });
    }
  }

  onSelect(event) {
    let initiatives = event.data.map(initiative => +initiative.id)
      .filter(id => id);
    if (initiatives.length) {
      this.touched = true;
      if (this.multiple) {
        initiatives = lodash.uniq(initiatives.concat(this.form.get(this.controlName).value || []));
      }
      this.selectedInitiatives = this.initiatives.filter(initiative => initiatives.includes(initiative.id));
      if (!isEqual(initiatives, this.value.map(p => p.id))) {
        this.form.get(this.controlName).markAsDirty();
      }
      this.form.get(this.controlName).setValue(initiatives);
      // $(`#${this.htmlId}`).val(null);
      if (this.multiple && this.selectedInitiatives.length) {
        const input = document.querySelector(`.${this.htmlId} .select2-search__field`) as HTMLInputElement
        input.placeholder = this.placeholder;
        input.style.width = 'auto';
      }
    }
  }

  remove(id) {
    this.touched = true;
    const initiatives = this.form.get(this.controlName).value.filter(initiative => initiative !== id);
    this.selectedInitiatives = this.initiatives.filter(initiative => initiatives.includes(initiative.id));
    if (!isEqual(initiatives, this.value.map(p => p.id))) {
      this.form.get(this.controlName).markAsDirty();
    }
    this.form.get(this.controlName).setValue(initiatives);
    this.values = this.selectedInitiatives.map(l => l.id.toString())
  }

}
