import { Directive, ViewContainerRef, Input, OnInit, ComponentFactoryResolver } from '@angular/core';
import { ModalComponent } from './../shared/components/modal/modal.component';
import { NuggetCardComponent } from './../shared/components/nugget-card/nugget-card.component';
// import { CustomerCardComponent } from './../shared/components/customer-card/customer-card.component';
import { EventCardComponent } from './../shared/components/event-card/event-card.component';
import { EventInfoComponent } from './../shared/components/event-info/event-info.component';
import { BlogCardComponent } from './../shared/components/blog-card/blog-card.component';
import { SkillsCardComponent } from './../shared/components/skills-card/skills-card.component';
import { PracticesChipComponent } from './../shared/components/practices-chip/practices-chip.component';
import { TechnologiesChipComponent } from './../shared/components/technologies-chip/technologies-chip.component';
import { MembersListComponent } from './../shared/components/members-list/members-list.component';
import { PersonCardComponent } from './../shared/components/person-card/person-card.component';
import {
  AdvisoryRequestAccessDialogComponent
} from './../components/advisories/advisory-request-access-dialog/advisory-request-access-dialog.component';
import { GloLoginComponent } from './../shared/components/glo-login/glo-login.component';
import { TagActionsComponent } from './../components/tags/tag-actions/tag-actions.component';
import { EditCcLocationComponent } from './../shared/components/edit-cc-location/edit-cc-location.component';
import { MoveFromCoreComponent } from './../shared/components/move-from-core/move-from-core.component';
import { OtherSkillsComponent } from './../shared/components/other-skills/other-skills.component';
import { JoinPracticeComponent } from './../shared/components/join-practice/join-practice.component';
import { LeavePracticeComponent } from './../shared/components/leave-practice/leave-practice.component';
import { NuggetCardLoaderComponent } from './../shared/components/loaders/nugget-card-loader/nugget-card-loader.component';
import { CustomerCardLoaderComponent } from './../shared/components/loaders/customer-card-loader/customer-card-loader.component';
import { BlogCardLoaderComponent } from './../shared/components/loaders/blog-card-loader/blog-card-loader.component';
import { CaseStudyCardComponent } from './../shared/components/case-study-card/case-study-card.component';
import { DeckCardComponent } from './../components/practices/deck-card/deck-card.component';
import { CuratedContentCardComponent } from './../modules/feature/practice/curated-content-card/curated-content-card.component';
import { ToolAcceleratorCardComponent } from './../modules/feature/practice/tools-accelerators/tool-accelerator-card/tool-accelerator-card.component';
import { RecommendationCardComponent } from './../components/practices/recommendation-card/recommendation-card.component';
import { PageListComponent } from 'app/shared/components/page-list/page-list.component';
import { LikedByComponent } from 'app/shared/components/liked-by/liked-by.component';
import { AssignToComponent } from 'app/shared/components/assign-to/assign-to.component';
import { RecommendedNuggetCardComponent } from 'app/shared/components/recommended-nugget-card/recommended-nugget-card.component';
import { PersonPracticesComponent } from 'app/shared/components/person-practices/person-practices.component';
import { SubmitImportantLinkComponent } from 'app/components/submit-important-link/submit-important-link.component';
import { SupportedLanguageComponent } from 'app/shared/components/supported-language/supported-language.component';
// import { LeadersChipComponent } from 'app/shared/components/leaders-chip/leaders-chip.component';
import { ReportViewComponent } from 'app/shared/components/report-view/report-view.component';
import { SubmitDocumentComponent } from 'app/components/documents/submit-document/submit-document.component';
import { MemberCardComponent } from 'app/shared/components/member-card/member-card.component';
import { CloudPickerComponent } from 'app/shared/components/fields/cloud-picker/cloud-picker.component';
import { LocationsListComponent } from 'app/shared/components/locations-list/locations-list.component';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';
import { IndustriesChipComponent } from 'app/shared/components/industries-chip/industries-chip.component';
import { ChipsComponent } from 'app/shared/components/filters/chips/chips.component';
import { FacetChipsComponent } from 'app/shared/components/facet-chips/facet-chips.component';
import { PracticesCtagComponent } from 'app/shared/components/practices-ctag/practices-ctag.component';
import { PartnershipCardComponent } from 'app/shared/components/partnership-card/partnership-card.component';
import { AddInCollectionComponent } from 'app/shared/components/add-in-collection/add-in-collection.component';
import { SharedPersonComponent } from 'app/components/shared-person/shared-person.component';

const componentMapper = {
  ModalComponent,
  NuggetCardComponent,
  // CustomerCardComponent,
  EventCardComponent,
  EventInfoComponent,
  SkillsCardComponent,
  PracticesChipComponent,
  TechnologiesChipComponent,
  BlogCardComponent,
  MembersListComponent,
  PersonCardComponent,
  AdvisoryRequestAccessDialogComponent,
  GloLoginComponent,
  TagActionsComponent,
  EditCcLocationComponent,
  MoveFromCoreComponent,
  OtherSkillsComponent,
  JoinPracticeComponent,
  LeavePracticeComponent,
  NuggetCardLoaderComponent,
  CustomerCardLoaderComponent,
  BlogCardLoaderComponent,
  CaseStudyCardComponent,
  DeckCardComponent,
  CuratedContentCardComponent,
  ToolAcceleratorCardComponent,
  RecommendationCardComponent,
  PageListComponent,
  LikedByComponent,
  AssignToComponent,
  RecommendedNuggetCardComponent,
  PersonPracticesComponent,
  SubmitImportantLinkComponent,
  SupportedLanguageComponent,
  // LeadersChipComponent,
  ReportViewComponent,
  SubmitDocumentComponent,
  MemberCardComponent,
  CloudPickerComponent,
  LocationsListComponent,
  ContactUsComponent,
  IndustriesChipComponent,
  ChipsComponent,
  FacetChipsComponent,
  PracticesCtagComponent,
  PartnershipCardComponent,
  AddInCollectionComponent,
  SharedPersonComponent,
};

@Directive({
  selector: '[appHostComponent]'
})
export class HostComponentDirective implements OnInit {
  @Input() data: any;
  @Input() classes: string;
  @Input() type = '';
  @Input() component: string;
  @Input() displayPractices = true;
  @Input() showSortIcons: boolean;
  @Input() previewAccess: boolean = true;

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentMapper[this.component]);
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    (componentRef.instance)['data'] = this.data;
    (componentRef.instance)['type'] = this.type;
    (componentRef.instance)['classes'] = this.classes;
    (componentRef.instance)['displayPractices'] = this.displayPractices;
    (componentRef.instance)['showSortIcons'] = this.showSortIcons;
    (componentRef.instance)['previewAccess'] = this.previewAccess;
  }

}
