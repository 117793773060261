import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { NuggetsService } from 'app/services/nuggets/nuggets.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nugget-report-count',
  templateUrl: './nugget-report-count.component.html',
  styleUrls: ['./nugget-report-count.component.css']
})
export class NuggetReportCountComponent implements OnInit, OnDestroy {
  @Input() entityId;
  @Input() entityCategoryId;
  @Input() editAccess;
  reports = [];
  type = '';
  reportNuggetEvent: Subscription;

  constructor(private nuggetService: NuggetsService, private filterService: FilterService, private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.entityCategoryId === 3) {
      this.type = $localize`Tech Document`;
    } else if (this.entityCategoryId === 2) {
      this.type = $localize`Tech Talk`;
    } else if (this.entityCategoryId === 5) {
      this.type = $localize`Blog`;
    } else if (this.entityCategoryId === 4) {
      this.type = $localize`Innovation`;
    } else if (this.entityCategoryId === 6) {
      this.type = $localize`Case Study`;
    }
    this.getFlag();

    this.reportNuggetEvent = this.nuggetService.reportNuggetEvent.subscribe(response => {
      this.getFlag();
    });
  }

  getFlag() {
    const filter = [
      { key: 'perPage', values: [0] },
      { key: 'entity_category_id', values: [this.entityCategoryId] }
    ];
    const params = this.filterService.getApiParams(filter);
    this.nuggetService.getFlags(this.entityId, params)
      .subscribe(response => {
        this.reports = response['flags'];
        if (response['current_user_flag']) {
          this.reports.push(response['current_user_flag']);
        }
      })
  }

  open() {
    const data = {
      title: $localize`Report ${this.type}`, message: '', component: 'ReportViewComponent',
      data: { id: this.entityId, cid: this.entityCategoryId, type: this.type, editAccess: this.editAccess }
    };
    this.modalService.open(data);
  }

  ngOnDestroy(): void {
    this.reportNuggetEvent.unsubscribe();
  }
}
