import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-blog-card-loader',
  templateUrl: './blog-card-loader.component.html',
  styleUrls: ['./blog-card-loader.component.css']
})
export class BlogCardLoaderComponent implements OnInit {
  @Input() classes = 'nugget-item-3-col';

  constructor() { }

  ngOnInit() {
  }

}
