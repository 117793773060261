import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopPropagation]'
})
export class StopPropagationDirective {

  constructor() { }

  @HostListener('click', ['$event'])
  public onClick(event: any): void {
    $($('[data-toggle="slide-collapse"]').data('target')).animate({ 'width': 'hide' }, 350);
    // event.preventDefault();
    event.stopPropagation();
  }
}
