<div class="nugget-item nugget-item-3-col pad-0 border-none event-card" (click)="open()"
  [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')', backgroundSize: 'cover'}"
  (mouseenter)="showPreviewIcon=true" (mouseleave)="showPreviewIcon=false">
  <div class="nugget-detail-preview">
    <app-change-nugget-preview [practice]="data.practices[0]" [entityId]="data.id" entityCategory="event"
      [previewUrl]="data.preview_url" *ngIf="isAccess && showPreviewIcon"></app-change-nugget-preview>
    <div class="nugget_subject nugget_subject-blog cursor-pointer text-white truncate-2">
      <span [title]="data['title']">
        {{data.title}}
      </span>
    </div>
    <div class="date mt-5 mb-10 text-white"> {{data.activity_date | date:'mediumDate':'GMT'}}</div>
    <div class="customer-act-body text-white truncate-2" [innerHTML]="data.description| truncate:250"></div>
    <div class="row mt-15 mb-15">
      <div class="col height-40">
        <app-members-chip [members]="getMembers()"></app-members-chip>
      </div>
    </div>
    <div class="divider"></div>
    <app-practices-chip [practices]="data.practices"></app-practices-chip>

    <!-- Edit/Delete Event -->
    <div class="delete" *ngIf="canEditDelete()" appStopPropagation>
      <a [routerLink]="['/events', data['id'],'edit']" class="btn btn-primary btn-sm mr-10" i18n>EDIT</a>
      <button type="button" class="btn btn-link btn-sm" (click)="deleteEvent(data['id'], data['title'])"
        i18n>DELETE</button>
    </div>
  </div>
  <!-- End of Edit/Delete Event -->
</div>