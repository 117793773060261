<div class="MultiCarousel" #mc [appMatchHeight]="matchHeight">
  <div class="MultiCarousel-inner" #mcInner [ngStyle]="{'transform': 'translateX(0px)'}"
    [style.width.px]="mcInnerwidth">
    <ng-container *ngIf="sortable;else normal;">
      <ng-template #itemTemplate let-item="item" let-index="index">
        <div class="item" [style.width.px]="itemWidth">
          <ng-template appHostComponent [data]="item.initData" [component]="component" [type]="type"
            [displayPractices]="displayPractices" [classes]="classes">
          </ng-template>
        </div>
      </ng-template>
      <bs-sortable [ngModel]="items" [itemTemplate]="itemTemplate" itemClass="sortable-item"
        itemActiveClass="sortable-item-active" placeholderClass="placeholderStyle" wrapperClass="sortable-wrapper"
        (onChange)="listOrderChanged($event)"></bs-sortable>
    </ng-container>

    <ng-template #normal>
      <div class="item" [style.width.px]="itemWidth" *ngFor="let item of items">
        <ng-template appHostComponent [data]="item" [component]="component" [type]="type" [classes]="classes"
          [displayPractices]="displayPractices" [showSortIcons]="items.length > 1">
        </ng-template>
      </div>
    </ng-template>
  </div>
  <span class="btn btn-primary leftLst" #left (click)="resCarousel(0)" [hidden]="!showButton"
    appStopPropagation>&lt;</span>
  <span class="btn btn-primary rightLst" #right (click)="resCarousel(1)" [hidden]="!showButton"
    appStopPropagation>&gt;</span>
</div>
