import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as lodash from 'lodash';
import { TitleCasePipe } from '@angular/common';

import { environment } from 'environments/environment';
import { Cacheable } from 'ts-cacheable';
import { flatten } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  personDropdownUpdate = new EventEmitter();
  searchPeopleEvent = new EventEmitter();
  searchAsFilter = false;

  apiUrl = environment.apiUrl;
  constructor(private http: HttpClient, private titlecasePipe: TitleCasePipe) { }

  // get people list
  getPeople(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/people', { params, headers });
  }

  // get team list
  getTeam(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/people/team', { params, headers });
  }

  // get person info
  getPersonInfo(id: any, data?: string, params?) {
    const url = this.apiUrl + '/v1/people/' + id + (data ? '/' + data : '');
    return this.http.get(url, { params });
  }

  @Cacheable()
  getMemberType() {
    return this.http.get(this.apiUrl + '/v1/member-types');
  }

  getPersonName(person) {
    if (!person || !person['name']) {
      return '';
    }
    return this.titlecasePipe.transform(((person['name']['first_name'] || '') + ' ' + (person['name']['last_name'] || '')).trim());
  }

  getPersonTitle(person) {
    if (!person) {
      return '';
    }
    // return person['role'] || (person['title'] || '').replace(',', ', ') || person['designation'];
    return (person['role'] || person['title'] || person['designation'] || '').replace(',', ', ');
  }

  getNuggetMembers(nugget) {
    let members = nugget.authors;
    members = nugget.lead ? [nugget.lead].concat(members) : members;
    return lodash.uniqBy(members, 'person_id');
  }

  // search people
  searchPeople(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/people/search', { params, headers });
  }

  getScheduleTypes() {
    return this.http.get(this.apiUrl + '/v1/schedule-types');
  }

  getSubscriptions() {
    return this.http.get(this.apiUrl + '/v1/subscriptions');
  }

  updateSubscriptions(data) {
    return this.http.put(this.apiUrl + '/v1/subscriptions', data);
  }

  updateWhatsappNumber(data) {
    return this.http.put(this.apiUrl + '/v1/register-whatsapp', data);
  }

  getPersonCCLocations(person, ids?) {
    let practices = person.practices || [];
    if (ids) {
      practices = practices.filter(practice => ids.includes(practice.id));
    }
    let locations = [];
    if (practices.length) {
      locations = flatten(practices.map(practice => {
        return practice.memberships.filter(membership => membership.c_name === 'center_coordinator')
          .map(membership => membership.center);
      }));
    }
    return locations;
  }

  getSubscribedPractices(id: any) {
    return this.http.get(this.apiUrl + `/v1/people/${id}/subscribed-practices`);
  }

  getActivities(params, id) {
    return this.http.get(this.apiUrl + `/v1/people/${id}/activities`, {params});
  }
}

export interface People {
  people: [];
  total_count: number;
}
