import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

import { LoginService } from 'app/services/auth/login.service';
import { BlogsService } from 'app/services/blogs/blogs.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { CloudPickerService } from "../../../services/cloud-picker/cloud-picker.service";
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.css']
})
export class BlogCardComponent implements OnInit {
  @Input() previewAccess: boolean = true;
  @Input() data;
  @Input() classes = '';
  @Input() displayPractices = true;
  editLink = '';
  isAuthor = false;
  deleteAccess = false;
  adminAccess = false;
  isAccess = false;
  showPreviewIcon = false;

  constructor(private loginService: LoginService, private blogService: BlogsService, private modalService: ModalPopupService, private cloudPickerService: CloudPickerService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    if (this.data.authors && this.data.authors[0]) {
      this.data['author'] = this.data.authors[0];
    }

    this.editLink = `${environment.gloUrl}communities/${this.data.community.glo_community_id}/posts/${this.data.glo_post_id}/edit`;
    if (this.data.author && this.data.author.contact.email_id === this.loginService.currentUser.user.email) {
      this.isAuthor = true;
    } else {
      this.isAuthor = false;
    }
    this.canDelete();
    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId && this.data.search_type == response.entityCategory) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })

    let practices = [];
    if (this.data.practices && this.data.practices.length > 0) {
      practices = this.data.practices.map(practice => practice.id);
    }

    let locations = [];
    if (this.data.locations && this.data.locations.length > 0) {
      locations = this.data.locations.map(location => location.city);
    }

    if (this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices)) {
      this.adminAccess = true;
    } else {
      this.adminAccess = false;
    }

    if (this.data.author && this.data.author.contact.email_id === this.loginService.currentUser.user.email || this.data.created_by === this.loginService.currentUser.user.email || this.adminAccess ||
      this.loginService.isPrivilegedUser()) {
      this.isAccess = true;
    } else {
      this.isAccess = false;
    }
  }

  canDelete() {
    let practices = [];

    if (this.data.practices && this.data.practices.length > 0) {
      practices = this.data.practices.map(practice => practice.id);
    }

    let locations = [];
    if (this.data.locations && this.data.locations.length > 0) {
      locations = this.data.locations.map(location => location.city);
    }
    this.deleteAccess = (this.loginService.isPrivilegedUser(practices) || this.loginService.isCenterCoordinator(locations, practices));
  }

  deleteBlog(event, id: number, title: string) {
    event.preventDefault();
    const data = {
      title: $localize`Delete Blog`, message: $localize`Are you sure you want to delete ${title} blog?
      <br><br><div class="help mb-0">Note: This blog will be deleted from practice portal. It will not make any impact on glo post.</div>`,
      size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.blogService.deleteBlogConfirmEvent.emit();
          this.blogService.deleteBlog(id)
            .subscribe(() => {
              const headers = new HttpHeaders({
                'Cache-Control': 'no-cache'
              });
              this.blogService.deleteBlogEvent.emit(headers);
            });
        }
      })
      .catch(() => { });
  }

  removeBlog(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'blog', action: 'removeItem' });
  }

  // open(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const previewData = { practice: this.data.practice, entityId: this.data.id, entityCategory: this.data.search_type, previewUrl: this.data.preview_url };
  //   const data = { title: "Change Preview", message: '', component: 'CloudPickerComponent', data: previewData, size: 'lg' };
  //   this.modalService.open(data);
  // }

}
