<label for="{{htmlId}}" *ngIf="options.length">
  {{ label }}
  <span class="help" *ngIf="optional" i18n>(Optional)</span>
  <div class="help" *ngIf="helpText">{{helpText}}</div>
</label>

<ng-select id="{{htmlId}}" bindValue="id" [items]="options" [multiple]="multiple"
  [disabled]="disabled" [searchable]="false" [selectableGroupAsModel]="false" [placeholder]="placeholder"
  *ngIf="options.length" groupBy="parent" [closeOnSelect]="!multiple" [(ngModel)]="selectedPractices"
  [hideSelected]="true" (change)="valueChange($event)">
  <ng-template ng-optgroup-tmp let-item="item">
    {{ item.parent }}
  </ng-template>
  <ng-template ng-multi-label-tmp>
    <span style="display:block; top:10px" class="ng-placeholder">{{placeholder}}</span>
  </ng-template>
</ng-select>

<div class="search-tag-wrapper row" *ngIf="multiple">
  <span class="search-tag" *ngFor="let practice of showSelectedPractices">{{practice.label}}<a
      (click)="remove(practice.id)">X</a></span>
</div>