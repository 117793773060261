import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigitalAcceleratorsService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getAccelerators(params?) {
    return this.http.get(this.apiUrl + '/v1/digital-accelerators/catalogs', { params });
  }

  getAcceleratorInfo(id) {
    return this.http.get(this.apiUrl + `/v1/digital-accelerators/catalogs/${id}`);
  }

  submitAccelerator(data) {
    return this.http.post(this.apiUrl + '/v1/digital-accelerators', data);
  }

  updateAccelerator(id, data) {
    return this.http.put(this.apiUrl + `/v1/digital-accelerators/${id}`, data);
  }

  deleteAccelerator(id) {
    return this.http.delete(this.apiUrl + `/v1/digital-accelerators/${id}`);
  }

  checkName(data) {
    return this.http.post(this.apiUrl + '/v1/digital-accelerators/valid', data);
  }

  updatePosition(data) {
    return this.http.post(this.apiUrl + `/v1/digital-accelerators/position`, data);
  }

  getMarketPlaces() {
    return this.http.get(this.apiUrl + `/v1/market-places`);
  }

  getIndustries() {
    return this.http.get(this.apiUrl + `/v1/industries`);
  }

  getAcceleratorTypes(params) {
    return this.http.get(this.apiUrl + `/v1/entity-categories`, { params });
  }

  getProviders() {
    return this.http.get(this.apiUrl + `/v1/providers`);
  }

  getCurrencies() {
    return this.http.get(this.apiUrl + `/v1/currencies`);
  }

  daSearch(params) {
    return this.http.get(this.apiUrl + '/v1/digital-accelerators/search', { params });
  }
}


export interface Accelerator {
  digital_accelerators: [];
  total_count: number;
}
