<div>
  <img [src]="data['activity']['preview_url']">
  <div class="advisory-card-body">
    <div class="advisory-title" *ngIf="data['client']">
      <a [routerLink]="['/customer-activities',data['activity']['id']]" class="truncate-2"
        [title]="data['client']['name']">{{data['client']['name']}}</a>
    </div>
    <div class="title height-50" *ngIf="data['activity']">
      <a [routerLink]="['/customer-activities',data['activity']['id']]" class="truncate-2"
        [title]="data['activity']['title']"> {{data['activity']['title']}}</a>
    </div>
    <div class="date">{{data['activity']['activity_date'] | date:'mediumDate':'GMT'}}</div>
    <p *ngIf="data['activity']" [innerHTML]="data['activity']['description'] | truncate : 250" class="truncate-2"></p>

    <div class="sub-title">Advisories</div>
    <ul class="advisories-list">
      <li *ngFor="let advisory of data['advisories'] | slice:0:2" (click)="checkAccess(advisory['id'])">
        <span>
          <div class="lock">
            <i class="fas fa-lock"></i>
          </div>
          <div class="advisory-content">
            <label class="truncate-2">{{advisory['title']}}</label>
            <a [routerLink]="['/team',advisory['owner']['login']]"
              *ngIf="advisory['owner'] && advisory['owner']['login'];else name;" appStopPropagation
              class="truncate-2">{{getUserName(advisory['owner'])}}</a>
            <ng-template #name> <label class="truncate-2">Horizontal Practices</label></ng-template>
          </div>
        </span>
      </li>

      <li class="last" *ngIf="data['advisories'] && data['advisories'].length > 2">
        <a [routerLink]="['/customer-activities',data['activity']['id']]">
          +{{data['advisories'].length - 2}}
        </a>
      </li>
    </ul>
  </div>
</div>