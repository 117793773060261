import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit {
  @Input() src;
  @Input() height;
  @Input() width = '100%';

  methodLogin = false;
  constructor(private loginService: LoginService) { }

  ngOnInit() {
    this.methodLogin = this.loginService.isMethodLoggedIn();
  }

}
