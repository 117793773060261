<div class="login-wraper">
  <div class="login-content">
    <img src="assets/images/practices-logo-w.png" alt="Practice Portal" class="mb-20 login-logo">
    <h1 class="login-head" i18n>PORTAL LOGIN</h1>

    <button (click)="duoLogin()" type="button" class="btn btn-primary" i18n>Sign in with Globallogic/Method Email
      Account</button>
    <div *ngIf="invalidLogin" class="alert alert-danger mt-22" i18n>Invalid user and/or domain.</div>

    <!-- <button (click)="socialSignIn('google')" type="submit" class="btn btn-primary"
      *ngIf="thirdPartyCookieEnabled; else loginDisabled">Sign in with Globallogic/Method Email Account</button> -->


    <!-- <ng-template #loginDisabled>
      <ng-template #tooltip>
        <div (mouseleave)="t1.close()">
          Your browser cookies are disabled. Please fix your browser settings to login into the practice portal. Click
          <a href="javascript:;" class="btn-link" (click)="loginService.cookieAlertClosed=false;">
            here</a> to know more.
        </div>
      </ng-template>

      <button type="button" class="btn btn-disabled" [ngbTooltip]="tooltip" tooltipClass='login-cookie-tooltip'
        triggers="manual" [autoClose]="false" #t1="ngbTooltip" (mouseenter)="t1.open()"
        (mouseleave)="onMouseleave($event,t1)">Sign in with
        Globallogic/Method Email Account</button>
    </ng-template> -->

    <!-- <div *ngIf="invalidLogin || cookieDisableError" class="alert alert-danger mt-22">
      <ng-container *ngIf="cookieDisableError;else invalid;">
        Your browser cookies are disabled. Please fix your browser setting and try again. Click <a class="btn-link"
          href="javascript:;" (click)="openPopup()">here</a> to know more.
      </ng-container>
      <ng-template #invalid>Invalid user and/or domain.</ng-template>
    </div> -->
    <div class="or">
      <span></span>
      <legend i18n>or</legend>
      <span></span>
    </div>
    <!-- <div class="explore-practices"><a routerLink="/public/cloud">Explore Globallogic Practices</a></div> -->
    <div class="explore-practices"><a href="https://www.globallogic.com/services/technical-capabilities" target='_blank'
        i18n>Explore Globallogic Practices</a></div>
  </div>
</div>
