import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SpellCheckService } from 'app/services/spell-check/spell-check.service';
import { CustomerActivityService } from 'app/services/customer-activities/customer-activity.service';
import { FormValidatorService } from 'app/services/form-validator/form-validator.service';
import { environment } from 'environments/environment';
import { CommonMethodsService } from 'app/services/util/common-methods.service';

@Component({
  selector: 'app-submit-prospect',
  templateUrl: './submit-prospect.component.html',
  styleUrls: ['./submit-prospect.component.css']
})
export class SubmitProspectComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  invalidForm = false;
  submitted = false;
  submitBtnText = $localize`Submit`;
  logoFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
  logoMimeTypes = `image/png,image/jpeg,image/jpg,image/gif`;
  clientName = '';
  httpError;
  originalValues;

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder,
    public spellCheckService: SpellCheckService, private customerActivityService: CustomerActivityService,
    private formValidator: FormValidatorService, private commonService: CommonMethodsService) { }
  @HostListener('window:beforeunload', ['$event'])
  onClose(event) {
    if (!this.commonService.isEqual(this.originalValues, this.form.value)) {
      event.returnValue = true;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    if (!this.customerActivityService.clientName) {
      this.router.navigate([this.customerActivityService.referrer]);
    }
    this.clientName = this.customerActivityService.clientName;
    this.customerActivityService.clientName = '';
    this.form = this.fb.group({
      name: this.fb.control(this.clientName,
        [this.formValidator.whiteSpaceValidation, Validators.maxLength(250)]),
      logo: this.fb.control(null, []),
      website: this.fb.control('', [this.formValidator.urlValidation, Validators.maxLength(500)]),
      description: this.fb.control('', [this.formValidator.whiteSpaceValidation, Validators.minLength(50), Validators.maxLength(5000)]),
    });
    this.onChanges('description');
    this.originalValues = this.form.value;
    this.form.valueChanges
      .subscribe(value => {
        if (this.form.pristine) {
          this.originalValues = value;
        }
      });
  }

  onSubmit() {
    this.invalidForm = true;
    // check form submitted
    if (!this.submitted) {
      if (this.form.invalid) {
        return false;
      }

      // set title field
      this.form.get('name').setValue(this.clientName);

      // check form spell errors
      if (this.spellCheckService.spellChecked) {
        this.saveData();
      } else {
        this.submitted = true;
        const data = {
          description: this.form.get('description').value.replace(/\r?\n|\r/g, ' ').replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/ig, ' ')
            .replace(/&#{0,1}[a-z0-9]+;/ig, '')
        };
        this.spellCheckService.checkSpelling(data)
          .subscribe(response => {
            this.spellCheckService.spellChecked = true;
            this.spellCheckService.errorData = {};
            this.spellCheckService.spellError = false;

            if (response['spell'].description.length) {
              this.spellCheckService.spellError = true;
              this.spellCheckService.errorData['description'] = {
                error: this.spellCheckService.uniqueErrors(response['spell'].description),
                label: 'Description',
                id: 'descriptionGroup',
                order: 1
              };
            }

            if (this.spellCheckService.spellError) {
              this.submitBtnText = $localize`Submit without spell check`;
              this.submitted = false;
            } else {
              this.saveData();
            }
          });
      }
    }
  }

  saveData() {
    this.invalidForm = false;
    this.submitted = true;
    this.customerActivityService.submitProspect(this.form.value)
      .subscribe(response => {
        this.customerActivityService.clientName = response['client'].name + (response['client'].erp_number ?
          ` (${response['client'].erp_number})` : '');
        this.customerActivityService.clientId = response['client']['id'];
        setTimeout(() => {
          this.form.reset();
          this.router.navigate([this.customerActivityService.referrer]);
        }, 1000);
      }, error => {
        this.invalidForm = true;
        this.submitted = false;
        this.httpError = this.commonService.parseHttpErrors(error);
      });
  }


  onChanges(control) {
    this.form.get(control).valueChanges.subscribe(val => {
      if (this.spellCheckService.spellChecked) {
        this.spellCheckService.spellChecked = false;
        this.submitBtnText = $localize`Submit`;
      }
    });
  }

  cancel() {
    this.commonService.onFormCancel(this.originalValues, this.form.value, this.customerActivityService.referrer);
  }

  ngOnDestroy() {
    this.spellCheckService.reset();
  }
}
