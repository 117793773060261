<a [routerLink]="['/team',member.person.login]" class="row" *ngIf="member">
  <div class="col-md-3">
    <ng-template #toolTipContent>
      <app-tooltip-person-card [data]="member.person" (mouseleave)="t.close()">
      </app-tooltip-person-card>
    </ng-template>
    <span class="avatar avatar-sm" [ngbTooltip]="toolTipContent" tooltipClass='p-card-tooltip' container="body"
      triggers="manual" [autoClose]="false" #t="ngbTooltip" (mouseenter)="t.open()"
      (mouseleave)="onMouseleave($event,t)">
      <img [src]="member.person.photo_url">
    </span>
  </div>
  <div class="col-md-9">
    <a [routerLink]="['/team',member.person.login]">{{peopleService.getPersonName(member.person)}}</a>
    <ng-template #tipContent>
      <div [innerHTML]="member.description" class="tooltip-content"></div>
    </ng-template>
    <div class="user-location mt-5" [ngbTooltip]="tipContent" container="body">
      {{ member.description | truncate:70}}</div>
  </div>
</a>
