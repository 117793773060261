import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { DynamicFormComponent } from 'app/shared/components/dynamic-form/dynamic-form.component';
import { AdvanceFilterFormComponent } from 'app/shared/components/advance-filter-form/advance-filter-form.component';
import { AdvanceFilterFieldsComponent } from 'app/shared/components/advance-filter-form/advance-filter-fields/advance-filter-fields.component'
import { FacetChipsComponent } from 'app/shared/components/facet-chips/facet-chips.component';
import { FacetsComponent } from 'app/shared/components/facets/facets.component';
import { CustomerFilterComponent } from 'app/shared/components/fields/customer-filter/customer-filter.component';
import { DateComponent } from 'app/shared/components/fields/date/date.component';
import { FacetOptionsComponent } from 'app/shared/components/fields/facet-options/facet-options.component';
import { FilterDropdownComponent } from 'app/shared/components/fields/filter-dropdown/filter-dropdown.component';
import { InputComponent } from 'app/shared/components/fields/input/input.component';
import { SearchPeopleComponent } from 'app/shared/components/fields/search-people/search-people.component';
import { FiltersComponent } from 'app/shared/components/filters/filters.component';
import { FilterActionComponent } from 'app/shared/components/filters/filter-action/filter-action.component';
import { DirectiveModule } from '../directive/directive.module';
import { PipeModule } from '../pipe/pipe.module';
import { NgSelect2Module } from 'ng-select2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChipsComponent } from 'app/shared/components/filters/chips/chips.component';
import { ClipboardModule } from 'ngx-clipboard';
import { LinksComponent } from 'app/shared/components/filters/links/links.component';
import { RouterModule } from '@angular/router';
import { ClickOutsideModule } from 'ng-click-outside';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from '@eklipse/perfect-scrollbar';
import { NestedFilterComponent } from 'app/shared/components/filters/nested-filter/nested-filter.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    declarations: [
        DynamicFormComponent,
        AdvanceFilterFormComponent,
        AdvanceFilterFieldsComponent,
        FacetChipsComponent,
        FacetsComponent,
        CustomerFilterComponent,
        DateComponent,
        FacetOptionsComponent,
        FilterDropdownComponent,
        InputComponent,
        SearchPeopleComponent,
        FiltersComponent,
        FilterActionComponent,
        ChipsComponent,
        LinksComponent,
        NestedFilterComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgSelect2Module,
        NgbModule,
        NgSelectModule,
        PerfectScrollbarModule,
        ClipboardModule,
        DirectiveModule,
        PipeModule,
        ClickOutsideModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        // RouterModule,
        // Select2Module,
        NgbModule,
        // ClipboardModule,
        DirectiveModule,
        PipeModule,
        DynamicFormComponent,
        AdvanceFilterFormComponent,
        AdvanceFilterFieldsComponent,
        FacetChipsComponent,
        FacetsComponent,
        CustomerFilterComponent,
        DateComponent,
        FacetOptionsComponent,
        FilterDropdownComponent,
        InputComponent,
        SearchPeopleComponent,
        FiltersComponent,
        FilterActionComponent,
        ChipsComponent,
        LinksComponent,
        NestedFilterComponent,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class FilterModule { }
