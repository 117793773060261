import { Component, OnInit, Input } from '@angular/core';
import { PeopleService } from 'app/services/people/people.service';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.css']
})
export class PersonCardComponent implements OnInit {
  @Input() data;
  @Input() classes;
  personName = '';
  showPreviewIcon = false;

  constructor(private peopleService: PeopleService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    this.personName = this.peopleService.getPersonName(this.data);
  }

  getPersonTitle() {
    return this.peopleService.getPersonTitle(this.data);
  }

  removePerson(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'experts', action: 'removeItem' });
  }
}
