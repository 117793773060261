import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-top-recommendations',
  templateUrl: './top-recommendations.component.html',
  styleUrls: ['./top-recommendations.component.css']
})
export class TopRecommendationsComponent implements OnInit {
  @Input() members = [];
  @Input() practiceCName = '';
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  @ViewChild(PerfectScrollbarDirective) directiveRef?: PerfectScrollbarDirective;

  constructor(public commonService: CommonMethodsService) { }

  ngOnInit() {
  }

}
