<html>
  <link rel='stylesheet' [href]="cmsBucketUrl + '/css/cms.css' | safe" type='text/css' media='all' />
  <link rel="stylesheet" href="https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css" crossorigin="anonymous">

  <body #element>
    <ng-template #loader>
      <div class="container">
        <content-loader height="300" width="1200" primaryColor="#eee" secondaryColor="#ddd">
          <svg:rect x="20" y="25" height="18" width="200" />
          <svg:rect x="20" y="110" height="30" width="400" />
          <svg:rect x="20" y="160" height="18" width="800" />
          <svg:rect x="20" y="190" height="18" width="400" />
        </content-loader>
      </div>
    </ng-template>
    <ng-container *ngIf="loaded;else loader">
      <div [appTranslate]="content" *ngIf="content"></div>

      <div *ngIf="!isPreview" class="text-right container mt-30">
        <ng-content select=".edit-btn"></ng-content>
        <a [routerLink]="url" [queryParams]="{preview:true}" target="_blank" class="btn btn-primary mr-10"
          *ngIf="editAccess && showPreview" i18n>View Pending Changes</a>
        <a [href]="editUrl" target="_blank" *ngIf="editAccess && editUrl" class="btn btn-primary" i18n>Edit Page In
          WordPress</a>
      </div>
    </ng-container>
  </body>

</html>
