<a [routerLink]="['/blogs',data['id']]" class="deck-card" [ngClass]="classes" (mouseenter)="showPreviewIcon=true"
  (mouseleave)="showPreviewIcon=false">
  <div class="status-chip" *ngIf="data.status && data.status.name === 'Pending Approval'">
    {{data.status.name}}
  </div>
  <div class="card" [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')'}">
    <div class="case-study-action">
      <ng-container *ngIf="!data.readOnly">
        <div class="language-chip" *ngIf="data.language?.language">{{data.language.language | slice:0:3}}</div>
        <app-change-nugget-preview [practice]="data.practices[0]" [entityId]="data.id"
          [entityCategory]="data.search_type" [previewUrl]="data.preview_url"
          *ngIf="previewAccess && isAccess && showPreviewIcon">
        </app-change-nugget-preview>
        <ng-container *ngIf="deleteAccess || isAuthor">
          <a [href]="editLink" target="_blank" class="circle-actions mt-5" *ngIf="isAuthor" appStopPropagation
            i18n-ngbTooltip ngbTooltip="Edit" i18n><i class="fas fa-pencil-alt"></i></a>
          <a (click)="deleteBlog($event,data['id'], data['title'])" appStopPropagation *ngIf="deleteAccess"
            class="circle-actions mt-5" i18n-ngbTooltip ngbTooltip="Delete" container="body">
            <i class="fas fa-trash-alt"></i></a>
        </ng-container>
        <app-create-collection [entityId]="data.id" [entityCategory]="5"
          *ngIf="showPreviewIcon"></app-create-collection>
      </ng-container>
      <div (click)="removeBlog($event, data.id, '5')" class="circle-actions"
        *ngIf="data.removeCollection && showPreviewIcon" i18n-ngbTooltip i18n-ngbTooltip
        ngbTooltip="Remove from My Collection" container="body"><i class="fa-solid fa-xmark"></i></div>
    </div>
  </div>
  <div class="card-body">
    <div class="mb-10">
      <app-members-chip [members]="data.author" class="d-inline-block">
      </app-members-chip>
    </div>
    <div class="title truncate-2">
      <span [title]="data['title']">
        {{data.title}}
      </span>
    </div>
    <div class="type">
      {{data.date | date:'mediumDate':'GMT'}}
    </div>
    <ng-container *ngIf="displayPractices">
      <div class="divider divider-1"></div>
      <app-practices-chip [practices]="data.practices"></app-practices-chip>
    </ng-container>
  </div>
  <!-- <app-change-nugget-preview [practice]="data.practice" [entityId]="data.id" [entityCategory]="data.search_type"
      [previewUrl]="data.preview_url" *ngIf="previewAccess && isAccess && showPreviewIcon">
    </app-change-nugget-preview> -->
  <!-- <div class="nugget_subject nugget_subject-blog text-white truncate-2">
      <span [title]="data['title']"> {{data['title']}}</span>
    </div>
    <div class=" date mt-5 mb-10 text-white"> {{data.date | date:'mediumDate':'GMT'}}</div>
    <div class="customer-act-body text-white truncate-2" [innerHTML]="data['body'] | truncate:250"></div>
    <div class="row mt-10">
      <div class="col height-40">
        <app-members-chip [members]="data['author']"></app-members-chip>
      </div>
    </div>
    <div class="divider"></div>
    <app-practices-chip [practices]="data['practices']"></app-practices-chip> -->

  <!-- Delete Blog -->
  <!-- <div class="delete" *ngIf="deleteAccess || isAuthor">
    <a [href]="editLink" target="_blank" class="btn btn-primary btn-sm mr-10" *ngIf="isAuthor" appStopPropagation
      i18n>EDIT</a>
    <button type="button" class="btn btn-primary btn-sm" (click)="deleteBlog($event,data['id'], data['title'])"
      appStopPropagation *ngIf="deleteAccess" i18n>DELETE</button>
  </div> -->
  <!-- End of Delete Blog -->
</a>