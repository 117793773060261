import { Component, OnInit, SimpleChanges } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { CustomerActivityService } from 'app/services/customer-activities/customer-activity.service';
import { IntrojsService } from 'app/services/introjs/introjs.service';
import { AppService } from 'app/services/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  version = '';

  constructor(public loginService: LoginService, private customerActivityService: CustomerActivityService,
    private introjsService: IntrojsService, private appService: AppService) { }

  ngOnInit(): void {
    this.getVersion();
  }

  submitFeedback() {
    const a = (window as any);
    (window as any).showFeedbackCollectorDialog();
  }

  submitBug() {
    const a = (window as any);
    (window as any).showBugCollectorDialog();
  }

  submitAccess() {
    return this.loginService.submitAccess();
  }

  isExternalUser() {
    return this.loginService.isExternalUser();
  }

  openSiteIntro() {
    this.introjsService.siteIntro(false);
  }

  getVersion() {
    this.appService.getVersion()
      .subscribe(response => {
        this.version = response['version'];
      })
  }
}
