<form class="row" [formGroup]="filterService.advanceForm">
    <ng-container *ngFor="let field of fields;">
        <div class="form-group" [ngClass]="{'col-md-3': field.type == 'date', 'col-md-6': field.type != 'date'}"
            *ngIf="field.type !== 'noUi' && field.type !== 'from' && field.type !== 'to'">
            <div [ngClass]="field.type" [hidden]="field.hide">
                <label for="Title">{{field.label}}</label>
                <div appDynamicField [field]="field" [group]="filterService.advanceForm"></div>
            </div>
        </div>
    </ng-container>
</form>