import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-loader',
  templateUrl: './banner-loader.component.html',
  styleUrls: ['./banner-loader.component.css']
})
export class BannerLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
