import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor() { }

  private hideTooltipSubject = new Subject<void>();

  hideTooltip$ = this.hideTooltipSubject.asObservable();

  hideTooltip() {
    this.hideTooltipSubject.next();
  }

}
