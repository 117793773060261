import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FieldConfig } from 'app/interfaces/field';
import { FilterService } from 'app/services/filter/filter.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {

  @Input() fields: FieldConfig[] = [];
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  form: UntypedFormGroup;

  get value() {
    return this.filterService.form.value;
  }

  constructor(private fb: UntypedFormBuilder, public filterService: FilterService) { }

  ngOnInit() {
    this.filterService.form = this.createControl();
    this.filterService.form.valueChanges.pipe(debounceTime(500)).subscribe(form => {
      this.filterService.setFilters();
    });

    if (this.filterService.form.get('practices') && this.filterService.form.get('primaryPractices')) {
      this.filterService.form.get('practices').valueChanges
        .subscribe(value => {
          if (this.filterService.form.get('primaryPractices').value.filter(f => f).length) {
            this.filterService.form.get('practices').value.forEach((v, i) => {
              if (v && this.filterService.form.get('primaryPractices').value[i]) {
                this.filterService.form.get('primaryPractices').value[i] = false;
              }
            })
          }
        })
    }
  }

  createControl() {
    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === 'button' || field.type === 'filterLink') {
        return;
      }
      let control;
      if (['filterDropdown', 'facetOptions'].includes(field.type) || (field.type === 'noUi' && field.name === 'primaryPractices')) {
        const options = field.options.map(c => this.fb.control(false));
        control = this.fb.array(options);
      } else {
        control = this.fb.control(
          field.value,
          this.bindValidations(field.validations || [])
        );
      }

      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.filterService.form.valid) {
      this.submit.emit(this.filterService.form.value);
    } else {
      this.validateAllFormFields(this.filterService.form);
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

}
