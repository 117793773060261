import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from 'app/services/auth/login.service';
import { environment } from 'environments/environment';
import { PerfectScrollbarConfigInterface } from '@eklipse/perfect-scrollbar';

@Component({
  selector: 'app-cookie-alert',
  templateUrl: './cookie-alert.component.html',
  styleUrls: ['./cookie-alert.component.css']
})
export class CookieAlertComponent implements OnInit {
  isClosed = true;
  // isWarningClosed = false;
  siteUrl = environment.siteUrl;
  // thirdPartyCookieEnabled = true;
  // cookieChecked = false;
  browser = '';
  config: PerfectScrollbarConfigInterface = { suppressScrollX: true, wheelPropagation: true };
  // isLoggedOut = true;
  // cookieEnabled = true;

  constructor(public loginService: LoginService, private jwtHelper: JwtHelperService) { }

  ngOnInit(): void {
    // this.cookieEnabled = navigator.cookieEnabled;
    // this.checkThirdPartyCookie();
    this.browser = this.getBrowser();
    // this.isLoggedOut = this.jwtHelper.isTokenExpired(localStorage.getItem('refreshToken'));
  }

  get driveAccess() {
    return this.loginService.driveAccess;
  }

  getBrowser() {
    const test = function (regexp) { return regexp.test(window.navigator.userAgent) }

    switch (true) {
      case test(/edg/i): return "Edge";
      case test(/trident/i): return "IE";
      case test(/firefox|fxios/i): return "Firefox";
      case test(/opr\//i): return "Opera";
      case test(/ucbrowser/i): return "UC Browser";
      case test(/samsungbrowser/i): return "Samsung Browser";
      case test(/chrome|chromium|crios/i): return "Chrome";
      case test(/safari/i): return "Safari";
      default: return "Other";
    }
  }

  // checkThirdPartyCookie() {
  //   window.addEventListener("message", event => {
  //     if (event.data === 'MM:3PCunsupported') {
  //       this.thirdPartyCookieEnabled = false;
  //     } else if (event.data === 'MM:3PCsupported') {
  //       this.thirdPartyCookieEnabled = true;
  //     }
  //     setTimeout(() => {
  //       this.cookieChecked = true;
  //     }, 1000);
  //   }, false);
  // }

  dismiss() {
    this.loginService.cookieAlertClosed = true;
  }

}
