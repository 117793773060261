import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {
  apiUrl = environment.apiUrl;
  selectedCaseStudy = [];
  CSupdateEvent = new EventEmitter();
  CSSelectEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  getCaseStudies(params?, headers?) {
    return this.http.get(this.apiUrl + '/v1/case-studies', { params, headers });
  }

  getCaseStudy(id, headers, params?) {
    return this.http.get(this.apiUrl + `/v1/case-studies/${id}`, { headers, params });
  }

  getRecommendation(id) {
    return this.http.get(this.apiUrl + `/v1/case-studies/${id}/recommended`);
  }

  // searchCaseStudies(params) {
  //   return this.http.get(this.apiUrl + '/v1/case-studies/search', { params });
  // }

  delete(id) {
    return this.http.delete(this.apiUrl + `/v1/case-studies/${id}`);
  }

  getCaseStudiesAll(params, headers?) {
    return this.http.get(this.apiUrl + '/v1/case-studies/all', { params, headers });
  }

  updatePosition(id, params) {
    return this.http.post(this.apiUrl + `/v1/case-studies/${id}/position`, params);
  }

  updateVisible(params) {
    return this.http.post(this.apiUrl + `/v1/case-studies/visible`, params);
  }
}

export interface CaseStudies {
  case_studies: [];
  total_count: number;
}
