import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FilterService } from 'app/services/filter/filter.service';
import { filter, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { RouterExtraService } from 'app/services/router-extra/router-extra.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent implements OnInit {
  showBackButton = false;

  constructor(private router: Router, private filterService: FilterService, private location: Location,
    private routerService: RouterExtraService, private route: ActivatedRoute) { }

  onClick() {
    const backUrl = sessionStorage.getItem('savedFilters');
    if (backUrl) {
      sessionStorage.removeItem('savedFilters');
      this.router.navigateByUrl(backUrl);
    } else {
      this.location.back();
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let route = this.route;
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      map((route) => route.snapshot),
    ).subscribe((event) => {
      const backUrl = sessionStorage.getItem('savedFilters');
      const previousUrl = this.routerService.getPreviousUrl();
      if ((backUrl || previousUrl !== '/') && event.data.showBack) {
        this.showBackButton = true;
        this.filterService.class = ' back-button-page';
      } else {
        this.showBackButton = false;
        this.filterService.class = this.filterService.class.replace(' back-button-page', '');
      }
    });
  }
}
