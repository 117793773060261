<ng-container *ngIf="members.length">
  <div class="small-heading">Top Contributors</div>
  <div appMatchHeight="row" [perfectScrollbar]="config" *ngIf="scrollable,else fullview;" [style.max-height.px]="height"
    class="white-well">
    <div class="p-card" *ngFor="let member of members">
      <div class="avatar avatar-60">
        <ng-template #toolTipContent>
          <app-tooltip-person-card [data]="member.person" (mouseleave)="t.close()">
          </app-tooltip-person-card>
        </ng-template>
        <a [routerLink]="['/team',member.person['login'] || member.person['person_id']]" [ngbTooltip]="toolTipContent"
          tooltipClass='p-card-tooltip' container="body" triggers="manual" [autoClose]="false" #t="ngbTooltip"
          (mouseenter)="t.open()" (mouseleave)="onMouseleave($event,t)">
          <img [src]="member.person.photo_url"></a>
        <div class="p-body">
          <div class="p-name">
            <a [routerLink]="['/team',member.person['login'] || member.person['person_id']]">
              {{peopleService.getPersonName(member.person)}}
            </a>
          </div>
          <div class="type">{{member.description}}</div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #fullview>
    <div class="contributors" appMatchHeight="row">
      <app-contributor-card *ngFor="let member of members" [member]="member" class="nugget-item nugget-item-full mb-10">
      </app-contributor-card>
    </div>
  </ng-template>

</ng-container>
