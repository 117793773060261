import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { FilterService } from 'app/services/filter/filter.service';

@Component({
  selector: 'app-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.css']
})
export class CustomerFilterComponent implements OnInit {
  options;
  apiUrl = environment.apiUrl;
  dataOption = [];

  constructor(private filterService: FilterService) { }

  ngOnInit() {
    this.options = {
      tags: false,
      multiple: true,
      placeholder: $localize`Customers`,
      language: { errorLoading: () => 'Searching...' },
      minimumInputLength: 1,
      ajax: {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        url: this.apiUrl + '/v1/clients',
        data: (params) => ({
          'filters[search]': params.term,
          page: params.page || 1
        }),
        processResults: (data) => {
          this.dataOption = data.clients.map((client) => ({
            id: client.id,
            text: client.name + (client.erp_number ? ` (${client.erp_number})` : '')
          }));

          return {
            results: this.dataOption,
            pagination: {
              more: (data.page * 20) < data.total_count
            }
          };
        }
      }
    };
  }

  valueChanged(event) {
    let clients = this.dataOption.filter(o => event.includes(o.id.toString()))
      .map(tag => ({ id: tag.id, name: tag.text }));
    if (clients.length) {
      clients = clients.concat(this.filterService.form.get('customers').value || []);
      this.filterService.form.get('customers').setValue(clients);
      $('#customers-filter select').val(null).trigger('change');
    }
  }

}
