import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from 'app/services/auth/login.service';
import { CommonMethodsService } from 'app/services/util/common-methods.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';
import { PracticesService } from 'app/services/practices/practices.service';
import { AppService } from 'app/services/app/app.service';
import { CloudPickerService } from 'app/services/cloud-picker/cloud-picker.service';
import { CuratedContentService } from '../curated-content.service';
import { CollectionsService } from 'app/services/collections/collections.service';

@Component({
  selector: 'app-curated-content-card',
  templateUrl: './curated-content-card.component.html',
  styleUrls: ['./curated-content-card.component.css']
})
export class CuratedContentCardComponent implements OnInit {

  @Input() data;
  @Input() showSortIcons;
  @Input() first;
  @Input() last;
  @Input() fromPage = '';
  isPrivileged = false;
  isCenterCoordinator = false;
  isOnlyCoreMember = false;
  downloadUrl = '';
  showPreviewIcon = false;
  queryParams = {};
  isAuthor = false;

  constructor(private practicesService: PracticesService, private loginService: LoginService, public commonService: CommonMethodsService,
    private modalService: ModalPopupService, private appService: AppService, private cloudPickerService: CloudPickerService,
    private curatedContentService: CuratedContentService, private collectionsService: CollectionsService) { }

  ngOnInit() {
    this.isPrivileged = this.loginService.isPrivilegedUser([this.data.practice?.id]) && !this.practicesService.showPublicView;
    this.isCenterCoordinator = this.loginService.isCenterCoordinator([], [this.data.practice?.id]) && !this.practicesService.showPublicView;
    this.isOnlyCoreMember = this.loginService.isOnlyCoreMember([this.data.practice?.id]) && !this.practicesService.showPublicView;
    this.isAuthor = this.data.authors && this.data.authors.filter(author => author.contact?.email_id ===
      this.loginService.currentUser.user.email).length;

    this.downloadUrl = this.data.deck_file_url;
    this.queryParams = { from: this.fromPage }
    this.cloudPickerService.previewUpdate.subscribe(response => {
      if (this.data.id == response.entityId) {
        this.data.preview_url = response.previewUrl;
        this.modalService.close();
      }
    })
  }

  deleteCuratedContent(event: Event, id: number, title: string) {
    event.preventDefault();
    event.stopPropagation();
    const data = {
      title: $localize`Delete Recommended Read`, message: $localize`Are you sure you want to delete ${title} recommended read?`, size: 'md', showFooter: true
    };
    this.modalService.confirm(data)
      .then((confirmed) => {
        if (confirmed) {
          this.commonService.showLoader = true;
          this.curatedContentService.deleteCuratedContent(id)
            .subscribe(response => {
              this.curatedContentService.deleteCuratedContentEvent.emit();
              this.commonService.showLoader = false;
            }, () => this.commonService.showLoader = false);
        }
      })
      .catch(() => { });
  }

  updateOrder(event, direction) {
    event.preventDefault();
    this.commonService.itemSortEvent.emit({ type: 'curated-content', direction, id: this.data.id, position: +this.data.position });
  }

  saveLog(event) {
    event.preventDefault;
    this.cloudPickerService.download(this.downloadUrl);
    const data = { entity_id: this.data['id'], entity_category_id: 25, referer: window.location.href, action_type: 2 };
    this.appService.saveVisitorLog(data)
      .subscribe(r => { },
        e => { });
  }

  removeCuratedContent(event, entityId, entityCategoryId) {
    event.preventDefault();
    event.stopPropagation();
    this.collectionsService.collectionEvent.next({ entityId, entityCategoryId, type: 'curated-content', action: 'removeItem' });
  }
}
