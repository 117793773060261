<a [routerLink]="[url,data.id]" [queryParams]="queryParams" class="deck-card recommended-nugget-card" (click)="click()">
  <div class="status-chip"
    *ngIf="data.status && data.status.name === 'Pending Approval' && (type === 'tech-doc' || type === 'tech-talk')">
    {{data.status.name}}
  </div>
  <div class="card" [ngStyle]="{backgroundImage: 'url(' + data.preview_url + ')'}">
  </div>
  <div class="card-body">
    <div class="mb-10" *ngIf="showMember">
      <app-members-chip [members]="members" [displayNumber]="displayNumber" class="d-inline-block">
      </app-members-chip>
    </div>
    <div class="title truncate-2">
      <span [title]="data.title">
        {{data.title}}
      </span>
    </div>
    <div class="type">
      {{getDate() | date:'mediumDate':'GMT'}}
    </div>
  </div>
</a>