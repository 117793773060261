<div class="tag-action">
  <div>{{message}}</div>
  <div class="custom-select-field" *ngIf="selectAlternateTag">
    <ng-select2 width="100%" [options]="options" (valueChanged)="valueChanged($event)" class="custom-select-field">
    </ng-select2>
  </div>
  <div *ngIf="helpMessage" class="help">{{helpMessage}}</div>
  <div *ngIf="hasError" class="invalid mt-5">{{errorMessage}}</div>
  <button class="btn btn-primary" [disabled]="(buttonDisabled)" [ngClass]="{'submitted':buttonDisabled}"
    (click)="submit()">{{buttonText | uppercase}}</button>
</div>