<div class="nugget-card">
  <content-loader height="250" width="300" primaryColor="#eee" secondaryColor="#ddd">
    <svg:rect x="0" y="0" width="300" height="120" />
    <svg:circle cx="32" cy="150" r="22" />
    <svg:circle cx="82" cy="150" r="22" />
    <svg:circle cx="132" cy="150" r="22" />
    <svg:circle cx="182" cy="150" r="22" />
    <svg:circle cx="232" cy="150" r="22" />
    <svg:rect x="10" y="180" width="200" height="18" />
    <svg:rect x="10" y="210" width="100" height="18" />
    <svg:rect x="120" y="210" width="100" height="18" />
  </content-loader>
</div>
