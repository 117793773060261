<form [formGroup]="form" (submit)="onSubmit()" *ngIf="form" class="modal-form">
  <div class="form-group"
    [ngClass]="{'validate':form.get('title').invalid && (form.get('title').touched || invalidForm)}">
    <label for="title" i18n>Title</label>
    <div class="help"> </div>
    <input type="text" class="form-control" i18n-placeholder placeholder="Enter title" formControlName="title"
      id="title">
    <div class="invalid" *ngIf="form.get('title').invalid && (form.get('title').touched || invalidForm)">
      <div *ngIf="form.get('title').errors.whitespace" i18n>Enter title</div>
      <div *ngIf="form.get('title').errors.maxlength" i18n>Title should be less than
        {{form.get('title').errors.maxlength.requiredLength}} characters
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="submitted" [ngClass]="{'submitted':submitted}"
      i18n>SUBMIT</button>
  </div>
</form>
