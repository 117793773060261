<div class="search-tag-wrapper row">
  <ng-container *ngFor="let filter of filtersList | slice:0:displayNumber">
    <ng-container *ngIf="filter.filterType === 'primaryPractices';else otherChip">
      <span class="search-tag primary-practice" *ngIf="filter.name" i18n-ngbTooltip
        ngbTooltip="Primary Practice">{{filter.name}}<a href="javascript:"
          (click)="filterService.removeFilter(filter)">X</a></span>
    </ng-container>
    <ng-template #otherChip>
      <span class="search-tag" *ngIf="filter.name">{{filter.name}}<a href="javascript:"
          (click)="filterService.removeFilter(filter)">X</a></span>
      <span class="search-tag" *ngIf="filter.filterType== 'from' || filter.filterType== 'to'">{{filter.filterType |
        titlecase}} : {{filter.value | date}}<a href="javascript:"
          (click)="filterService.removeFilter(filter)">X</a></span>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="moreChips > 0">
    <span class="search-tag search-tag-more" (click)="open()" appStopPropagation i18n>+{{moreChips}}
      more</span>
  </ng-container>
</div>
<div *ngIf="allowClearAll" class="filter-chip-action">
  <button class="btn btn-primary" (click)="filterService.clearFilters()" *ngIf="filtersList.length">Clear
    Filters</button>
</div>