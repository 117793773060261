import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterService } from 'app/services/filter/filter.service';
import { ModalPopupService } from 'app/services/util/modal-popup.service';

@Component({
  selector: 'app-facet-chips',
  templateUrl: './facet-chips.component.html',
  styleUrls: ['./facet-chips.component.css']
})
export class FacetChipsComponent implements OnInit, OnChanges {
  @Input() hiddenChipTypes = [];
  @Input() data;
  @Input() displayLength = 190;
  filtersList = [];
  displayNumber = 0;
  moreChips = 0;
  allowClearAll = false;

  constructor(public filterService: FilterService, private modalService: ModalPopupService) { }

  ngOnInit(): void {
    if (this.data) {
      this.filtersList = this.data.filter;
      this.hiddenChipTypes = this.data.hiddenChipTypes;
      this.displayLength = 100000;
      this.getDisplayNumber();
      this.allowClearAll = true;
    }
    this.filterService.filtersUpdate.subscribe(filter => {
      this.getCurrentFilter();
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getCurrentFilter();
  }

  getCurrentFilter() {
    this.filtersList = this.filterService.filters.filter(f => f.name && (this.hiddenChipTypes.indexOf(f.filterType) < 0))
    this.getDisplayNumber();
  }

  getDisplayNumber() {
    this.displayNumber = 0;
    this.moreChips = 0;
    if (!this.filtersList.length) {
      this.modalService.close()
    }
    // get no of filters display
    let filtersName = '';
    for (const filter of this.filtersList) {
      filtersName += filter['name'] + '       ';
      if (filtersName.length > (this.displayLength - 12)) {
        break;
      }
      filtersName += ' ';
      this.displayNumber++;
    }
    // check is only one segment left and it can be display
    if (this.filtersList.length === (this.displayNumber + 1)) {
      if (filtersName.length <= this.displayLength) {
        this.displayNumber++;
      }
    }
    this.moreChips = this.filtersList.length - this.displayNumber;
  }

  open() {
    const data = {
      title: $localize`Filters`, message: '', component: 'FacetChipsComponent',
      data: { filter: this.filtersList, hiddenChipTypes: this.hiddenChipTypes }, size: 'lg'
    };
    this.modalService.open(data);
  }

  clearAll() {
    this.filterService.clearFilters();
  }

}
