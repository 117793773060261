import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DigitalAcceleratorsService } from 'app/modules/feature/digital-accelerator/digital-accelerators.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { map } from 'rxjs';

@Component({
  selector: 'app-industry-segment-select',
  templateUrl: './industry-segment-select.component.html',
  styleUrls: ['./industry-segment-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IndustrySegmentSelectComponent
    }
  ]
})
export class IndustrySegmentSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() multiple = true;
  @Input() helpText = $localize`Select the industry from Practice or Secondary Practices and then choose Industry Segments.`;
  @Input() optional = false;
  @Input() filter = [];
  htmlId = 'industry-segment';
  label = $localize`Industry Segment`
  placeholder = $localize`Select industry segment`;
  values = [];
  selectedSegments: any[] = [];
  industrySegments: any[] = [];
  options: any[] = [];
  disabled = false;
  touched = false;
  onChange: any = () => { }
  onTouched = () => { this.touched = true; }

  constructor(private digitalAcceleratorService: DigitalAcceleratorsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.options = this.industrySegments.filter(s => this.filter.includes(s.practice_id));
      this.selectedSegments = this.selectedSegments.filter(s => this.filter.includes(s.practice_id));
      this.onSelectionChange(this.selectedSegments);
    }
  }

  ngOnInit(): void {
    if (this.multiple) {
      this.htmlId += $localize`s`;
      this.label += $localize`s`;
      this.placeholder += $localize`s`;
    }
    this.digitalAcceleratorService.getIndustries().pipe(
      map(optionsData => {
        return optionsData['industries'].flatMap(i => i.industry_segments
          .filter(() => i.practice_id !== null)
          .map(s => ({ id: s.id, name: s.name, industry_id: i.id, industry: i.name, practice_id: i.practice_id })));
      }),
    )
      .subscribe(options => {
        this.industrySegments = options;
        this.options = options;
        this.setSelectedSegments()
      })
  }

  setSelectedSegments() {
    this.selectedSegments = this.options.filter(option => this.values.some(value => value.id === option.id));
    this.onSelectionChange(this.selectedSegments);
  }

  onSelectionChange(values: any[]) {
    if (values) {
      values = values.reduce((acc, cur) => {
        const index = acc.findIndex(a => a.id === cur.industry_id)
        if (index > -1) {
          acc[index].industry_segments.push(cur.id)
        } else {
          const industry = { id: cur.industry_id, industry_segments: [cur.id] }
          acc.push(industry)
        }
        return acc;
      }, [])
    }
    this.onChange(values)
  }

  compareWith(item, selected) {
    if (selected.id && item.id) {
      return item.id === selected.id;
    }
    return false;
  }

  remove(id: number) {
    this.selectedSegments = this.selectedSegments.filter(s => s.id !== id);
    this.onSelectionChange(this.selectedSegments);
  }

  writeValue(values: any): void {
    this.values = values?.flatMap(i => i.industry_segments.map(s => ({ id: s.id })));
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
