import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LoginService } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class DriveService {

  apiUrl = environment.apiUrl;
  //  not in use
  authUrl = 'https://script.google.com/a/globallogic.com/macros/s/AKfycbyHwLgOqV06QI2r2dd6OHs3TKqEVAAqlu5GjCif05DT36DHcw/exec';
  methodUrl = 'https://script.google.com/a/method.com/macros/s/AKfycbxupEemQ9fNqE6mQT2w4WnsnF-DaXrv-8M_FwGBnUlgS5fyUNQP/exec';
  pickerToken = '';
  pickerSubscription;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.checkDriveAccess();
  }

  checkDriveAccess() {
    // this.loginService.driveAccessChecked = false;
    this.getAuthToken()
      .subscribe((r) => {
        this.loginService.driveAccess = true; //this.loginService.driveAccessChecked = true;
      },
        (r) => {
          this.loginService.driveAccess = false; //this.loginService.driveAccessChecked = true;
        }
      )
  }

  getAuthToken() {
    let url = this.authUrl;
    if (this.loginService.isMethodLoggedIn()) {
      url = this.methodUrl;
    }
    return this.http.jsonp(url, 'callback');
  }

  removeFile(id, data) {
    return this.http.request('delete', this.apiUrl + `/v1/files/${id}`, { body: data });
  }

  upload(data) {
    return this.http.post(this.apiUrl + `/v1/files/upload`, data);
  }

  getFileById(id, token) {
    let params = new HttpParams();
    params = params.append('access_token', token);
    return this.http.get(this.apiUrl + `/v1/files/${id}`, { params });
  }

  getDocHeight(mimeType, size?) {
    const type = ['application/vnd.ms-powerpoint', 'application/vnd.google-apps.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    if (type.includes(mimeType) || mimeType.indexOf('video') > -1 || mimeType.indexOf('image') > -1) {
      return size === 'min' ? '410px' : '540px';
    } else {
      return size === 'min' ? '860px' : '1050px';
    }
  }

  select(data) {
    return this.http.post(this.apiUrl + `/v1/files/select`, data);
  }
}
